/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import IcBack from 'assets/ic_left.svg';
import { media } from 'utils/media';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_CALLS, callAPI } from 'api';
import { DataContext } from 'utils/DataContext';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import IcPlay from 'assets/ic_play_show.svg';
import IcPause from 'assets/ic_pause.svg';
import { MusicList } from './MusicList';
import { getAccessToken } from 'api/http-common';
import axios from 'axios';
import { TopBackground } from './TopBackground';

export function Album({ fetchArtistDetails }) {
  let { id } = useParams();
  const [list, setList] = useState([{}, {}, {}, {}, {}]);
  const [content, setContent] = useState({});
  const {
    notify,
    isPlaying,
    curAlbumId,
    setPlaylist,
    setIsPlaying,
    setPlayingId,
    setCurAlbumId,
  } = useContext(DataContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (curAlbumId === id && content) setPlaylist(content.contentIds);
  }, [curAlbumId, content]);

  const loadContent = async () => {
    try {
      const resp = await callAPI(API_CALLS.GET_CONTENT, id);
      setContent(resp);
      fetchArtistDetails(resp.artistUsername);
    } catch (e) {
      console.log(e);
      setContent({});
      const errResponse =
        (e && e?.response && e?.response.data && e?.response.data.message) ||
        (e && e?.message) ||
        'There was some issue while loading content.';
      notify(errResponse, 'error');
    }
  };

  const goBack = () => navigate(-1);

  useEffect(() => {
    if (content && content.id) fetchContentList();
  }, [content]);

  useEffect(() => {
    loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchContentList = async () => {
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };
    let query = '';
    query += `userId=${content.userId}&`;
    query += `albumId=${id}`;
    content.contentIds.forEach(id => (query += `&ids=${id}`));

    try {
      const response = await axios.get(
        `https://api.fanfliq.com/v1/content?${query}`,
        {
          headers: headers,
        },
      );
      setList(response.data.results);
    } catch (e) {
      setList();
      const errResponse =
        (e && e?.response && e?.response.data && e?.response.data.message) ||
        (e && e?.message) ||
        'There was some issue while loading content.';
      notify(errResponse, 'error');
    }
  };

  const handlePlayPause = () => {
    if (curAlbumId === id) setIsPlaying(!isPlaying);
    else {
      setCurAlbumId(id);
      setPlayingId(content.contentIds[0]);
      setIsPlaying(true);
      setPlaylist(content.contentIds);
    }
  };

  return (
    <div>
      <CoverDiv style={{ position: 'relative' }}>
        <TopBackground
          id="home"
          gradient="linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), transparent 50% / cover no-repeat"
          layerHeight="36.8rem"
          styleProp={{
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,
            position: 'absolute',
          }}
          content={content}
        />
        <TopInside>
          <div
            className="hide-in-mobile"
            style={{
              width: '4.8rem',
              height: '4.8rem',
              justifyContent: 'center',
              alignItems: 'center ',
              padding: '1.2rem',
              borderRadius: '100%',
              background: '#fff',
              cursor: 'pointer',
              margin: '4rem 0rem 0rem 4rem',
            }}
            onClick={goBack}
          >
            <img src={IcBack} alt="back" />
          </div>
          <div
            className="hide-in-desktop"
            style={{
              width: '2.4rem',
              height: '2.4rem',
              justifyContent: 'center',
              alignItems: 'center ',
              padding: '0.6rem',
              borderRadius: '100%',
              background: '#fff',
              position: 'absolute',
              top: '1.6rem',
              left: '1.6rem',
              cursor: 'pointer',
            }}
            onClick={goBack}
          >
            <img
              style={{ width: '1.2rem', height: '1.2rem' }}
              src={IcBack}
              alt="back"
            />
          </div>
          <TopDiv>
            {content.cover ? (
              <ImageCover
                placeholderSrc={content?.thumbnail}
                src={content?.cover}
              />
            ) : (
              <ImageSkeleton />
            )}
            <div className="center-mobile">
              <div className="album-subtitle">ALBUM</div>
              <div className="album-title">{content.title}</div>
              <div className="album-subtitle">{`By: ${content.artist}`}</div>
            </div>
          </TopDiv>
        </TopInside>
        <PlayBg className="hide-in-mobile" onClick={handlePlayPause}>
          <img
            src={isPlaying && curAlbumId === id ? IcPause : IcPlay}
            alt="play"
          />
        </PlayBg>
      </CoverDiv>
      <BodyDiv>
        <MusicList list={list} />
      </BodyDiv>
    </div>
  );
}

const BodyDiv = styled.div`
  ${media.mobile`margin: 4rem 1.6rem 20rem 1.6rem`};
  ${media.small`margin: 4rem 1.6rem 20rem 1.6rem`};
  ${media.medium`margin: 6.4rem 0rem 28rem 16.8rem`};
`;

const TopDiv = styled.div`
  display: flex;
  align-items: center;
  ${media.mobile`width: 100%; align-items: center; justify-content: space-between; flex-direction: column`};
  ${media.small`width: 100%; align-items: center; justify-content: space-between; flex-direction: column`};
  ${media.medium`width: auto; gap: 1.6rem; margin-top: 0.4rem; align-items: center; justify-content: flex-start; flex-direction: row`};
`;

const ImageCover = styled(LazyLoadImage)`
  ${media.mobile`width: 10.4rem; height: 10.4rem;`};
  ${media.small`width: 10.4rem; height: 10.4rem;`};
  ${media.medium`width: 18.7rem; height: 18.7rem;`};
  object-fit: cover;
`;
const ImageSkeleton = styled(Skeleton)`
  ${media.mobile`width: 10.4rem; height: 10.4rem;`};
  ${media.small`width: 10.4rem; height: 10.4rem;`};
  ${media.medium`width: 18.7rem; height: 18.7rem;`};
`;

const CoverDiv = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  ${media.mobile`width: 100%; height: 22.4rem; padding: 2rem 1.6rem;`};
  ${media.small`width: 100%; height: 22.4rem; padding: 2rem 1.6rem;`};
  ${media.medium`width: 100%; height: 36.8rem; gap: 7rem; padding: 0rem;`};
`;

const TopInside = styled.div`
  z-index: 8;
  display: flex;
  ${media.mobile`width: 100%; height: auto;`};
  ${media.small`width: 100%; height: auto;`};
  ${media.medium`width: 100%; height: 36.8rem; gap: 7rem;`};
`;

const PlayBg = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -2.8rem;
  left: 15.8rem;
  cursor: pointer;

  background: ${themeColors[SELECTED_THEME].brand};
  :hover {
    transform: scale(1.06);
  }
  > img {
    width: 3.36rem;
    height: 3.36rem;
  }
`;
