import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithPhoneNumber,
} from 'firebase/auth';
const firebaseConfig = {
  apiKey: 'AIzaSyAQ9ZgYadVh86clDBS39WnkUPfS0M6XsBM',
  authDomain: 'fanfliq-a5354.firebaseapp.com',
  // authDomain: 'localhost:3000'
  projectId: 'fanfliq-a5354',
  storageBucket: 'fanfliq-a5354.appspot.com',
  messagingSenderId: '631777460290',
  appId: '1:631777460290:web:542bde1b955400b68b6ddb',
  measurementId: 'G-FCZ11Q10LJ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
export const auth = getAuth(app);
auth.useDeviceLanguage();

export const facebook = new FacebookAuthProvider();

const provider = new GoogleAuthProvider();
export const signInWithGoogle = () => {
  return signInWithPopup(auth, provider);
};

export const signInWithPhone = (phone, verifier) => {
  return signInWithPhoneNumber(auth, phone, verifier);
};

export const signInWithFacebook = () => {
  return signInWithPopup(auth, facebook);
};
