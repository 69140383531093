import React, { useState } from 'react';
import { Fab, Action } from './fabmenu/index.jsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import copy from 'copy-to-clipboard';

function Circle() {
  const ShareIcon = (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="white" />
      <path
        d="M33.707 23.293L25.707 15.293C25.5671 15.1532 25.389 15.058 25.195 15.0194C25.0011 14.9808 24.8 15.0007 24.6173 15.0763C24.4346 15.152 24.2785 15.2801 24.1686 15.4445C24.0587 15.609 24 15.8023 24 16V19.545C21.2683 19.7978 18.7292 21.0609 16.8797 23.087C15.0302 25.1132 14.0033 27.7566 14 30.5V32C14.0002 32.2076 14.0649 32.41 14.1853 32.5791C14.3056 32.7482 14.4756 32.8757 14.6717 32.9438C14.8678 33.012 15.0802 33.0174 15.2795 32.9594C15.4788 32.9014 15.6551 32.7827 15.784 32.62C16.7637 31.455 17.9658 30.4968 19.3199 29.8016C20.6741 29.1064 22.1533 28.6881 23.671 28.571C23.721 28.565 23.846 28.555 24 28.545V32C24 32.1977 24.0587 32.391 24.1686 32.5555C24.2785 32.7199 24.4346 32.848 24.6173 32.9237C24.8 32.9993 25.0011 33.0191 25.195 32.9806C25.389 32.942 25.5671 32.8468 25.707 32.707L33.707 24.707C33.8945 24.5195 33.9998 24.2652 33.9998 24C33.9998 23.7348 33.8945 23.4805 33.707 23.293ZM26 29.586V27.5C26 27.2348 25.8946 26.9804 25.7071 26.7929C25.5196 26.6054 25.2652 26.5 25 26.5C24.745 26.5 23.704 26.55 23.438 26.585C20.7429 26.8333 18.1774 27.8573 16.052 29.533C16.2932 27.3274 17.3395 25.2883 18.9906 23.8062C20.6416 22.324 22.7813 21.5028 25 21.5C25.2652 21.5 25.5196 21.3946 25.7071 21.2071C25.8946 21.0196 26 20.7652 26 20.5V18.414L31.586 24L26 29.586Z"
        fill="#1A1A1A"
      />
    </svg>
  );

  const CancelIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="interface/close-lg">
        <path
          id="Vector"
          d="M4.29292 18.2931L10.5859 12.0001L4.29292 5.70708C4.11076 5.51848 4.00997 5.26588 4.01224 5.00368C4.01452 4.74148 4.11969 4.49067 4.3051 4.30526C4.49051 4.11985 4.74132 4.01469 5.00352 4.01241C5.26571 4.01013 5.51832 4.11092 5.70692 4.29308L11.9999 10.5861L18.2929 4.29308C18.3852 4.19757 18.4955 4.12139 18.6175 4.06898C18.7395 4.01657 18.8707 3.98898 19.0035 3.98783C19.1363 3.98668 19.268 4.01198 19.3909 4.06226C19.5138 4.11254 19.6254 4.18679 19.7193 4.28069C19.8132 4.37458 19.8875 4.48623 19.9377 4.60913C19.988 4.73202 20.0133 4.8637 20.0122 4.99648C20.011 5.12926 19.9834 5.26048 19.931 5.38249C19.8786 5.50449 19.8024 5.61483 19.7069 5.70708L13.4139 12.0001L19.7069 18.2931C19.8024 18.3853 19.8786 18.4957 19.931 18.6177C19.9834 18.7397 20.011 18.8709 20.0122 19.0037C20.0133 19.1365 19.988 19.2681 19.9377 19.391C19.8875 19.5139 19.8132 19.6256 19.7193 19.7195C19.6254 19.8134 19.5138 19.8876 19.3909 19.9379C19.268 19.9882 19.1363 20.0135 19.0035 20.0123C18.8707 20.0112 18.7395 19.9836 18.6175 19.9312C18.4955 19.8788 18.3852 19.8026 18.2929 19.7071L11.9999 13.4141L5.70692 19.7071C5.51832 19.8892 5.26571 19.99 5.00352 19.9878C4.74132 19.9855 4.49051 19.8803 4.3051 19.6949C4.11969 19.5095 4.01452 19.2587 4.01224 18.9965C4.00997 18.7343 4.11076 18.4817 4.29292 18.2931Z"
          fill="#141414"
        />
      </g>
    </svg>
  );

  const [shareIconClicked, setShareIconClicked] = useState(false);

  const handleShareIconClick = () => {
    setShareIconClicked(!shareIconClicked);
  };

  function twitterLinkShare() {
    const url = window.location.href;
    window.open(
      'https://twitter.com/intent/tweet?text=' +
        encodeURIComponent(document.title) +
        '&url=' +
        encodeURIComponent(url),
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=350,width=600',
    );
    return false;
  }

  const [copyTxt, setCopyTxt] = useState('Copy Link');

  return (
    <div>
      <Fab
        externalClass="share-button"
        style={{ zIndex: 40 }}
        mainButtonStyles={{
          backgroundColor: '#fff',
          left: 0,
        }}
        actionButtonStyles={{
          backgroundColor: '#fff',
          hoverColor: '#fff',
        }}
        icon={ShareIcon}
        cancelicon={CancelIcon}
        alwaysShowTitle={true}
        onClick={handleShareIconClick}
      >
        {/* <Action
          onClick={() =>
            window.open(
              'https://www.instagram.com/wedigit.tech/?utm_medium=copy_link',
              '_blank',
            )
          }
        >
          <img
            style={{ zIndex: 4, width: '4.8rem', height: '4.8rem' }}
            src={ic_insta}
            alt=""
          />
        </Action> */}
        <Action onClick={() => twitterLinkShare()}>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">Share on X</Tooltip>}
          >
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="64" height="64" rx="32" fill="#1B1B1B" />
              <path
                d="M38.901 21.1531H42.581L34.541 30.3431L44 42.8461H36.594L30.794 35.2621L24.156 42.8461H20.474L29.074 33.0161L20 21.1541H27.594L32.837 28.0861L38.901 21.1531ZM37.61 40.6441H39.649L26.486 23.2401H24.298L37.61 40.6441Z"
                fill="#DEDEDE"
              />
            </svg>
          </OverlayTrigger>
        </Action>
        <Action
          onClick={() =>
            copy(window.location.href, {
              onCopy: () => {
                setCopyTxt('Copied!');
                setTimeout(() => {
                  setCopyTxt('Copy Link');
                }, 3000);
              },
            })
          }
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">{copyTxt}</Tooltip>}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '5.0rem',
                height: '5rem',
                background: '#fff',
                padding: '1.2rem',
                borderRadius: '100%',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.9016 4.09835C18.4372 2.63388 16.0628 2.63388 14.5984 4.09835L10.0983 8.59835C8.63388 10.0628 8.63388 12.4372 10.0983 13.9017C10.4092 14.2125 10.7598 14.4565 11.133 14.6348C11.5068 14.8134 11.665 15.2611 11.4865 15.6349C11.3079 16.0086 10.8602 16.1669 10.4865 15.9883C9.96169 15.7376 9.47063 15.3953 9.03769 14.9623C6.98744 12.9121 6.98744 9.58794 9.03769 7.53769L13.5377 3.03769C15.5879 0.987437 18.9121 0.987437 20.9623 3.03769C23.0126 5.08794 23.0126 8.41206 20.9623 10.4623L19.2053 12.2193C18.9124 12.5122 18.4376 12.5122 18.1447 12.2193C17.8518 11.9264 17.8518 11.4515 18.1447 11.1586L19.9016 9.40165C21.3661 7.93718 21.3661 5.56282 19.9016 4.09835ZM12.5135 8.36513C12.6921 7.99138 13.1398 7.83313 13.5135 8.01167C14.0383 8.26236 14.5294 8.60475 14.9623 9.03769C17.0126 11.0879 17.0126 14.4121 14.9623 16.4623L10.4623 20.9623C8.41206 23.0126 5.08794 23.0126 3.03769 20.9623C0.987437 18.9121 0.987437 15.5879 3.03769 13.5377L4.79466 11.7807C5.08755 11.4878 5.56243 11.4878 5.85532 11.7807C6.14821 12.0736 6.14821 12.5485 5.85532 12.8414L4.09835 14.5984C2.63388 16.0628 2.63388 18.4372 4.09835 19.9016C5.56282 21.3661 7.93718 21.3661 9.40165 19.9016L13.9017 15.4016C15.3661 13.9372 15.3661 11.5628 13.9017 10.0983C13.5908 9.78748 13.2402 9.54347 12.867 9.36517C12.4932 9.18662 12.335 8.73889 12.5135 8.36513Z"
                  fill="#141414"
                />
              </svg>
            </div>
          </OverlayTrigger>
        </Action>
      </Fab>
    </div>
  );
}

export default Circle;
