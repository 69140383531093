/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import { Footer } from 'components/Footer';
import IcCheck from 'assets/ic_check.svg';
import IcSuccess from 'assets/ic_success.svg';
import IcSad from 'assets/ic_sad.svg';
import IcSignal from 'assets/ic_signal.svg';
import IcVerified from 'assets/ic_verified.svg';

import { SELECTED_THEME, themeColors } from 'utils/theme';
import { FanfliqModal } from 'components/modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HLSAudioPlayer from 'components/hls-audio-player';
import { getAccessToken } from 'api/http-common';
import { DataContext } from 'utils/DataContext';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Album } from 'components/Album';
import { Header } from 'components/Header';
import { Portfolio } from '../components/Portfolio';

export function Home() {
  const { notify, myProfile, curPlayingId, curAlbumId, playlist } =
    useContext(DataContext);
  const navigate = useNavigate();

  const [mainView, setMainView] = useState('');
  const [openModal, setModalOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showSuccess, setShowSucess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [showWelcome, setShowWelcome] = useState(false);
  const [showError, setShowError] = useState(false);

  const [planId, setPlanId] = useState();
  const [subsData, setSusData] = useState();
  const [price, setPrice] = useState();

  const [artist, setArtist] = useState({});

  const audioRef = useRef(null);

  const [searchParams] = useSearchParams();

  const handleCancelFliq = async () => {
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };
    setLoader(true);
    try {
      const data = await axios.post(
        `https://api.fanfliq.com/v1/subscription/cancel`,
        { id: subsData?.id },
        {
          headers: headers,
        },
      );
      setLoader(false);
      setOpenCancel(false);

      if (data.data) {
        setSuccessMsg('FliQ cancelled successfully!');
        setShowSucess(true);
      }
    } catch (e) {
      setLoader(false);

      const errResponse =
        (e && e?.response && e?.response.data && e?.response.data.message) ||
        (e && e?.message) ||
        'There was some issue while cancelling the subscription.';
      notify(errResponse, 'error');
    }
  };

  const fetchArtistDetails = async id => {
    try {
      const response = await axios.get(
        `https://api.fanfliq.com/v1/users/details/${id}`,
      );
      setArtist(response.data);
    } catch (err) {
      notify('Error in loading data', 'error');
    }
  };

  useEffect(() => {
    if (myProfile && artist.avatar) {
      if (searchParams.get('status') === 'success') {
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, '', location.href.split('?')[0]);
        setShowWelcome(true);
      } else if (
        searchParams.get('status') === 'failed' &&
        searchParams.get('error')
      ) {
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, '', location.href.split('?')[0]);
        setShowError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfile, artist]);

  const goToLogin = (
    href = '',
    redirect = `${window.location.origin}/artist/${artist.username}`,
  ) => {
    if (href === '#play') {
      sessionStorage.setItem('curPlayingId', curPlayingId);
      sessionStorage.setItem('playlist', playlist);
      sessionStorage.setItem('curAlbumId', curAlbumId);
    }
    if (searchParams.get('redirect'))
      navigate(`/login?redirect=${searchParams.get('redirect')}`);
    else navigate(`/login?redirect=${redirect}`);
  };

  const handleBuyFliq = () => {
    if (getAccessToken())
      window.location.href = `https://payment-fliq.wedigit.tech/purchase?token=${getAccessToken()}&planId=${planId}&t=${Date.now()}`;
    else goToLogin();
  };

  const fetchPlanDetails = async userId => {
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };
    const planResp = await axios.get(
      `https://api.fanfliq.com/v1/plans?userId=${userId}`,
      {
        headers,
      },
    );

    if (planResp.data.results[0]) {
      setPlanId(planResp?.data.results[0]?.id);
      setPrice(`₹. ${planResp?.data.results[0]?.monthlyPlan} / m`);
    }
  };

  const fetchSubsDetails = async userId => {
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };

    try {
      const resp = await axios.get(
        `https://api.fanfliq.com/v1/subscription?artistId=${userId}`,
        {
          headers,
        },
      );
      if (resp.data.results[0]) setSusData(resp.data.results[0]);
    } catch (err) {}
  };

  useEffect(() => {
    if (artist.id) {
      fetchPlanDetails(artist.id);
      fetchSubsDetails(artist.id);
    }
  }, [artist]);

  useEffect(() => {
    let url = window.location.pathname;
    if (url.includes('artist/')) {
      const id = url.split('/')[2];
      fetchArtistDetails(id);
      setMainView('artist');
    } else if (url.includes('album/')) {
      setMainView('album');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const openBuyFliq = () => setModalOpen(true);

  const handleCancel = () => {
    setOpenCancel(true);
  };

  const renderArtistView = () => (
    <Portfolio
      price={price}
      subsData={subsData}
      handleCancel={handleCancel}
      openBuyFliq={openBuyFliq}
      artist={artist}
    />
  );

  const renderAlbumView = () => (
    <Album fetchArtistDetails={fetchArtistDetails} openBuyFliq={openBuyFliq} />
  );

  const renderMainView = () => {
    if (mainView === 'artist') return renderArtistView();
    else if (mainView === 'album') return renderAlbumView();
    else return <></>;
  };

  return (
    <div
      style={{
        overflowX: 'hidden',
        position: 'relative',
        background: themeColors[SELECTED_THEME].primary,
      }}
    >
      <Header />
      {renderMainView()}
      <Footer />
      <FanfliqModal
        cover={artist?.avatar}
        show={openModal}
        title={
          <div>
            <div
              style={{
                gap: '0.8rem',
                alignItems: 'center',
                display: 'flex',
                marginTop: '0.8rem',
              }}
            >
              <div
                className="text-20 bold"
                style={{ color: themeColors[SELECTED_THEME].btnTxt }}
              >
                {artist.name}
              </div>
              <img
                style={{ width: '2rem', height: '2rem' }}
                src={IcVerified}
                alt="verfied"
              />
            </div>
            <span
              className="text-14"
              style={{
                color: themeColors[SELECTED_THEME].black30,
                lineHeight: '2.4rem',
              }}
            >
              Get your <span style={{ fontWeight: 700 }}>FliQ</span> to access
              exclusivity.{' '}
            </span>
          </div>
        }
        subtitle={
          <div style={{ paddingTop: '0.8rem' }}>
            {[
              { title: 'Exclusive content.', live: true },
              {
                title: 'Give feedback on Unreleased and pre release songs.',
                live: true,
              },
              { title: 'Live stream together.' },
              { title: 'BTS of shoots, Studio and many more.' },
            ].map(item => (
              <div
                style={{
                  display: 'flex',
                  gap: '0.8rem',
                  marginTop: '0.8rem',
                  alignItems: 'center',
                  fontWeight: 400,
                }}
              >
                <img
                  src={IcCheck}
                  alt="check"
                  style={{ height: '1.6rem', width: '1.6rem' }}
                />
                <span
                  className="text-14"
                  style={{
                    color: themeColors[SELECTED_THEME].btnTxt,
                    fontWeight: 400,
                  }}
                >
                  {item.title}{' '}
                  {!item.live ? (
                    <span
                      style={{
                        fontStyle: 'italic',
                        color: themeColors[SELECTED_THEME].golden,
                      }}
                    >
                      (Coming soon).
                    </span>
                  ) : (
                    <></>
                  )}{' '}
                </span>
              </div>
            ))}
          </div>
        }
        info={
          <div className="info-modal text-12" style={{ lineHeight: '2.4rem' }}>
            We’ll notify you before 15 days if the price changes.
          </div>
        }
        primaryBtn={`Get FliQ ${price}`}
        handlePrimaryBtn={handleBuyFliq}
        handleClose={() => setModalOpen(false)}
      />
      <FanfliqModal
        cover={artist?.avatar}
        show={openCancel}
        title={
          <div>
            <div
              style={{
                gap: '0.8rem',
                alignItems: 'center',
                display: 'flex',
                marginTop: '0.8rem',
              }}
            >
              <div
                className="text-20 bold"
                style={{
                  color: themeColors[SELECTED_THEME].btnTxt,
                }}
              >
                {artist.name}
              </div>
              <img
                style={{ width: '2rem', height: '2rem' }}
                src={IcVerified}
                alt="verfied"
              />
            </div>
            <span
              className="text-14"
              style={{
                color: themeColors[SELECTED_THEME].black30,
                lineHeight: '2.4rem',
              }}
            >
              Get your <span style={{ fontWeight: 700 }}>FliQ</span> to access
              exclusivity.{' '}
            </span>
          </div>
        }
        subtitle={
          <div style={{ paddingTop: '0.8rem' }}>
            {[
              { title: 'Exclusive content.', live: true },
              {
                title: 'Give feedback on Unreleased and pre release songs.',
                live: true,
              },
              { title: 'Live stream together.' },
              { title: 'BTS of shoots, Studio and many more.' },
            ].map(item => (
              <div
                style={{
                  display: 'flex',
                  gap: '0.8rem',
                  alignItems: 'center',
                  marginTop: '0.8rem',
                }}
              >
                <img
                  src={IcCheck}
                  alt="check"
                  style={{ height: '1.6rem', width: '1.6rem' }}
                />
                <span
                  className="text-14"
                  style={{
                    color: themeColors[SELECTED_THEME].btnTxt,
                    fontWeight: 400,
                  }}
                >
                  {item.title}{' '}
                  {!item.live ? (
                    <span
                      style={{
                        fontStyle: 'italic',
                        color: themeColors[SELECTED_THEME].golden,
                      }}
                    >
                      (Coming soon).
                    </span>
                  ) : (
                    <></>
                  )}{' '}
                </span>
              </div>
            ))}
          </div>
        }
        info={
          <div className="info-modal text-12" style={{ lineHeight: '2.4rem' }}>
            We’ll notify you before 15 days if the price changes.
          </div>
        }
        secondaryBtn={'Cancel FliQ'}
        primaryBtn={`Close`}
        handlePrimaryBtn={() => setOpenCancel(false)}
        handleClose={() => setOpenCancel(false)}
        handleSecondaryBtn={handleCancelFliq}
        loader={loader}
      />
      <FanfliqModal
        cover={IcSuccess}
        show={showSuccess}
        title={
          <div style={{ marginTop: '1.6rem' }}>
            <span
              className="text-20"
              style={{
                color: themeColors[SELECTED_THEME].primary,
                fontWeight: 400,
              }}
            >
              {successMsg}
            </span>
          </div>
        }
        primaryBtn={`Okay`}
        handlePrimaryBtn={() => window.location.reload()}
      />
      <FanfliqModal
        show={showWelcome}
        title={
          <div>
            <div
              className="text-20 bold"
              style={{
                color: themeColors[SELECTED_THEME].btnTxt,
                lineHeight: '2.4rem',
              }}
            >
              Welcome 🥳
            </div>
            <div
              className="text-14"
              style={{
                color: themeColors[SELECTED_THEME].black30,
                marginTop: '1.6rem',
                lineHeight: '2.4rem',
              }}
            >
              {`Welcome to ${artist.name} backstage.`}{' '}
              {`You are now a part of ${artist.name}'s
              FliQers. Access now`}
            </div>
          </div>
        }
        subtitle={
          <div>
            <div style={{ paddingTop: '1.6rem' }}>
              {[
                { title: 'Exclusive content.', live: true },
                {
                  title: 'Give feedback on Unreleased and pre release songs.',
                  live: true,
                },
                { title: 'Live stream together.' },
                { title: 'BTS of shoots, Studio and many more.' },
              ].map(item => (
                <div
                  style={{
                    display: 'flex',
                    gap: '0.8rem',
                    alignItems: 'center',
                    marginTop: '0.8rem',
                  }}
                >
                  <img
                    src={IcCheck}
                    alt="check"
                    style={{ height: '1.6rem', width: '1.6rem' }}
                  />
                  <span
                    className="text-14"
                    style={{
                      color: themeColors[SELECTED_THEME].btnTxt,
                      fontWeight: 400,
                    }}
                  >
                    {item.title}{' '}
                    {!item.live ? (
                      <span
                        style={{
                          fontStyle: 'italic',
                          color: themeColors[SELECTED_THEME].golden,
                        }}
                      >
                        (Coming soon).
                      </span>
                    ) : (
                      <></>
                    )}{' '}
                  </span>
                </div>
              ))}
            </div>

            <div
              style={{
                marginTop: '2.4rem',
                display: 'flex',
                gap: '0.8rem',
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LazyLoadImage
                placeholderSrc={artist?.thumbnail}
                src={artist?.avatar}
                wrapperClassName="circle-image"
                style={{
                  height: '9.6rem',
                  width: '9.6rem',
                  borderRadius: '100%',
                }}
                alt="1"
              />
              <img src={IcSignal} alt="3" />
              <LazyLoadImage
                placeholderSrc={myProfile?.thumbnail}
                src={myProfile?.avatar}
                wrapperClassName="circle-image"
                style={{
                  height: '9.6rem',
                  width: '9.6rem',
                  borderRadius: '100%',
                }}
                alt="1"
              />
            </div>
          </div>
        }
        primaryBtn={`Okay`}
        handlePrimaryBtn={() => setShowWelcome(false)}
      />
      <FanfliqModal
        show={showError}
        title={
          <div>
            <div
              className="text-20 bold"
              style={{
                color: '#D24141',
                lineHeight: '2.4rem',
              }}
            >
              Oh no! Something went wrong!
            </div>
            <div
              className="text-14"
              style={{
                color: themeColors[SELECTED_THEME].black30,
                marginTop: '1.6rem',
                lineHeight: '2.4rem',
              }}
            >
              Sorry, we had an issue confirming your payment.
              <br /> Please try again.
            </div>
          </div>
        }
        subtitle={
          <div
            style={{
              marginTop: '2.4rem',
              display: 'flex',
              gap: '0.8rem',
              width: '100%',
              alignSelf: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LazyLoadImage
              placeholderSrc={artist?.thumbnail}
              src={artist?.avatar}
              wrapperClassName="circle-image"
              style={{
                height: '9.6rem',
                width: '9.6rem',
                borderRadius: '100%',
              }}
              alt="1"
            />
            <img src={IcSad} alt="3" />
            <LazyLoadImage
              placeholderSrc={myProfile?.thumbnail}
              src={myProfile?.avatar}
              wrapperClassName="circle-image"
              style={{
                height: '9.6rem',
                width: '9.6rem',
                borderRadius: '100%',
              }}
              alt="1"
            />
          </div>
        }
        primaryBtn={`Try Again`}
        secondaryBtn={'Cancel'}
        handlePrimaryBtn={handleBuyFliq}
        handleSecondaryBtn={() => setShowError(false)}
      />
      {curPlayingId ? (
        <HLSAudioPlayer
          audioRef={audioRef}
          openBuyFliq={openBuyFliq}
          goToLogin={goToLogin}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
