/* eslint-disable react-hooks/exhaustive-deps */
import { API_CALLS, callAPI } from 'api';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from 'utils/media';
import { ContentCard } from './ContentCard';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { DataContext } from 'utils/DataContext';

const TABS = [
  {
    title: 'Exclusive',
    url: API_CALLS.GET_CONTENTS,
    params: `visibility=Exclusive&contentType=album,single&sortBy=createdAt:desc&limit=20`,
  },
  {
    title: 'Albums/EPs',
    url: API_CALLS.GET_CONTENTS,
    params: `visibility=Free&contentType=album&sortBy=createdAt:desc&limit=20`,
  },
  {
    title: 'Singles',
    url: API_CALLS.GET_CONTENTS,
    params: `visibility=Free&contentType=single&sortBy=createdAt:desc&limit=20`,
  },
];
export const DiscographySection = ({ userId }) => {
  const [selectedTab, setSelectedTab] = useState(
    window.location.href.split('#')[1]
      ? parseInt(window.location.href.split('#')[1])
      : 0,
  );
  const { notify } = useContext(DataContext);

  const [list, setList] = useState([{}, {}, {}]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 6,
    },
  };

  const loadMusic = async (url, params) => {
    setList([{}, {}, {}]);
    try {
      const dataMusic = await callAPI(url, params);
      setList(dataMusic?.results);
    } catch (e) {
      setList([{}, {}, {}]);
      const errResponse =
        (e && e?.response && e?.response.data && e?.response.data.message) ||
        (e && e?.message) ||
        'There was some issue while loading content.';
      notify(errResponse, 'error');
    }
  };

  useEffect(() => {
    if (selectedTab >= 0 && userId) {
      loadMusic(
        TABS[selectedTab].url,
        `userId=${userId}&${TABS[selectedTab].params}`,
      );
    }
  }, [selectedTab, userId]);

  useEffect(() => {
    if (selectedTab)
      window.location.href = `${window.location.href.split('#')[0]}#${selectedTab}`;
  }, [selectedTab]);

  const renderList = () => (
    <Carousel
      swipeable={false}
      removeArrowOnDeviceType={
        list?.length < 8 ? ['mobile', 'desktop'] : ['mobile']
      }
      itemClass="carousel-item-padding-40-px"
      responsive={responsive}
    >
      {list ? (
        list.map((item, index) => <ContentCard index={index} content={item} />)
      ) : (
        <></>
      )}
    </Carousel>
  );

  return (
    <ParentDiv>
      <div className="hor-flex" style={{ gap: '1.6rem', width: 'auto' }}>
        {TABS.map((tab, index) => (
          <div
            className={index === selectedTab ? 'tab tab-selected' : 'tab'}
            onClick={() => {
              setSelectedTab(index);
            }}
          >
            <div>{tab.title}</div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: '1.6rem' }}>{renderList()}</div>
    </ParentDiv>
  );
};

const ParentDiv = styled.div`
  margin-top: 1.6rem;
  ${media.mobile`width: 100%`};
  ${media.small`width: 100%`};
  ${media.medium`width: 100%; `};
`;
