import { axiosInstance } from './api';
import { ROUTES } from './http-common';

export const getBlogs = async (param: string) => {
  const response = await axiosInstance.get(
    ROUTES.BLOGS.replace('{filters}', param),
  );
  return response.data;
};

export const getBlogById = async (blogId: string) => {
  const response = await axiosInstance.get(
    ROUTES.BLOG.replace('{blogId}', blogId),
  );
  return response.data;
};
