import styled from 'styled-components';
import { media } from 'utils/media';
import icMusic from 'assets/ic_music.svg';
import icCamera from 'assets/ic_camera.svg';
import icPlay from 'assets/ic_play_circle.svg';
import icMerch from 'assets/ic_gift.svg';
import icTicket from 'assets/ic_ticket.svg';
import icHeart from 'assets/ic_heart.svg';
import IcVerified from 'assets/ic_verified.svg';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SELECTED_THEME, themeColors } from 'utils/theme';

export const ArtistDetailsCard = ({
  profile,
  price,
  subsData,
  handleCancel,
  openBuyFliq,
}) => {
  const OFFERINGS = [
    {
      title: 'Pre-releases',
      icon: icMusic,
    },
    {
      title: 'BTS Footages',
      icon: icPlay,
    },
    {
      title: 'Video QnA Sessions',
      icon: icCamera,
    },
    {
      title: 'Merch',
      icon: icMerch,
    },
    {
      title: 'Tickets',
      icon: icTicket,
    },
    {
      title: 'TOP Fans perks',
      icon: icHeart,
    },
  ];
  return (
    <>
      <ParentDiv>
        <FlexDiv>
          {profile?.avatar ? (
            <Image
              alt={'profile'}
              wrapperClassName="circle-image-9"
              placeholderSrc={profile?.thumbnail}
              src={profile?.avatar}
            />
          ) : (
            <ImageSkeleton />
          )}
          <div>
            <div
              style={{
                gap: '0.8rem',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <div className="text-32">
                {profile.name ? (
                  profile.name
                ) : (
                  <Skeleton style={{ flex: 1, width: '15rem' }} />
                )}
              </div>
              <img
                style={{ width: '2.4rem', height: '2.4rem' }}
                src={IcVerified}
                alt="verfied"
              />
            </div>
            {profile.numSongsTxt ? (
              <div
                style={{
                  gap: '0.8rem',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <div
                  className="text-12"
                  style={{
                    lineHeight: '2.4rem',
                  }}
                >
                  {profile.numSongsTxt}
                </div>
                <div
                  style={{
                    width: '0.4rem',
                    height: '0.4rem',
                    background: themeColors[SELECTED_THEME].black60,
                    borderRadius: '4.8rem',
                  }}
                ></div>
                <div
                  className="text-12"
                  style={{
                    lineHeight: '2.4rem',
                  }}
                >
                  {profile.numStreamsTxt}
                </div>
              </div>
            ) : (
              <Skeleton style={{ flex: 1, width: '10rem' }} />
            )}
          </div>
          <div>
            <div
              className="text-12"
              style={{
                color: themeColors[SELECTED_THEME].textPrimary,
                lineHeight: '3.2rem',
                fontWeight: 500,
              }}
            >
              GET EXCLUSIVE ACCESS TO MY
            </div>
            <Grid style={{ marginTop: '0.8rem' }}>
              {OFFERINGS.map((item, index) => (
                <OfferCard key={index}>
                  <img
                    src={item.icon}
                    style={{ width: '1.6rem', height: '1.6rem' }}
                    alt="icon"
                  />
                  <div className="text-12">{item.title}</div>
                </OfferCard>
              ))}
            </Grid>
          </div>
        </FlexDiv>
        <HorizontalDiv>
          {price ? (
            !subsData ? (
              <div
                onClick={openBuyFliq}
                className="btn-brand-small text-12"
                style={{
                  color: 'white',
                  fontWeight: 700,
                  lineHeight: '2.4rem',
                }}
              >
                Subscribe to my FliQ
              </div>
            ) : (
              <></>
            )
          ) : (
            <Skeleton
              style={{
                flex: 1,
                width: '6rem',
                height: '2rem',
                borderRadius: '12rem',
              }}
            />
          )}
          {price ? (
            subsData ? (
              !subsData.cancelAt ? (
                <div
                  onClick={handleCancel}
                  className="btn-black btn-black-hover"
                >{`Cancel FliQ`}</div>
              ) : (
                <div
                  className="text-12"
                  style={{ fontWeight: 500, textTransform: 'uppercase' }}
                >
                  Scheduled to cancel on{' '}
                  <span
                    style={{ color: themeColors[SELECTED_THEME].brand }}
                  >{`${new Date(subsData.cancelAt).toDateString()}`}</span>
                </div>
              )
            ) : (
              <div
                disabled
                style={{
                  color: '#E4E4E7',
                  fontWeight: 900,
                  lineHeight: '4rem',
                }}
                className="text-14"
              >{`${price}`}</div>
            )
          ) : (
            <Skeleton
              style={{
                flex: 1,
                width: '6rem',
                height: '2rem',
                borderRadius: '12rem',
              }}
            />
          )}
        </HorizontalDiv>
      </ParentDiv>
    </>
  );
};

const ParentDiv = styled.div`
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 9.6rem;
  left: 16rem;
  ${media.mobile`padding: 1.6rem`};
  ${media.small`padding: 1.6rem`};
  ${media.medium`padding: 4rem 3.2rem; min-width: 69rem`};
`;

const OfferCard = styled.div`
  border-radius: 0.8rem;
  padding: 1.6rem;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
  background: ${themeColors[SELECTED_THEME].black8};
`;

const Grid = styled.div`
  display: grid;
  column-gap: 1.6rem;
  row-gap: 1.6rem;
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(100%/3, max(64px, 100%/4)), 1fr)
  );
`;

const HorizontalDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.2rem;
`;

const Image = styled(LazyLoadImage)`
  ${media.mobile`width: 8rem; height: 8rem`};
  ${media.small`width: 8rem; height: 8rem`};
  ${media.medium`width: 9.6rem; height: 9.6rem`};
  border-radius: 100%;
`;

const ImageSkeleton = styled(Skeleton)`
  ${media.mobile`width: 8rem; height: 8rem`};
  ${media.small`width: 8rem; height: 8rem`};
  ${media.medium`width: 9.6rem; height: 9.6rem`};
  border-radius: 100%;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile`gap: 1.6rem`};
  ${media.small`gap: 1.6rem`};
  ${media.medium`gap: 1.6rem`};
`;
