/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useRef, useState, useEffect, useContext } from 'react';
import Hls from 'hls.js';
import styled from 'styled-components';
import IcPrev from 'assets/ic_prev.svg';
import IcNext from 'assets/ic_next.svg';
import IcPlay from 'assets/ic_play_bottom.svg';
import IcPause from 'assets/ic_pause_bottom.svg';
import IcVolume from 'assets/ic_volume.svg';
import IcMute from 'assets/ic_mute.svg';
import IcPlayMobile from 'assets/ic_play_mobile.svg';
import IcPauseMobile from 'assets/ic_pause_mobile.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { media } from 'utils/media';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { DataContext } from 'utils/DataContext';
import { Spinner } from 'react-bootstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { getAccessToken } from 'api/http-common';
import { API_CALLS, callAPI } from 'api';

export const toTimeString = (millis = 0) => {
  const minutes = Math.floor(millis / 60000);
  const seconds = parseInt(((millis % 60000) / 1000).toFixed(0), 10);
  return seconds === 60
    ? minutes + 1 + ':00'
    : minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};

const HLSAudioPlayer = ({ audioRef, setShowDownloadApp, goToLogin }) => {
  const hlsRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);

  const {
    isPlaying,
    setIsPlaying,
    setPlayingId,
    curPlayingId,
    playlist,
    notify,
  } = useContext(DataContext);
  const [state, setState] = useState('buffering');
  const [content, setContent] = useState({});

  const handleClickNext = () => {
    const index = playlist.findIndex(item => item === curPlayingId);
    if (index < playlist.length - 1) setPlayingId(playlist[index + 1]);
  };

  const handleClickPrev = () => {
    const index = playlist.findIndex(item => item === curPlayingId);
    if (index > 0) setPlayingId(playlist[index - 1]);
  };

  const fetchTrack = async id => {
    setState('buffering');
    try {
      const resp = await callAPI(API_CALLS.GET_CONTENT, id);
      setContent(resp);
    } catch (e) {
      setContent({});
      setState('');
      const errResponse =
        (e && e?.response && e?.response.data && e?.response.data.message) ||
        (e && e?.message) ||
        'There was some issue while loading content.';
      notify(errResponse, 'error');
    }
  };

  useEffect(() => {
    if (curPlayingId) fetchTrack(curPlayingId);
  }, [curPlayingId]);

  useEffect(() => {
    if (content) {
      if (content.url) {
        if (hlsRef.current) {
          hlsRef.current.destroy();
        }

        if (audioRef.current) {
          var config = {
            emeEnabled: true,

            licenseXhrSetup: function (
              _xhr,
              _url,
              _keyContext,
              _licenseChallenge,
            ) {},
            licenseResponseCallback: function (_xhr, _url, _keyContext) {},

            xhrSetup: function (xhr, url) {
              if (url.includes('fankey://')) {
                url = `https://api.fanfliq.com/v1/auth/getfankey?contentId=${content.id}`;
                xhr.open('get', url);
                xhr.setRequestHeader(
                  'Authorization',
                  `Bearer ${getAccessToken()}`,
                );
              } else xhr.open('get', url);
            },
          };

          hlsRef.current = new Hls(config);
          hlsRef.current.attachMedia(audioRef.current);
          hlsRef.current.on(Hls.Events.MEDIA_ATTACHED, () => {
            hlsRef.current?.loadSource(content?.url);

            hlsRef.current?.on(Hls.Events.MANIFEST_PARSED, () => {
              hlsRef.current?.on(Hls.Events.LEVEL_LOADED, async (_, data) => {
                const duration = data.details.totalduration;
                setDuration(duration);
                setCurrentTime(0);
                setState('buffering');
                await audioRef.current?.play();
                try {
                  callAPI(API_CALLS.TRACK_STREAM, { contentIds: [content.id] });
                } catch (e) {}
                setState('playing');
              });
            });
          });
        }
      } else {
        if (content.id) {
          setState('');
          setIsPlaying(false);

          if (content.visibility === 'Free') goToLogin('#play');
          else {
            if (!content.url) {
              audioRef.current?.pause();
            }
          }
        }
      }
    }
  }, [content, audioRef]);

  useEffect(() => {
    audioRef.current.addEventListener('ended', function () {
      handleClickNext();
    });
  }, []);

  useEffect(() => {
    if (content.url) {
      if (isPlaying) audioRef.current.play();
      else audioRef.current.pause();
    }
  }, [isPlaying]);

  const onSeeking = e => {
    const currentTime = +e;
    audioRef.current.currentTime = currentTime;
    setCurrentTime(currentTime);
  };

  const onProgress = _e => {
    const currentTime = audioRef.current.currentTime;
    setCurrentTime(currentTime);
    if (currentTime >= 15) {
      setShowDownloadApp();
      setIsPlaying(false);
    }
  };

  const onPlay = () => setIsPlaying(true);

  const onPause = () => setIsPlaying(false);

  const onSeekStart = () => setIsPlaying(false);

  const onSeekEnd = () => setIsPlaying(true);

  const onPlayPauseClick = () => {
    console.log(content);
    if (content.url) setIsPlaying(!isPlaying);
    else {
      if (content.visibility === 'Free') goToLogin('#play');
    }
  };

  const onVolumeChange = e => {
    const newVolume = +e;
    setVolume(newVolume);
  };

  useEffect(() => {
    audioRef.current.volume = volume;
  }, [volume]);

  const onMute = () => setIsMuted(!isMuted);

  return (
    <div className="player-parent">
      <audio
        ref={audioRef}
        onTimeUpdate={onProgress}
        onPlay={onPlay}
        onPause={onPause}
        muted={isMuted}
      />
      <div className="left-controls">
        {content.cover ? (
          <Image placeholderSrc={content?.thumbnail} src={content?.cover} />
        ) : (
          <ImageSkeleton />
        )}
        <div>
          {content.title ? (
            <div
              className="text-14 text-play"
              style={{
                textTransform: 'none',
                fontWeight: 700,
                lineHeight: '2.4rem',
              }}
            >
              {content.title}
            </div>
          ) : (
            <Skeleton style={{ flex: 1, width: '10rem' }} />
          )}
          {content.artist ? (
            <div
              className="text-12 text-play-sub"
              style={{ lineHeight: '1.6rem' }}
            >
              {content.artist}
            </div>
          ) : (
            <Skeleton style={{ flex: 1, width: '6rem' }} />
          )}
        </div>
      </div>

      <div className="player-control-hide center-controls">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4.8rem',
          }}
        >
          <div style={{ cursor: 'pointer' }} onClick={handleClickPrev}>
            <img src={IcPrev} />
          </div>
          <div style={{ cursor: 'pointer' }} onClick={onPlayPauseClick}>
            {state !== 'buffering' ? (
              <img src={isPlaying ? IcPause : IcPlay} />
            ) : (
              <Spinner variant="light" />
            )}
          </div>
          <div style={{ cursor: 'pointer' }} onClick={handleClickNext}>
            <img src={IcNext} />
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1.6rem' }}>
          <div
            className="text-10"
            style={{ fontWeight: 400, lineHeight: '2.4rem' }}
          >
            {toTimeString(currentTime * 1000)}
          </div>
          <div className="progress-wrapper">
            {/* <div className="timeline" />
            
            <div
              className="progressline"
              style={{ width: `${(currentTime / duration) * 100}%` }}
            />
            <div
              className="thumb"
              style={{
                marginLeft: `calc(${(currentTime / duration) * 100}% - 8px)`,
              }}
            /> */}
            <Slider
              styles={{
                track: {
                  background: '#fff',
                },
                handle: {
                  width: '1.6rem',
                  height: '1.6rem',
                  background: '#fff',
                  opacity: 1,
                  border: 0,
                },
                rail: {
                  width: '47.7rem',
                  height: '0.4rem',
                  background: 'rgba(255,255,255,0.30)',
                },
              }}
              min={0.0}
              value={currentTime}
              onMouseDown={onSeekStart}
              onMouseUp={onSeekEnd}
              onChangeComplete={onSeeking}
              onChange={onSeeking}
              max={duration}
            />
            {/* <input
              type="range"
              min="0"
              max={duration}
              value={currentTime}
              className="input-slider"
              onInput={onSeeking}
              onChange={onSeeking}
              onMouseDown={onSeekStart}
              onMouseUp={onSeekEnd}
            /> */}
          </div>
          <div
            className="text-10"
            style={{ fontWeight: 400, lineHeight: '2.4rem' }}
          >
            {toTimeString(duration * 1000)}
          </div>
        </div>
      </div>

      <div
        className="player-control-hide"
        style={{ flexDirection: 'column', gap: '0.8rem' }}
      >
        <div className="right-controls">
          <img
            onClick={onMute}
            style={{ cursor: 'pointer' }}
            src={isMuted || volume === 0 ? IcMute : IcVolume}
          />
          <div className="volume-wrapper">
            <Slider
              styles={{
                track: {
                  background: '#fff',
                },
                handle: {
                  width: '1.6rem',
                  height: '1.6rem',
                  background: '#fff',
                  opacity: 1,
                  border: 0,
                },
                rail: {
                  height: '0.4rem',
                  background: 'rgba(255,255,255,0.30)',
                },
              }}
              min={0.0}
              value={volume}
              onChange={onVolumeChange}
              max={1.0}
              step={0.01}
            />
            {/* <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              className="input-slider"
              onInput={onVolumeChange}
              onChange={onVolumeChange}
            /> */}
          </div>
        </div>
      </div>

      <div className="player-control-show" onClick={onPlayPauseClick}>
        {state !== 'buffering' ? (
          <img src={isPlaying ? IcPauseMobile : IcPlayMobile} />
        ) : (
          <Spinner variant="light" />
        )}
      </div>
      <div className="progress-wrapper-mobile">
        <div className="timeline" />
        <div
          className="progressline"
          style={{ width: `${(currentTime / duration) * 100}%` }}
        />
      </div>
    </div>
  );
};

const Image = styled(LazyLoadImage)`
  ${media.mobile`width: 3.2rem; height: 3.2rem`};
  ${media.small`width: 3.2rem; height: 3.2rem`};
  ${media.medium`width: 4.8rem; height: 4.8rem`};
  object-fit: cover;
`;

const ImageSkeleton = styled(SkeletonTheme)`
  ${media.mobile`width: 3.2rem; height: 3.2rem`};
  ${media.small`width: 3.2rem; height: 3.2rem`};
  ${media.medium`width: 4.8rem; height: 4.8rem`};
`;

export default HLSAudioPlayer;
