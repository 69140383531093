import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { media } from 'utils/media';
import IcTicket from 'assets/ic_ticket.svg';

export function OpenOnWeb() {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '3.2rem',
        height: '100vh',
      }}
    >
      <Parent>
        <div>
          <Vertical>
            <img
              src={IcTicket}
              style={{ height: '9.6rem', width: '9.6rem' }}
              alt="profile"
            />
            <div>
              <div
                className="signup"
                style={{ fontSize: '2rem', lineHeight: '3.2rem' }}
              >
                Hi!
              </div>
              <div
                className="subtitle-modal margin-8"
                style={{
                  fontSize: '1.4rem',
                  lineHeight: '2.4rem',
                  marginTop: '0.8rem',
                }}
              >
                {`Open this link ${queryParams.get('redirect')} on your phone browser.`}
              </div>
            </div>
            <BtnFlex>
              <a
                style={{
                  textDecoration: 'none',
                }}
                href={queryParams.get('redirect')}
                target="_blank"
                download
                rel="noreferrer"
              >
                <div className="btn-grey" style={{ background: '#0F0F0F' }}>
                  <div
                    className="text-14"
                    style={{
                      textDecoration: 'none',
                      fontWeight: 600,
                      color: 'white',
                      fontSize: '1.4rem',
                      lineHeight: '3.2rem',
                    }}
                  >
                    Open.
                  </div>
                </div>
              </a>
            </BtnFlex>
          </Vertical>
        </div>
      </Parent>
    </div>
  );
}

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile`gap: 2.4rem`};
  ${media.small`gap: 2.4rem`};
  ${media.medium`gap: 4.8rem;`};
`;

const BtnFlex = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile`gap: 0.8rem`};
  ${media.small`gap: 0.8rem`};
  ${media.medium`gap: 1.6rem`};
`;

const Parent = styled.div`
  border-radius: 2.4rem;
  background: white;
  display: flex;
  flex-direction: column;
  ${media.mobile`padding: 2.4rem 1.6rem; gap: 1.6rem; width: 100%`};
  ${media.small`padding:  2.4rem 1.6rem; gap: 1.6rem; width: 100%`};
  ${media.medium`padding: 4rem 3.2rem; gap: 2.4rem; width: 50rem`};
`;
