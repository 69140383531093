export const themeColors = {
  dark: {
    brand: '#DC143C',
    textPrimary: '#F1F1F3',
    textSecondary: '#BFBFBF',
    textTertiary: '#FCFCFD',
    btnTxt: '#1A1A1A',
    btnBg: '#fff',
    black60: '#999',
    black20: '#333',
    primary: '#0F0F0F',
    white: '#fff',
    header: 'rgba(0, 0, 0, 0.60)',
    footer: '#141414',
    golden: '#F19F00',
    black30: '#4D4D4D',
    grey97: '#F7F7F8',
    black8: '#141414',
    red: '#C62929',
  },
  light: {},
};

export const SELECTED_THEME = 'dark';
