import axios from 'axios';
import { axiosInstance } from './api';
import { BASE_URL, ROUTES, getAccessToken } from './http-common';

export const getContents = async (param: string) => {
  const response = getAccessToken()
    ? await axiosInstance.get(ROUTES.CONTENTS.replace('{filters}', param))
    : await axios.get(
        `${BASE_URL.PROD}/${ROUTES.CONTENTS.replace('{filters}', param)}`,
      );
  return response.data;
};

export const getContentById = async (contentId: string) => {
  const response = await axiosInstance.get(
    ROUTES.CONTENT.replace('{contentId}', contentId),
  );
  return response.data;
};

export const deleteContentById = async (contentId: string) => {
  const response = await axiosInstance.delete(
    ROUTES.CONTENT.replace('{contentId}', contentId),
  );
  return response.data;
};

export const getContentUploadUrl = async (param: string) => {
  const response = await axiosInstance.get(
    ROUTES.CONTENT_UPLOAD.replace('{filters}', param),
  );
  return response.data;
};

export const postContent = async (param: object) => {
  const response = await axiosInstance.post(
    ROUTES.CONTENT.replace('/{contentId}', ''),
    param,
  );
  return response.data;
};

export const shareContent = async (param: string, onProgressListner: any) => {
  const response = await axiosInstance.get(
    ROUTES.CONTENT_SHARE.replace('{contentId}', param),
    {
      onDownloadProgress: onProgressListner,
    },
  );
  return response.data;
};
