import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { media } from 'utils/media';
import IcWallet from 'assets/ic_money.svg';

export function Redeem() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '3.2rem',
        height: '100vh',
      }}
    >
      <Parent>
        <div>
          <Vertical>
            <img
              src={IcWallet}
              style={{ height: '9.6rem', width: '9.6rem' }}
              alt="profile"
            />
            <div>
              <div
                className="signup"
                style={{ fontSize: '2rem', lineHeight: '3.2rem' }}
              >
                Congratulations!.
              </div>
              <div
                className="subtitle-modal margin-8"
                style={{
                  fontSize: '1.4rem',
                  lineHeight: '2.4rem',
                  marginTop: '0.8rem',
                }}
              >
                {`Congratulations on your first revenue ${searchParams.get('name')} Stay tight! You’ll
              be able to redeem from 30th Nov ‘24`}
              </div>
            </div>
            <BtnFlex>
              <div
                className="btn-grey"
                style={{ background: '#0F0F0F' }}
                onClick={() => navigate('/')}
              >
                <div
                  className="text-14"
                  style={{
                    fontWeight: 600,
                    color: 'white',
                    fontSize: '1.4rem',
                    lineHeight: '3.2rem',
                  }}
                >
                  Cool
                </div>
              </div>
            </BtnFlex>
          </Vertical>
        </div>
      </Parent>
    </div>
  );
}

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile`gap: 2.4rem`};
  ${media.small`gap: 2.4rem`};
  ${media.medium`gap: 4.8rem;`};
`;

const BtnFlex = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile`gap: 0.8rem`};
  ${media.small`gap: 0.8rem`};
  ${media.medium`gap: 1.6rem`};
`;

const Parent = styled.div`
  border-radius: 2.4rem;
  background: white;
  display: flex;
  flex-direction: column;
  ${media.mobile`padding: 2.4rem 1.6rem; gap: 1.6rem; width: 100%`};
  ${media.small`padding:  2.4rem 1.6rem; gap: 1.6rem; width: 100%`};
  ${media.medium`padding: 4rem 3.2rem; gap: 2.4rem; width: 50rem`};
`;
