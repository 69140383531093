import styled from 'styled-components';
import { media } from 'utils/media';
import { signInWithGoogle } from '../utils/firebase';
import axios from 'axios';
import IcPhone from 'assets/ic_phone.svg';

import IcGoogle from 'assets/ic_google.svg';
import IcWhiteLogo from 'assets/white_logo.svg';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { DataContext } from 'utils/DataContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { setAuthTokens } from 'api/http-common';

export const LoginComponent = () => {
  const { notify } = useContext(DataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [logingOptions, setLoginOptions] = useState(['google', 'phone']);

  const [loading, setLoading] = useState('');

  const singupWithGoogle = (profile = {}) => {
    axios({
      method: 'post',
      url: 'https://api.fanfliq.com/v1/auth/login/google',
      data: { ...profile },
    })
      .then(async function (response) {
        const headers = {
          Authorization: `Bearer ${response.data.tokens.access.token}`,
        };

        const playRes = await axios.get(
          'https://api.fanfliq.com/v1/users/player/token',
          {
            headers: headers,
          },
        );

        if (playRes.status === 200 && playRes.data) {
          localStorage.setItem('playToken', playRes.data);
        }

        setLoading('');

        setAuthTokens({
          accessToken: response.data.tokens.access.token,
          refreshToken: response.data.tokens.refresh.token,
        });

        localStorage.setItem('name', response.data.user.name);
        localStorage.setItem('id', response.data.user._id);

        let data = {};
        data.id = localStorage.getItem('id');
        data.name = localStorage.getItem('name');
        data.avatar = localStorage.getItem('avatar');

        notify('Successfully logged in!', 'success');

        const queryParams = new URLSearchParams(location.search);

        if (!response.data.user.gender) {
          if (queryParams.get('redirect'))
            navigate(
              `/setup?redirect=${queryParams.get('redirect')}${window.location.hash}`,
            );
          else navigate(`/setup`);
        } else if (queryParams.get('redirect'))
          window.location.href = `${queryParams.get('redirect')}${window.location.hash}`;
        else navigate('/home');
      })
      .catch(function (e) {
        console.log(e);
        //handle error
        setLoading('');
        notify('Error Logging In', 'error');
      });
  };

  const responseGoogle = () => {
    setLoading('google');
    signInWithGoogle().then(result => {
      getResult(result);
    });
  };

  const sendEmailOtpFlow = () => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('redirect') !== null)
      navigate(
        `/email/verify?redirect=${queryParams.get('redirect')}${window.location.hash}`,
      );
    else navigate(`/email/verify`);
  };

  const getResult = async result => {
    const queryParams = new URLSearchParams(location.search);

    if (result && result.user) {
      setLoading('google');
      const profile = {
        type: 'google',
        webLink: queryParams.get('redirect') ? queryParams.get('redirect') : '',
        profile: {
          photos: result.user.photoURL,
          name: result.user.displayName,
          email: result.user.email,
        },
        accessToken: result._tokenResponse.oauthIdToken,
      };

      singupWithGoogle(profile);
    } else {
      setLoading('');
    }
  };

  useEffect(() => {
    if (
      window.navigator.userAgent.includes('Messenger') ||
      window.navigator.userAgent.includes('Instagram')
    ) {
      setLoading('');
      setLoginOptions(['google_otp', 'phone']);
    } else {
      setLoading('');
      setLoginOptions(['google', 'phone']);
    }
  }, []);

  const openNumberflow = () => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('redirect') !== null)
      navigate(
        `/phone?redirect=${queryParams.get('redirect')}${window.location.hash}`,
      );
    else navigate(`/phone`);
  };

  useEffect(() => {
    setLoading('google');
    if (
      window.navigator.userAgent.includes('Messenger') ||
      window.navigator.userAgent.includes('Instagram')
    ) {
      setLoading('');
    } else {
      setLoading('');
    }
  }, []);

  return (
    <Parent>
      <img style={{ width: '16rem' }} src={IcWhiteLogo} alt="" />
      <div>
        <div className="text-38">Music. Fans. Artist.</div>
        <div
          className="text-38"
          style={{ color: themeColors[SELECTED_THEME].brand }}
        >
          United.
        </div>
      </div>
      <div>
        <Vertical>
          <div>
            <div className="signup">Signup.</div>
            <div className="subtitle-modal margin-8">
              Sign up to enter the future of music.
            </div>
          </div>
          <BtnFlex>
            {logingOptions.includes('google') ? (
              loading !== 'google' ? (
                <div className="btn-grey" onClick={responseGoogle}>
                  <img src={IcGoogle} alt="google" />
                  <div
                    className="text-14"
                    style={{
                      fontWeight: 600,
                      color: themeColors[SELECTED_THEME].btnTxt,
                    }}
                  >
                    Sign in with Google
                  </div>
                </div>
              ) : (
                <div className="btn-grey">
                  <Spinner />
                </div>
              )
            ) : (
              <></>
            )}
            {logingOptions.includes('google_otp') ? (
              loading !== 'google_otp' ? (
                <div className="btn-grey" onClick={sendEmailOtpFlow}>
                  <img src={IcGoogle} alt="google_otp" />
                  <div
                    className="text-14"
                    style={{
                      fontWeight: 600,
                      color: themeColors[SELECTED_THEME].btnTxt,
                    }}
                  >
                    Sign in with Google (Email)
                  </div>
                </div>
              ) : (
                <div className="btn-grey">
                  <Spinner />
                </div>
              )
            ) : (
              <></>
            )}
            {logingOptions.includes('phone') ? (
              loading !== 'google' ? (
                <div className="btn-grey" onClick={openNumberflow}>
                  <img src={IcPhone} alt="fb" />
                  <div
                    className="text-14"
                    style={{
                      fontWeight: 600,
                      color: themeColors[SELECTED_THEME].btnTxt,
                    }}
                  >
                    Continue with mobile
                  </div>
                </div>
              ) : (
                <div className="btn-grey">
                  <Spinner />
                </div>
              )
            ) : (
              <></>
            )}
          </BtnFlex>
        </Vertical>
      </div>
      <span className="bottom-text">
        By continuing, you agree to our{' '}
        <a
          href="https://docs.google.com/document/d/e/2PACX-1vT17gknQqH8pBHFKRS7hsQdgDTRighgM-1JppwlqIa1KhXc2zz58VHh9O-GvAsjEA/pub"
          style={{
            textDecoration: 'underline',
            color: themeColors[SELECTED_THEME].black60,
          }}
          target="_blank"
          rel="noreferrer"
        >
          Terms & conditions
        </a>{' '}
        and{' '}
        <a
          href="https://docs.google.com/document/d/e/2PACX-1vRXWOyzxjKQ1gcdO-YxJTuqlAym3StrAzwg2bXrRjpTeVmzqy-369YhcTe7CAu3IA/pub"
          style={{
            textDecoration: 'underline',
            color: themeColors[SELECTED_THEME].black60,
          }}
          target="_blank"
          rel="noreferrer"
        >
          Privacy policy
        </a>
      </span>
    </Parent>
  );
};

const Parent = styled.div`
  border-radius: 2.4rem;
  background: white;
  display: flex;
  flex-direction: column;
  ${media.mobile`padding: 3.2rem; gap: 1.6rem; min-width: 100%`};
  ${media.small`padding: 3.2rem; gap: 1.6rem; min-width: 100%`};
  ${media.medium`padding: 4rem 3.2rem; gap: 2.4rem; width: auto;  min-width: 50rem`};
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile`gap: 2.4rem`};
  ${media.small`gap: 2.4rem`};
  ${media.medium`gap: 4.8rem`};
`;

const BtnFlex = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile`gap: 0.8rem`};
  ${media.small`gap: 0.8rem`};
  ${media.medium`gap: 1.6rem`};
`;
