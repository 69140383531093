import { HeaderWebsite } from 'components/HeaderWebsite';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { Footer } from 'components/Footer';
import Accordion from 'react-bootstrap/Accordion';

const FAQS = [
  {
    title: 'What is FanFliQ',
    content: (
      <>
        FanFliQ is an innovative platform designed to enhance engagement between
        artists and their fans. It allows fans to be a part of new projects,
        songs, EPs, behind-the-scenes (BTS) content, and much more from their
        favorite artists. By joining the FanFliQ community, fans gain exclusive
        backstage access and enjoy numerous perks. <br />
        <br />
        For artists, FanFliQ offers unique monetization opportunities, enabling
        them to generate up to 1000 times more revenue compared to traditional
        streaming platforms.
        <br />
        <br />
        When you acquire a release on FanFliQ, you're not simply buying music –
        you're getting a sneak peek into an artist's creative world. This can
        encompass their newest tracks, exclusive content, priority access to
        concert tickets, merchandise, and a host of other perks. It's a premium
        experience for fans, providing much more than the usual streaming or
        purchasing options available on other platforms.
      </>
    ),
  },
  {
    title: 'What is personal data?',
    content: (
      <>
        "Personal data" includes information that can directly identify,
        describe, or be linked to you or your household, either directly or
        indirectly.
        <br />
        <br />
        Additionally, we may collect data that can't identify you or isn't
        connected to you, like deidentified, grouped, or anonymized data. This
        type of data isn't considered personal data, so our use of it isn't
        covered by this Policy.
        <br />
      </>
    ),
  },
  {
    title: 'Third-party Links',
    content: (
      <>
        Our Website may contain links to third-party websites, plugins, and
        applications for payment. Activation of these links or connections may
        enable third parties to collect or disclose personal data concerning
        you.
      </>
    ),
  },
  {
    title: 'Cookies',
    content: (
      <>
        We may receive information from cookies (small text files placed on your
        computer or device) and similar technologies. We (and our third-party
        service providers) place first-party cookies that enable you to use the
        services and help us analyze and improve your experience and services.
        We use cookies on our website that collect information to enable us and
        third parties to understand your use of FanFliQ.
      </>
    ),
  },
  {
    title: 'Social networking features and widgets',
    content: (
      <>
        We use social media features that run on FanFliQ. If you provide us with
        your social media information, we may use it to interact with you on
        these social media sites. These features may collect your IP address and
        other personal information, including the page you are visiting on our
        site, and may set a cookie to enable the feature to function properly.
        Social media features and widgets are either stored by a third party or
        stored directly by FanFliQ. Your interactions with these features are
        governed by the Privacy Policy of the company providing them. For more
        information about the technologies used by these social networks, please
        refer to their specific privacy notices.
        <br />
        <br />
        Social media functionalities and widgets utilized on our platform are
        powered by FanFliQ. If you opt to share your social media credentials
        with us, we may engage with you through these social platforms. These
        functionalities might gather your IP address and other personal data,
        such as the page you're currently browsing on our site, and could employ
        cookies for optimal performance. Social media functionalities and
        widgets may be hosted by third parties or directly by FanFliQ. Your
        interactions with these tools adhere to the respective Privacy Policy of
        the hosting company. For further insights into the technologies employed
        by these social networks, please consult their individual privacy
        disclosures.
        <br />
        <br />
        We disclaim responsibility for, and this Policy does not extend to, the
        content, security, or privacy practices of such other websites, plugins,
        or applications. We advise you to review the privacy and cookie
        policies/notices of these third parties to comprehend the utilization of
        your personal data.
      </>
    ),
  },
  {
    title: 'Declining to Provide Personal Data',
    content: (
      <>
        To furnish access to the Services or specific features and
        functionalities of the Services in accordance with our contractual
        obligations to you (i.e., our Terms of Service), it is imperative for us
        to gather specific personal data from you. Moreover, we may be mandated
        to process certain personal data in compliance with applicable laws.
        Please be advised that failure to provide personal data as requested may
        preclude us from providing access to the Services or specific features
        and functionalities.
      </>
    ),
  },
  {
    title: 'Updates to this Policy',
    content: (
      <>
        We reserve the right to periodically amend this Policy, with any
        alterations becoming effective upon posting the revised Policy on our
        Website. We shall exert reasonable endeavors to apprise you of
        substantial updates to this Policy, including through the transmission
        of a feed notification or a chat message via your account on our
        Website.
      </>
    ),
  },
  {
    title: 'Information you provide to us as a fan',
    content: (
      <>
        1. Phone Number
        <br />
        2. Username verification
        <br />
        3. Username . <br />
        5. First and last name . <br />
        6. Date of birth . <br />
        7. Gender . <br />
        8. Location . <br />
        9. Email address . <br />
      </>
    ),
  },
  {
    title: 'Information you provide to us as a Creator',
    content: (
      <>
        1.full name*
        <br />
        2. alias, if applicable
        <br />
        3. residential address
        <br />
        5. country of residence* . <br />
        6. email address . <br />
        7. telephone number <br />
        8. a "selfie" of you holding your government identity document
        <br />
        9. third-party social media handle / personal website address (used to
        further verify your age and identity and to help us better understand
        the content which you are likely to share on our Website). <br />
        10. signature on release forms if you feature in another Creator's
        content <br />
        Additionally, you retain the discretion to augment further details to
        your public profile, encompassing links to your social media platforms
        and a descriptive section, all of which are stored in conjunction with
        any remarks or communications you submit on FanFliQ.
      </>
    ),
  },
  {
    title: 'Disclosure of Information to Creator Managers',
    content: (
      <>
        Upon subscribing to a creator, you consent to the dissemination of the
        following information to the respective manager of said creator: <br />
        1. Your name , Gender and any other details provided within your
        profile. <br />
        2. All communications you transmit to the creator via FanFliQ. <br />
        3. Encompassing city, state, and country. <br />
        4. Certain aggregated and anonymized data pertaining to your utilization
        of FanFliQ. <br />
        The creator managers affirm to uphold the privacy of subscriber members
        throughout and beyond their association.
      </>
    ),
  },
  {
    title: 'Legal Basis for Processing Personal Data',
    content: (
      <>
        We engage in the processing of personal data, relying on one or more of
        the following legal grounds: 1. Performance of a Contract: By utilizing
        the Services, you enter into a contractual agreement with us through our
        Terms of Service. Consequently, we process personal data to fulfil the
        obligations outlined in that contract (e.g., facilitating transactions
        between Fans and Creators and managing Creator earnings) and to enforce
        the terms therein. <br />
        2. Legitimate Interests: We may process personal data if it aligns with
        our or a third party's legitimate interests, as delineated in the
        accompanying table. This includes activities such as investigating and
        addressing reports submitted through our DMCA takedown procedure to
        safeguard a Creator's intellectual property rights. <br />
        3. Compliance with Legal Obligations: As a global entity, we may process
        personal data to adhere to relevant laws, regulations, and mandates in
        the jurisdictions where we operate. <br />
        4. Task Carried Out in the Public Interest: Personal data may be
        processed when necessary to fulfil a task performed in the public
        interest. This encompasses actions like reporting illicit activities to
        pertinent law enforcement agencies, governmental bodies, and
        non-governmental organizations. <br />
        <br />
        We pledge to utilize your personal data solely for the purposes for
        which it was originally collected unless we reasonably determine that
        alternative usage is necessary and compatible with the initial purpose.
      </>
    ),
  },
  {
    title: 'Obtaining your Personal Data',
    content: (
      <>
        We acquire your personal data from the following categories of sources:
        Directly from you: This occurs when you furnish it to us directly for
        the purpose of establishing an account and utilizing the Services when
        you modify your personal data within your account, or through
        communication with us (e.g., User Data, Account Data). <br />
        Automatically or indirectly from you: This includes data obtained
        through your utilization of the Services, such as Transaction Data,
        Technical Data, and Usage Data. <br />
        From our service providers: In instances permitted by applicable law, we
        receive Third-Party Onboarding Data and specific Technical Data from
        third-party age and identity verification providers. <br />
      </>
    ),
  },
  {
    title: 'Your rights regarding Personal Data',
    content: (
      <>
        You possess certain entitlements concerning the collection and
        processing of personal data. These rights may be exercised by contacting
        us using the contact information provided in Section (assistance and
        contact details). <br />
        Under specific circumstances and subject to particular exemptions, you
        retain the following rights:
        <b>Withdrawal of Consent:</b> You have the right to retract your consent
        to the processing of your personal data. Please be aware that
        withdrawing your consent will not affect the legality of any processing
        conducted prior to the withdrawal. <br />
        <b>Request for Correction of Your Personal Data:</b> You have the right
        to request the correction of any incomplete or inaccurate personal data
        we hold about you. <br />
        <b>Request for Deletion/Eradication of Your Personal Data:</b> You may
        request the deletion or removal of your personal data where there is no
        legitimate reason for us to continue processing it. Additionally, you
        may request deletion or removal of your personal data if you have
        exercised your right to object to processing (as detailed below). Please
        note that we may not always be able to comply with your
        deletion/eradication request due to specific legal grounds. <br />
        <b>Request for Restriction of Processing of Your Personal Data:</b> You
        have the right to request the suspension of processing personal data
        about you, such as if you wish to verify its accuracy or the rationale
        behind its processing. <br />
      </>
    ),
  },
  {
    title: 'Data Retention',
    content: (
      <>
        We keep your personal data only as long as necessary to provide you with
        the FanFliQ Service and for FanFliQ 's legitimate and essential business
        purposes, such as: <br />
        Under specific circumstances and subject to particular exemptions, you
        retain the following rights: maintaining the performance of the FanFliQ
        Service
        <br />
        making data-driven business decisions about new features and offerings .{' '}
        <br />
        complying with our legal obligations.
        <br />
        resolving disputes
        <br />
        ,Data retained until your FanFliQ account is deleted: We keep some data
        until your FanFliQ account is deleted. Examples of this include your
        FanFliQ username and profile information. We also typically keep
        streaming history for the life of an account, for example, to provide
        retrospective playlists that users enjoy and personalised
        recommendations that take listening habits into account When your
        FanFliQ account is deleted, this category of your data is deleted or
        de-identified.
      </>
    ),
  },
  {
    title: 'Assistance and contact information',
    content: (
      <>
        We have appointed a Data Protection Officer ("DPO") whose
        responsibilities include, together with our team of privacy specialists,
        responding to questions, requests, and concerns in relation to this
        Policy and our use of personal data. <br />
        <br />
        <br />
        If you have questions about this Policy, or how we process your personal
        data, please submit a ticket through your account or email us at
        admin@fanfliq.app.
      </>
    ),
  },
];

export function Privacy() {
  return (
    <div>
      <HeaderWebsite />
      <div
        className="padding-top-12"
        style={{ backgroundColor: themeColors[SELECTED_THEME].primary }}
      >
        <div className="section-web p-88" style={{}}>
          <div className="w-1032 gap-16 child section-web-left">
            <div
              style={{
                zIndex: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div className="brand-tag ph-16" style={{ width: 'fit-content' }}>
                <div>Privacy Policy</div>
              </div>
              <div className="subtitle-web margin-top-24">
                FliqMedia Tech PRIVATE LIMITED a company having its registered
                office at A-20/5, Chetram Gali, Maujpur, Delhi-110053
                (hereinafter referred to as "FanFliQ") operates as a forum
                facilitating interactions among content creators and their Fans
                by creating a community. <br />
                <br /> FanFliQ is a social network which enables: <br />
                <br /> (i) "Creators" to share and monetise their own content
                (as well as subscribe to, and view, the content of other
                Creators); and <br /> (ii) "Fans" to subscribe to, and view, the
                content of Creators. FanFliQ, being a private limited company,
                operates on a large scale.
                <br /> <br /> By accessing and utilizing our platform
                ("FanFliQ"), you consent to the transfer, storage, and
                processing of any personal information you furnish directly to
                us or that we gather through your interaction with the platform,
                in accordance with the terms delineated within this Policy. At
                FanFliQ, we take privacy seriously because we believe in
                creating inclusive and welcoming spaces for everyone. Your
                privacy matters to us, and we're committed to safeguarding it.
                <br /> <br />
                When you use FanFliQ, we may collect different types of
                information: the details you share with us directly, data we
                automatically gather, and information we receive from other
                sources.
              </div>
              <div className="gap-16 margin-top-48-small">
                <Accordion
                  className="w-1032"
                  defaultActiveKey={'0'}
                  alwaysOpen={true}
                >
                  {FAQS.map((faq) => (
                    <Accordion.Item className="w-1032" eventKey={`0`}>
                      <Accordion.Header className='no-icon'>{faq.title}</Accordion.Header>
                      <Accordion.Body>{faq.content}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
