import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { media } from 'utils/media';
import IcWhiteLogo from 'assets/white_logo.svg';
import IcClose from 'assets/ic_close.svg';

import { SELECTED_THEME, themeColors } from 'utils/theme';

export const FanfliqModalCommon = ({ show, handleClose, title }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className="modal-dialog-login"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ padding: 0 }}>
        <Parent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <img style={{ width: '16rem' }} src={IcWhiteLogo} alt="" />
            <img
              style={{ cursor: 'pointer' }}
              onClick={handleClose}
              src={IcClose}
              alt=""
            />
          </div>
          <div>
            <div className="text-38">Music. Fans. Artist.</div>
            <div
              className="text-38"
              style={{ color: themeColors[SELECTED_THEME].brand }}
            >
              United.
            </div>
            <div className="margin-top-24">{title}</div>
          </div>
        </Parent>
      </Modal.Body>
    </Modal>
  );
};

const Parent = styled.div`
  border-radius: 2.4rem;
  background: white;
  display: flex;
  flex-direction: column;
  ${media.mobile`padding: 3.2rem; gap: 1.6rem; min-width: 100%`};
  ${media.small`padding: 3.2rem; gap: 1.6rem; min-width: 100%`};
  ${media.medium`padding: 4rem 3.2rem; gap: 2.4rem; width: auto; min-width: 50rem`};
`;
