import styled from 'styled-components';
import { media } from 'utils/media';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { Form, Spinner } from 'react-bootstrap';
import { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataContext } from 'utils/DataContext';
import { getAccessToken } from 'api/http-common';

export const ProfileDataComponent = () => {
  const { notify } = useContext(DataContext);

  const GENDER = ['Male', 'Female', 'Other'];
  const [selectedGender, setSelectedGender] = useState();
  const [name, setName] = useState(localStorage.getItem('name'));
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleSubmit = () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };

    axios({
      method: 'patch',
      url: `https://api.fanfliq.com/v1/users/${localStorage.getItem('id')}`,
      headers: headers,
      data: { name: name, gender: selectedGender },
    })
      .then(function (response) {
        setLoading(false);
        if (searchParams.get('redirect'))
          window.location.href = searchParams.get('redirect');
        else navigate('/home');
      })
      .catch(function () {
        //handle error
        setLoading('');
        notify('Error settin up', 'error');
      });
  };

  return (
    <Parent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <div className="text-38">
          Complete your <br /> profile.
        </div>
      </div>
      <Vertical>
        <div>
          <Form.Label
            className="text-12"
            style={{ color: themeColors[SELECTED_THEME].black60 }}
          >
            FULL NAME
          </Form.Label>
          <Input
            onChange={e => setName(e.target.value)}
            value={name ? name : ''}
            style={{ marginTop: '0.8rem' }}
            placeholder="Type here"
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              marginTop: '4rem',
              gap: '2.4rem',
            }}
          >
            {GENDER.map((g, i) => (
              <Form.Check
                type="radio"
                className="text-16"
                onChange={e => setSelectedGender(g)}
                name="gender"
                id={i}
                style={{
                  color: themeColors[SELECTED_THEME].primary,
                  width: '25%',
                }}
                label={g}
              />
            ))}
          </div>
          <BtnFlex>
            <button
              onClick={() => navigate(-1)}
              className="btn-grey"
              style={{ width: '50%' }}
            >
              <div
                className="text-14"
                style={{
                  fontWeight: 600,
                  color: themeColors[SELECTED_THEME].btnTxt,
                }}
              >
                Back
              </div>
            </button>
            {!loading ? (
              <button
                className="btn-brand"
                disabled={!selectedGender || !name}
                style={{ width: '50%' }}
                onClick={handleSubmit}
              >
                <div
                  className="text-14"
                  style={{
                    fontWeight: 600,
                    color: themeColors[SELECTED_THEME].white,
                  }}
                >
                  Let me in
                </div>
              </button>
            ) : (
              <div
                className="btn-brand"
                disabled={!selectedGender || !name}
                style={{ width: '50%' }}
                type="submit"
              >
                <Spinner />
              </div>
            )}
          </BtnFlex>
        </div>
      </Vertical>
    </Parent>
  );
};

const Parent = styled.div`
  border-radius: 2.4rem;
  background: white;
  display: flex;
  flex-direction: column;
  ${media.mobile`padding: 2.4rem; gap: 1.6rem; width: 100%`};
  ${media.small`padding: 2.4rem; gap: 1.6rem; width: 100%`};
  ${media.medium`padding: 4rem 3.2rem; gap: 2.4rem; width: auto;  min-width: 50rem`};
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled(Form.Control)`
  border-radius: 8px;
  border: 0px;
  background: var(--Greay-Shades-97, #f7f7f8);
  color: #0f0f0f !importnant;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  ${media.mobile`font-size: 1.6rem; line-height: 2.4rem; padding: 1.2rem 1.6rem; height: 4.8rem; width: 100%`};
  ${media.small`font-size: 1.6rem; line-height: 2.4rem; padding: 1.2rem 1.6rem; height: 4.8rem; width: 100%`};
  ${media.medium`font-size: 1.4rem; line-height: 2.4rem; padding: 2rem 1.6rem; height: 6.4rem; width: 100%`};
`;

const BtnFlex = styled.div`
  display: flex;
  width: 100%;
  ${media.mobile`margin-top: 4rem; gap: 0.8rem`};
  ${media.small`margin-top: 7.2rem; gap: 0.8rem`};
  ${media.medium`margin-top: 7.2rem; gap: 1.6rem`};
`;
