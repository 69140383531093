import styled from 'styled-components';
import { media } from 'utils/media';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SELECTED_THEME, themeColors } from 'utils/theme';

export const AboutUsCard = ({ profile }) => {
  return (
    <>
      <ParentDiv>
        {profile?.avatar ? (
          <Image
            alt={'profile'}
            placeholderSrc={profile?.thumbnail}
            src={profile?.avatar}
          />
        ) : (
          <ImageSkeleton />
        )}
        <SubTitle>{profile.bio}</SubTitle>
      </ParentDiv>
    </>
  );
};

const SubTitle = styled.div`
  color: ${themeColors[SELECTED_THEME].black60};
  font-family: Poppins;
  font-weight: 500;
  ${media.mobile`font-size: 1.2rem; line-height: 2.4rem; margin: 0 1.6rem`};
  ${media.small`font-size: 1.2rem; line-height: 2.4rem; margin: 0 1.6rem`};
  ${media.medium`font-size: 1.6rem; line-height: 3.2rem; margin: 0 2.4rem`};
`;

const ParentDiv = styled.div`
  display: flex;
  border-radius: 0.8rem;
  flex-direction: column;
  background: ${themeColors[SELECTED_THEME].black8};
  ${media.mobile`gap: 1.6rem; width: 100%;padding-bottom: 2.4rem`};
  ${media.small`gap: 1.6rem; width: 100%;padding-bottom: 2.4rem`};
  ${media.medium`gap: 2.4rem; width: 79rem; margin-top: 0.8rem; padding-bottom: 3.2rem`};
`;

const Image = styled(LazyLoadImage)`
  border-radius: 0.8rem 0.8rem 0 0;
  ${media.mobile`width: 100%; aspect-ratio: 16 / 9; height: auto`};
  ${media.small`width: 100%; aspect-ratio: 16 / 9; height: auto`};
  ${media.medium`width: 79rem; height: 48rem`};
  object-fit: cover;
`;

const ImageSkeleton = styled(Skeleton)`
  border-radius: 0.8rem 0.8rem 0 0;
  ${media.mobile`width: 100%; aspect-ratio: 16 / 9; height: auto`};
  ${media.small`width: 100%; aspect-ratio: 16 / 9; height: auto`};
  ${media.medium`width: 79rem; height: 48rem`};
  object-fit: cover;
`;
