import { HeaderWebsite } from 'components/HeaderWebsite';
import IcAppstore from 'assets/ic_appstore.svg';
import IcTickred from 'assets/ic_check_red.svg';
import IcFliqType from 'assets/fliq_type_icon.svg';
import IcPlaystore from 'assets/ic_playstore.svg';
import { Footer } from 'components/Footer';
import { Fade } from 'react-awesome-reveal';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { createRef, useEffect, useState } from 'react';

const FEATURES = [
  {
    tag: 'CREATE',
    title: 'The artist creates FliQ family.',
    subtitle:
      'Create your FliQ family so that your super fans can join you backstage and have exclusive access to your projects and more.',
    image: 'step1.webp',
  },
  {
    tag: 'SHARE',
    title: 'Share your FliQ link on socials.',
    subtitle: 'Invite your fans from social media channels.',
    image: 'step2.webp',
  },
  {
    tag: 'JOIN',
    title: 'Fans join the artist’s backstage.',
    subtitle:
      'Fans engage through a fully immersive, artist-branded experience.',
    image: 'step3.webp',
  },
  {
    tag: 'FAMILY',
    title:
      'They join projects, gain exclusive early access to music, and more.',
    subtitle:
      'BTS, Exclusive music before streaming platforms, Meet n greet, Polls and more.',
    image: 'step4.webp',
  },
  {
    tag: 'DATA',
    title: 'While the artist gains deeper insights into their fanbase.',
    subtitle:
      'Beyond mere demographic data, FanFliQ offers much deeper insights.',
    image: 'step5.webp',
  },
  {
    tag: 'FLIQS',
    title: 'Engage your superfans with FliQs.',
    subtitle: (
      <>
        With a FliQ, you can create time-based polls, audios, videos, or
        pictures to involve your superfans in your next project.
      </>
    ),
    image: 'step7.webp',
  },
];

const FLIQ_TYPE = [
  {
    tag: 'MUSIC, TICKETS, MERCH',
    title: 'Sell projects, tickets and merch.',
    subtitle:
      'Give access to your music, tickets and merch before anywhere else.',
    image: 'fliq_type.webp',
  },
  {
    tag: 'TIME BASED FLIQS',
    title: 'Community Engagement with exclusive experience.',
    subtitle:
      'Use time-based FliQs to engage your superfans with your upcoming projects.',
    image: 'fliq_poll.webp',
    list: [
      'Start a conversation.',
      'Tell something nobody knows.',
      'Let your fans decide through polls.',
      'Share a moment.',
    ],
  },
];

export function ForArtist() {
  const [elRefs, setElRefs] = useState([]);
  const [elRefs2, setElRefs2] = useState([]);

  const [activeIndex, setActiveIndex] = useState();
  const [activeIndex2, setActiveIndex2] = useState();

  useEffect(() => {
    setElRefs(elRefs =>
      Array(7)
        .fill()
        .map((_, i) => elRefs[i] || createRef()),
    );
    setElRefs2(elRefs =>
      Array(7)
        .fill()
        .map((_, i) => elRefs[i] || createRef()),
    );
  }, []);

  useEffect(() => {
    if (elRefs.length > 0) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [elRefs]);

  useEffect(() => {
    if (elRefs2.length > 0) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [elRefs2]);

  const handleScroll = e => {
    elRefs.forEach((ref, index) => {
      if (ref.current) {
        const leftDiv = ref.current;

        if (leftDiv) {
          const scrollTop = leftDiv.getBoundingClientRect().top;
          if (scrollTop >= 0 && scrollTop <= window.innerHeight) {
            setActiveIndex(index);
            return;
          }
        }
      }
    });

    elRefs2.forEach((ref, index) => {
      if (ref.current) {
        const leftDiv = ref.current;

        if (leftDiv) {
          const scrollTop = leftDiv.getBoundingClientRect().top;
          if (scrollTop >= 0 && scrollTop <= window.innerHeight) {
            setActiveIndex2(index);
            return;
          }
        }
      }
    });
  };

  return (
    <div>
      <HeaderWebsite />
      <div className="padding-top-8">
        <div className="section-web banner-bg-artist section-web-first">
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div className="w-858 child section-web-first-artist">
              <div
                className="section-content"
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span>
                  <span className="brand-web-large">
                    <span
                      style={{ color: themeColors[SELECTED_THEME].textPrimary }}
                    >
                      Join
                    </span>{' '}
                    FanFliQ
                  </span>
                </span>
                <div
                  className="text-align-center subtitle-16-web margin-top-16"
                  style={{ textAlign: 'center' }}
                >
                  An all-in-one tool for artists to manage their fan base better
                  and maximize revenue, connection, and popularity.
                </div>
                <div
                  className="subtitle-16-web margin-top-32"
                  style={{
                    color: themeColors[SELECTED_THEME].textPrimary,
                    fontWeight: 700,
                  }}
                >
                  Get FanFliQ for Artist Now!
                </div>
                <div
                  style={{ display: 'flex' }}
                  className="gap-16 margin-top-16 hover-link-ff"
                >
                  <a href="https://play.google.com/store/apps/details?id=app.fliqmedia.fanfliq">
                    <img
                      className="store-btn"
                      src={IcPlaystore}
                      alt="playstore"
                    />
                  </a>
                  <a href="https://apps.apple.com/in/app/fanfliq-for-artist/id6497226407">
                    <img
                      className="store-btn"
                      src={IcAppstore}
                      alt="appstore"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <div
          className="section-web banner-bg-artist-2 section-web-first"
          style={{ alignItems: 'center' }}
        >
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div className="w-858 child">
              <div
                className="section-content"
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span
                  className="brand-web-large"
                  style={{ textAlign: 'center' }}
                >
                  <span
                    style={{
                      color: themeColors[SELECTED_THEME].textPrimary,
                    }}
                  >
                    How does
                  </span>{' '}
                  FanFliQ{' '}
                  <span
                    style={{
                      color: themeColors[SELECTED_THEME].textPrimary,
                    }}
                  >
                    work.
                  </span>
                </span>
              </div>
            </div>
          </Fade>
        </div>
        <div
          className="section-web padding-top-72"
          style={{ backgroundColor: themeColors[SELECTED_THEME].primary }}
        >
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div
              className="child w-1032 gap-16"
              style={{ height: 'auto', position: 'relative' }}
            >
              <div
                className="w-508 left-content gap-72"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                {FEATURES.map((feat, index) => (
                  <div
                    key={index}
                    className="w-508 h-100vh gap-16-mobile"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      key={index}
                      className="w-508 hide-in-desktop"
                      src={require(`assets/artist/${feat.image}`)}
                      alt={feat.image}
                    />
                    <div
                      ref={elRefs[index]}
                      className={
                        activeIndex === index
                          ? 'trigger-text img-active'
                          : 'trigger-text'
                      }
                    >
                      <div
                        className="brand-tag ph-16"
                        style={{ width: 'fit-content' }}
                      >
                        <div>{feat.tag}</div>
                      </div>
                      <div className="title-web margin-top-24">
                        {feat.title}
                      </div>
                      <div className="subtitle-web margin-top-16">
                        {feat.subtitle}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-508 right-content">
                <div className="collection">
                  {FEATURES.map((feat, index) => (
                    <img
                      key={index}
                      className={
                        activeIndex === index
                          ? 'w-508 img-scroll trigger-img img-active'
                          : 'w-508 img-scroll trigger-img'
                      }
                      src={require(`assets/artist/${feat.image}`)}
                      alt={feat.image}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <div className="div-strip">
          <div>
            <div>MUSIC. FANS. ARTIST. UNITED. MUSIC. FANS. ARTIST. UNITED.</div>
          </div>
        </div>
        <div
          className="section-web"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: themeColors[SELECTED_THEME].primary,
          }}
        >
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div className="w-1032 gap-16 child">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  className="brand-tag ph-16"
                  style={{ width: 'fit-content', alignSelf: 'center' }}
                >
                  <div>EXCLUSIVE MUSIC</div>
                </div>
                <div
                  className="text-align-center title-web margin-top-24"
                  style={{ textAlign: 'center' }}
                >
                  Pre-release your music & let your fans purchase to get it
                  exclusively on FanFliQ.
                </div>

                <img
                  className={'w-508 margin-top-72'}
                  src={require(`assets/prerelease.webp`)}
                  alt={'pre-release'}
                />
              </div>
            </div>
          </Fade>
        </div>
        <div
          className="section-web"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: themeColors[SELECTED_THEME].primary,
          }}
        >
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div className="w-1032 gap-16 child">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  className="brand-tag ph-16"
                  style={{ width: 'fit-content', alignSelf: 'center' }}
                >
                  <div>FLIQS</div>
                </div>
                <div
                  className="text-align-center title-web margin-top-24"
                  style={{ textAlign: 'center' }}
                >
                  How a FliQ works.
                </div>
                <div className="w-1032 gap-16 flex-responsive margin-top-72">
                  <div className="w-508">
                    <img
                      className={'w-508'}
                      src={require(`assets/fliq_create.webp`)}
                      alt={'fliq-create'}
                    />
                  </div>
                  <div className="w-508" style={{ zIndex: 2 }}>
                    <div
                      style={{ display: 'flex', flexDirection: 'column' }}
                      className="gap-56"
                    >
                      <div>
                        <div
                          className="subtitle-16-web"
                          style={{
                            color: themeColors[SELECTED_THEME].textPrimary,
                            fontWeight: 700,
                          }}
                        >
                          FLiQ Topic
                        </div>
                        <div
                          className="subtitle-16-web"
                          style={{ marginTop: '0.8rem' }}
                        >
                          Set a Topic for your FliQ.
                        </div>
                      </div>
                      <div>
                        <div>
                          <img src={IcFliqType} alt="fliq-type" />
                        </div>
                        <div
                          className="subtitle-16-web"
                          style={{
                            color: themeColors[SELECTED_THEME].textPrimary,
                            fontWeight: 700,
                            marginTop: '1.2rem',
                          }}
                        >
                          FLiQ Type
                        </div>
                        <div
                          className="subtitle-16-web"
                          style={{ marginTop: '0.8rem' }}
                        >
                          Text, Audio, Image/Video, Polls{' '}
                        </div>
                      </div>
                      <div>
                        <div
                          className="subtitle-16-web"
                          style={{
                            color: themeColors[SELECTED_THEME].textPrimary,
                            fontWeight: 700,
                          }}
                        >
                          FLiQ Length{' '}
                        </div>
                        <div
                          className="subtitle-16-web"
                          style={{ marginTop: '0.8rem' }}
                        >
                          FliQ has a beginning & an end.
                          <br />
                          This makes the experience special and focussed.{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <div
          className="section-web banner-bg-artist-3 section-web-first"
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div className="w-858 child">
              <div
                className="section-content"
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span
                  className="brand-web-large"
                  style={{ textAlign: 'center' }}
                >
                  <span
                    style={{
                      color: themeColors[SELECTED_THEME].textPrimary,
                    }}
                  >
                    Types of
                  </span>{' '}
                  <br /> FliQs
                </span>
              </div>
            </div>
          </Fade>
        </div>
        <div
          className="section-web padding-top-72"
          style={{ backgroundColor: themeColors[SELECTED_THEME].primary }}
        >
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div
              className="child w-1032 gap-16"
              style={{ height: 'auto', position: 'relative' }}
            >
              <div className="w-508 right-content">
                <div className="collection">
                  {FLIQ_TYPE.map((feat, index) => (
                    <img
                      key={index}
                      className={
                        activeIndex2 === index
                          ? 'w-508 img-scroll trigger-img img-active'
                          : 'w-508 img-scroll trigger-img'
                      }
                      src={require(`assets/artist/${feat.image}`)}
                      alt={feat.image}
                    />
                  ))}
                </div>
              </div>
              <div
                className="w-508 left-content gap-72"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                {FLIQ_TYPE.map((feat, index) => (
                  <div
                    key={index}
                    className="w-508 h-100vh gap-16-mobile"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      key={index}
                      className="w-508 hide-in-desktop"
                      src={require(`assets/artist/${feat.image}`)}
                      alt={feat.image}
                    />
                    <div
                      ref={elRefs2[index]}
                      className={
                        activeIndex2 === index
                          ? 'trigger-text img-active'
                          : 'trigger-text'
                      }
                    >
                      <div
                        className="brand-tag ph-16"
                        style={{ width: 'fit-content' }}
                      >
                        <div>{feat.tag}</div>
                      </div>
                      <div className="title-web margin-top-24">
                        {feat.title}
                      </div>
                      <div className="subtitle-web margin-top-16">
                        {feat.subtitle}
                      </div>
                      <div className="margin-top-16 gap-16">
                        {feat.list ? (
                          feat.list.map(benefit => (
                            <div
                              style={{ display: 'flex' }}
                              className="gap-16px margin-top-16"
                            >
                              <img src={IcTickred} alt="unlock" />
                              <div className="subtitle-14-web">{benefit}</div>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Fade>
        </div>
        {/* <div
          className="section-web"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: themeColors[SELECTED_THEME].primary,
          }}
        >
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div className="w-1032 gap-16 child">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  className="brand-tag ph-16"
                  style={{ width: 'fit-content', alignSelf: 'center' }}
                >
                  <div>Testimonials</div>
                </div>
                <div className="text-align-center title-web margin-top-24">
                  Download the Artist App now!
                </div>
                <div
                  style={{ display: 'flex' }}
                  className="gap-16 margin-top-32 hover-link-ff"
                >
                  <a href="https://play.google.com/store/apps/details?id=app.fliqmedia.fanfliq">
                    <img
                      className="store-btn"
                      src={IcPlaystore}
                      alt="playstore"
                    />
                  </a>
                  <a href="https://apps.apple.com/in/app/fanfliq-for-artist/id6497226407">
                    <img
                      className="store-btn"
                      src={IcAppstore}
                      alt="appstore"
                    />
                  </a>
                </div>
                <div
                  className="margin-top-70 flex-responsive gap-16-big"
                  style={{ width: '100%' }}
                >
                  {TESTIMONIALS.map((test, index) => (
                    <div key={index} style={{ width: '100%' }}>
                      <div className="h-376" style={{ position: 'relative' }}>
                        <div
                          className="h-376"
                          style={{
                            backgroundColor: 'grey',
                            borderRadius: '1.2rem',
                          }}
                        />
                        <img
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            inset: 0,
                            margin: 'auto',
                          }}
                          src={IcPlay}
                          alt="play"
                        />
                      </div>
                      <div
                        style={{ marginTop: '1.6rem' }}
                        className="test-name"
                      >
                        {test.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Fade>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}
