import { Col, Modal, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { media } from 'utils/media';
import { SELECTED_THEME, themeColors } from 'utils/theme';

export const FanfliqModal = ({
  show,
  handleClose,
  cover,
  dialogClass = 'modal-dialog',
  title,
  leftContent,
  subtitle,
  info,
  primaryBtn,
  secondaryBtn,
  handleSecondaryBtn,
  loader,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className="modal-dialog-common"
      dialogClassName={dialogClass}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ padding: 0 }}>
        <VerticalDiv className="padding-32" style={{ alignItems: 'center'  }}>
          <HorizontalDivFlex>
            {leftContent}
            <VerticalDiv className="gap-16px">
              <HorizontalDiv>
                {cover ? <Image src={cover} /> : <></>}
                <div>{title}</div>
              </HorizontalDiv>

              {subtitle}
              {info}
            </VerticalDiv>
          </HorizontalDivFlex>
          <FlexDiv>
            {loader ? (
              <div className="secondary-btn">
                <Spinner />
              </div>
            ) : (
              primaryBtn
            )}
            {secondaryBtn ? (
              <Button
                onClick={handleSecondaryBtn}
                className="secondary-btn text-12-small hover-link-ff"
                style={{
                  width: '50%',
                  color: themeColors[SELECTED_THEME].primary,
                  fontWeight: 600,
                }}
              >
                {secondaryBtn}
              </Button>
            ) : (
              <></>
            )}
          </FlexDiv>
        </VerticalDiv>
      </Modal.Body>
    </Modal>
  );
};

const VerticalDiv = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const HorizontalDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

const HorizontalDivFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  ${media.mobile`flex-direction: column`};
  ${media.small`flex-direction: column`};
  ${media.medium`flex-direction: row`};
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  ${media.mobile`gap: 1.6rem`};
  ${media.small`gap: 1.6rem`};
  ${media.medium`gap: 1.6rem`};
  margin-top: 4rem;
`;

const Image = styled.img`
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 100%;
`;

const Button = styled.div``;
