import { ProfileDataComponent } from 'components/ProfileDataComponent';
import styled from 'styled-components';
import { media } from 'utils/media';

const BANNER_WEB =
  'https://fanfliq-public-asset.s3.ap-south-1.amazonaws.com/login_bg_desktop.webp';
const BANNER_MOBILE =
  'https://fanfliq-public-asset.s3.ap-south-1.amazonaws.com/login_bg_mobile.webp';

export function AccountSetup() {
  return (
    <Div>
      <DivInternal>
        <ProfileDataComponent />
      </DivInternal>
    </Div>
  );
}

const DivInternal = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.mobile`padding: 0 2.4rem`};
  ${media.small`padding: 0rem`};
  ${media.medium`padding: 0rem`};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) -31.09%, #000 72.31%);
`;

const Div = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.mobile`background-image: url(${BANNER_MOBILE}); background-size: cover`};
  ${media.small`background-image: url(${BANNER_MOBILE}); background-size: cover`};
  ${media.medium`background-image: url(${BANNER_WEB}); background-size: cover`};
`;
