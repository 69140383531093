import styled from 'styled-components';
import { media } from 'utils/media';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { Form, Spinner } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataContext } from 'utils/DataContext';
import 'react-phone-number-input/style.css';
import OTPInput, { ResendOTP } from 'otp-input-react';
import IcEdit from 'assets/ic_edit.svg';
import axios from 'axios';
import { setAuthTokens } from 'api/http-common';

export const EmailDataComponent = () => {
  const { notify } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [code, setCode] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const singupWithEmail = (code = '', email) => {
    axios({
      method: 'post',
      url: 'https://api.fanfliq.com/v1/auth/verify/otp/email',
      data: {
        code,
        email,
      },
    })
      .then(async function (response) {
        const headers = {
          Authorization: `Bearer ${response.data.tokens.access.token}`,
        };

        const playRes = await axios.get(
          'https://api.fanfliq.com/v1/users/player/token',
          {
            headers: headers,
          },
        );

        if (playRes.status === 200 && playRes.data) {
          localStorage.setItem('playToken', playRes.data);
        }

        setLoading('');
        setAuthTokens({
          accessToken: response.data.tokens.access.token,
          refreshToken: response.data.tokens.refresh.token,
        });

        if (response.data.user.name)
          localStorage.setItem('name', response.data.user.name);

        localStorage.setItem('id', response.data.user._id);

        notify('Successfully logged in!', 'success');

        const queryParams = new URLSearchParams(location.search);

        if (!response.data.user.gender) {
          if (queryParams.get('redirect'))
            navigate(
              `/setup?redirect=${queryParams.get('redirect')}${window.location.hash}`,
            );
          else navigate(`/setup`);
        } else if (queryParams.get('redirect'))
          window.location.href = `${queryParams.get('redirect')}${window.location.hash}`;
        else navigate('/home');
      })
      .catch(function (e) {
        console.log(e);
        //handle error
        setLoading('');
        notify('Error Logging In', 'error');
      });
  };

  const handleResend = () => {
    if (!checkEmail()) {
      notify('Enter a valid email address', 'error');
      return;
    }
    setLoading(true);
    axios({
      method: 'post',
      url: 'https://api.fanfliq.com/v1/auth/send/otp/email',
      data: {
        email: value,
      },
    })
      .then(async function (_response) {
        setLoading(false);
        setShowOtp(true);
      })
      .catch(e => {
        notify(e, 'error');
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    if (!checkEmail()) {
      notify('Enter a valid email address', 'error');
      return;
    }
    if (!showOtp) {
      setLoading(true);
      axios({
        method: 'post',
        url: 'https://api.fanfliq.com/v1/auth/send/otp/email',
        data: {
          email: value,
        },
      })
        .then(async function (_response) {
          setLoading(false);
          setShowOtp(true);
        })
        .catch(e => {
          notify(e, 'error');
          setLoading(false);
        });
    } else {
      if (code) {
        setLoading(true);
        singupWithEmail(code, value);
      }
    }
  };

  const checkEmail = () => {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  const [value, setValue] = useState();
  const renderButton = buttonProps => {
    return (
      <ResendBtn className="text-12" {...buttonProps}>
        Resend
      </ResendBtn>
    );
  };
  const renderTime = remainingTime => {
    return (
      <span
        className="text-12"
        style={{
          color: themeColors[SELECTED_THEME].black60,
          textAlign: 'center',
        }}
      >
        {remainingTime} seconds remaining
      </span>
    );
  };

  return (
    <Parent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <div className="text-38">
          {!showOtp ? (
            <div>
              Enter your <br /> email address
            </div>
          ) : (
            <div>
              Otp sent to <br />
              {value}{' '}
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setShowOtp(false)}
                src={IcEdit}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
      <Vertical>
        <div>
          <Form.Label
            className="text-12"
            style={{ color: themeColors[SELECTED_THEME].black60 }}
          >
            {showOtp ? 'Enter OTP' : 'My Email id is'}
          </Form.Label>
          {!showOtp ? (
            <Input
              placeholder="Type here"
              value={value}
              style={{ marginTop: '0.8rem' }}
              onChange={e => setValue(e.target.value)}
            />
          ) : (
            <>
              <OTPInput
                value={code}
                onChange={setCode}
                autoFocus
                inputClassName="otp"
                OTPLength={6}
                otpType="number"
                disabled={false}
              />
            </>
          )}
          <BtnDiv>
            {!showOtp ? (
              <div
                className="text-12"
                style={{
                  color: themeColors[SELECTED_THEME].black60,
                  textAlign: 'center',
                }}
              >
                When you tap “Send OTP”, FanFliQ will send you an email with
                verification code.
              </div>
            ) : (
              <ResendOTP
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={handleResend}
              />
            )}
            <BtnFlex>
              {!loading ? (
                <button
                  className="btn-brand"
                  disabled={!code && showOtp}
                  id="oto-login-button"
                  style={{ width: '100%' }}
                  onClick={handleSubmit}
                >
                  <div
                    className="text-14"
                    style={{
                      fontWeight: 600,
                      color: themeColors[SELECTED_THEME].white,
                    }}
                  >
                    {showOtp ? 'Verify' : 'Send OTP'}
                  </div>
                </button>
              ) : (
                <div
                  className="btn-brand"
                  disabled={!value}
                  style={{ width: '100%' }}
                  type="submit"
                >
                  <Spinner />
                </div>
              )}
            </BtnFlex>
          </BtnDiv>
        </div>
      </Vertical>
    </Parent>
  );
};

const ResendBtn = styled.button`
  background: transparent;
  border: 0;
  color: ${themeColors[SELECTED_THEME].brand} !important;

  :disabled {
    color: ${themeColors[SELECTED_THEME].black60} !important;
  }
`;

const Parent = styled.div`
  border-radius: 2.4rem;
  background: white;
  display: flex;
  flex-direction: column;
  ${media.mobile`padding: 2.4rem; gap: 1.6rem; width: 100%`};
  ${media.small`padding: 2.4rem; gap: 1.6rem; width: 100%`};
  ${media.medium`padding: 4rem 3.2rem; gap: 2.4rem;  width: 50rem`};
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  border-radius: 8px;
  border: 0px;
  background: var(--Greay-Shades-97, #f7f7f8);
  color: #0f0f0f !importnant;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  ${media.mobile`font-size: 1.6rem; line-height: 2.4rem; padding: 1.2rem 1.6rem; height: 4.8rem; width: 100%`};
  ${media.small`font-size: 1.6rem; line-height: 2.4rem; padding: 1.2rem 1.6rem; height: 4.8rem; width: 100%`};
  ${media.medium`font-size: 1.4rem; line-height: 2.4rem; padding: 2rem 1.6rem; height: 6.4rem; width: 100%`};
  > input {
    background: transparent;
    border: 0;
    :focus-visible {
      outline: 0;
    }
  }
`;

const BtnFlex = styled.div`
  display: flex;
  width: 100%;
  ${media.mobile`margin-top: 0.8rem; gap: 0.8rem`};
  ${media.small`margin-top: 1.6rem; gap: 0.8rem`};
  ${media.medium`margin-top: 1.6rem; gap: 1.6rem`};
`;

const BtnDiv = styled.div`
  ${media.mobile`margin-top: 4rem; gap: 0.8rem`};
  ${media.small`margin-top: 7.2rem; gap: 0.8rem`};
  ${media.medium`margin-top: 7.2rem; gap: 1.6rem`};
`;
