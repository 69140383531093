import styled from 'styled-components';
import IcPlay from 'assets/ic_play_show.svg';
import IcPause from 'assets/ic_pause.svg';

import { SELECTED_THEME, themeColors } from 'utils/theme';
import { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { media } from 'utils/media';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DataContext } from 'utils/DataContext';
import { useNavigate } from 'react-router-dom';

export const ContentCard = ({ content }) => {
  const [showPlay, setShowPlay] = useState(false);
  const {
    isPlaying,
    curAlbumId,
    setIsPlaying,
    setPlaylist,
    setCurAlbumId,
    setPlayingId,
  } = useContext(DataContext);
  const navigate = useNavigate();

  const openAlbum = () => {
    navigate(`/${content.artistUsername}/album/${content.id}`);
  };

  const handlePlayPause = id => {
    if (curAlbumId === id) setIsPlaying(!isPlaying);
    else {
      setCurAlbumId(id);
      setPlayingId(content.contentIds[0]);
      setIsPlaying(true);
      setTimeout(() => {
        setPlaylist(content.contentIds);
      }, 100);
    }
  };

  return (
    <Vertical
      onMouseEnter={() => setShowPlay(true)}
      onMouseLeave={() => setShowPlay(false)}
    >
      <div style={{ position: 'relative' }}>
        {content.cover ? (
          <Image
            onClick={() => {
              openAlbum(content.id);
            }}
            placeholderSrc={content?.thumbnail}
            src={content?.cover}
          />
        ) : (
          <ImageSkeleton />
        )}
        {showPlay ? (
          <PlayBg
            onClick={() => {
              handlePlayPause(content.id);
            }}
          >
            <img
              src={curAlbumId === content.id && isPlaying ? IcPause : IcPlay}
              alt="play"
            />
          </PlayBg>
        ) : (
          <></>
        )}
      </div>
      <div
        onClick={() => {
          openAlbum(content.id);
        }}
        style={{ width: '100%' }}
      >
        {content.title ? (
          <div
            className="text-14"
            style={{
              textTransform: 'none',
              lineHeight: '3.2rem',
              textOverflow: 'ellipsis',
              width: '80%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {content.title}
          </div>
        ) : (
          <Skeleton style={{ flex: 1, width: '10rem' }} />
        )}
        {content.createdAt ? (
          <div className="text-12" style={{ lineHeight: '2.4rem' }}>
            {new Date(content.createdAt).getFullYear()}
          </div>
        ) : (
          <Skeleton style={{ flex: 1, width: '6rem' }} />
        )}
      </div>
    </Vertical>
  );
};

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  cursor: pointer;
  align-items: flex-start;
  padding: 0.8rem;
  cursor: pointer;
  :hover {
    background: black;
    border-radius: 0.8rem;
  }

  ${media.mobile`background: black;border-radius: 0.8rem;flex-basis: 48.5%`};
  ${media.small`background: black;border-radius: 0.8rem;flex-basis: 48.5%`};
  ${media.medium`flex: 0;background: transparent; border-radius: 0.8rem;`};
`;

const Image = styled(LazyLoadImage)`
  ${media.mobile`width: 100%; height: auto`};
  ${media.small`width: 100%; height: auto`};
  ${media.medium`width: 17.6rem; height: 17.6rem`};
  object-fit: cover;
`;

const ImageSkeleton = styled(Skeleton)`
  ${media.mobile`width: 100%; height: auto`};
  ${media.small`width: 100%; height: auto`};
  ${media.medium`width: 17.6rem; height: 17.6rem`};
`;

const PlayBg = styled.div`
  @keyframes smooth-appear {
    to {
      bottom: 0.8rem;
      opacity: 1;
    }
  }

  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -5%;
  opacity: 0;

  left: 0.8rem;
  background: ${themeColors[SELECTED_THEME].brand};
  animation: smooth-appear 0.3s ease forwards;
  :hover {
    transform: scale(1.06);
  }
`;
