import styled from 'styled-components';

export const ScrollIndicator = ({
  sectionIndex = 0,
  onClicked = () => {},
  sections = ['Home', 'Content', 'About Us'],
}) => {
  return (
    <Parent>
      {sections.map((section, index) => (
        <Div>
          {sectionIndex === index ? (
            <div className="red-bar"></div>
          ) : (
            <div
              className="circle-white"
              onClick={() => onClicked(index)}
            ></div>
          )}
          {sectionIndex === index ? (
            <div className="text-14">{section}</div>
          ) : (
            <></>
          )}
        </Div>
      ))}
    </Parent>
  );
};

const Div = styled.div`
  display: flex;
  gap: 0.8rem;
`;
const Parent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
`;
