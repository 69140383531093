import { useState } from 'react';
import { MusicListItem } from './MusicListItem';

export const MusicList = ({ list = [] }) => {
  const [hoverId, setHoverId] = useState();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.6rem',
        width: '100%',
      }}
    >
      <div className="text-16-all">SONGS.</div>
      {list.map((item, index) => (
        <MusicListItem
          item={item}
          index={index}
          setHoverId={setHoverId}
          hoverId={hoverId}
        />
      ))}
    </div>
  );
};
