import { HeaderWebsite } from 'components/HeaderWebsite';
import IcAppstore from 'assets/ic_appstore.svg';
import IcPlaystore from 'assets/ic_playstore.svg';
import IcLock from 'assets/ic_unlock.svg';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { Footer } from 'components/Footer';
import { Fade } from 'react-awesome-reveal';
import Accordion from 'react-bootstrap/Accordion';
import { FanfliqModalCommon } from 'components/modal-commmon';
import { useState } from 'react';

const BENEFITS = {
  FANS: ['Pre-release music.', 'BTS Footages.', 'Live QnA.', 'Meet n Greet'],
  ARTIST: [
    'Sell Pre-release Music.',
    'Build Stronger Community.',
    'Get Deeper Data, Not just the Demographics.',
    'Connect with fans, and grow faster.',
  ],
  ARTIST_2: [
    'Pre-release music.',
    'BTS Footages.',
    'Live QnA.',
    'Meet n Greet',
  ],
};

const FAQS = [
  {
    title: 'What is FanFliQ',
    content: (
      <>
        FanFliQ is an innovative platform designed to enhance engagement between
        artists and their fans. It allows fans to be a part of new projects,
        songs, EPs, behind-the-scenes (BTS) content, and much more from their
        favorite artists. By joining the FanFliQ community, fans gain exclusive
        backstage access and enjoy numerous perks. <br />
        <br />
        For artists, FanFliQ offers unique monetization opportunities, enabling
        them to generate up to 1000 times more revenue compared to traditional
        streaming platforms.
        <br />
        <br />
        When you acquire a release on FanFliQ, you're not simply buying music –
        you're getting a sneak peek into an artist's creative world. This can
        encompass their newest tracks, exclusive content, priority access to
        concert tickets, merchandise, and a host of other perks. It's a premium
        experience for fans, providing much more than the usual streaming or
        purchasing options available on other platforms.
      </>
    ),
  },
  {
    title: 'How does FanFliQ work?',
    content: (
      <>
        FanFliQ offers a revolutionary experience for both fans and artists. By
        simply signing up on FanFliQ, artists can invite their fans to enjoy
        exclusive content, music, and more. <br />
        Artists can engage their audience through FliQs, a unique form of
        time-based interaction, unlike traditional social media platforms. There
        are several types of FliQs: <br />
        <br />
        <div>
          <span>
            <span style={{ fontWeight: 700 }}>1. Time-based Polls: </span> These
            polls provide insights from fans, help with naming your next
            release, and serve many other purposes.
          </span>
          <br />
          <span>
            <span style={{ fontWeight: 700 }}>2. Time-based Text FliQ: </span>{' '}
            nitiate conversations with your most dedicated fans using this
            feature.
          </span>
          <br />
          <span>
            <span style={{ fontWeight: 700 }}>3. Image or Video FliQ: </span>{' '}
            Share fleeting moments with your fans, setting an expiration time
            for the content.
          </span>
          <br />
          <span>
            <span style={{ fontWeight: 700 }}>4. Audio FliQ: </span> Share
            secrets or important updates in an engaging audio format.
          </span>
          <br />
          <span>
            <span style={{ fontWeight: 700 }}>
              5. Exclusive Music Releases:{' '}
            </span>{' '}
            Release your music on FanFliQ before it hits streaming platforms,
            allowing fans to purchase and listen to it ahead of everyone else.
          </span>
        </div>
      </>
    ),
  },
  {
    title: 'How do artists benefit from releasing on FanFliQ?',
    content: (
      <>
        FanFliQ equips artists with the tools and resources needed to share
        their music with fans while maintaining control over their fan
        relationships.
        <br />
        With FanFliQ, artists can: <br />
        <br />
        1. Set the price for their work.
        <br />
        2. Decide how to reward their fans. <br />
        3. Receive daily payments. <br />
        4. Own and export their data with a single click.
      </>
    ),
  },
  {
    title: 'How is it different from social media or streaming platforms?',
    content: (
      <>
        FanFliQ stands apart from social media and streaming platforms because:{' '}
        <br />
        <br />
        1. Fans engage directly with your content in a focused environment, free
        from the noise of social media.
        <br />
        2. The relationship between fans and their favorite artists is direct
        and concentrated.
        <br />
        3. It serves as a community-based engagement tool for artists, not just
        another social media platform.
        <br />
        4. Artists can earn significantly more revenue—up to 1000 times
        more—through their music releases compared to streaming platforms.
        <br />
        5. FanFliQ provides in-depth data on fan listening habits and
        engagement, surpassing the basic demographic data offered by streaming
        platforms.
      </>
    ),
  },
  {
    title: 'Is FanFliQ only for independent artists?',
    content: (
      <>
        FanFliQ isn't just for independent artists.
        <br />
        <br />
        It's a comprehensive tool for managing artist communities and gaining
        valuable insights.
        <br />
        <br />
        We offer custom dashboards for artist management companies and labels,
        enabling them to manage revenue and other critical data for their
        artists.
      </>
    ),
  },
];

const DIFFERENCES = [
  {
    title: 'REVENUE',
    other: 'Streams & brand deals.',
    fanfliq: 'Based on number of fans',
  },
  {
    title: 'Connection',
    other: 'One-Directional.',
    fanfliq: 'Bi-Directional',
  },
  {
    title: 'ENGAGEMENT',
    other: 'Distracted',
    fanfliq: 'Focussed',
  },
  {
    title: 'RELATIONSHIP QUALITY',
    other: 'Casual',
    fanfliq: 'Intense',
  },
  {
    title: 'PARTICIPATION',
    other: 'Passive',
    fanfliq: 'Active',
  },
  {
    title: 'EXCLUSIVITY',
    other: 'No Exclusivity',
    fanfliq: 'Super Exclusive',
  },
];

export function HomePageWebsite() {
  const getSocialIcon = (type = '') => {
    return require(`assets/${type}.svg`);
  };

  const [showComingSoon, swetShowComingSoon] = useState(false);

  return (
    <div>
      <div className="banner-bg" />
      <HeaderWebsite />
      <div className="padding-top-8">
        <div className="section-web section-web-first">
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div className="w-1032 gap-16 child">
              <div className="section-content w-595" style={{ zIndex: 2 }}>
                <div className="brand-web">MUSIC. FANS. ARTIST. UNITED. 🤝</div>
                <div className="text-align-center title-web-large margin-top-16">
                  Where fans, artists meet and engage directly.
                </div>
                <div className="text-align-center subtitle-web margin-top-16">
                  FanFliQ is a dedicated platform for the artists and fans for
                  better engagement with music and BTS.
                </div>
                <div
                  className="subtitle-16-web margin-top-32"
                  style={{
                    color: themeColors[SELECTED_THEME].textPrimary,
                    fontWeight: 700,
                  }}
                >
                  Get FanFliQ for Fans Now!
                </div>
                <div
                  style={{ display: 'flex' }}
                  className="gap-16 margin-top-16 hover-link-ff"
                >
                  <a href="https://play.google.com/store/apps/details?id=apps.fliqmedia.fanfliq">
                    <img
                      className="store-btn"
                      src={IcPlaystore}
                      alt="playstore"
                    />
                  </a>
                  <a href="https://apps.apple.com/us/app/fanfliq-for-fans/id6497407785">
                    <img
                      className="store-btn"
                      src={IcAppstore}
                      alt="appstore"
                    />
                  </a>
                </div>
              </div>
              <div className="w-508" />
            </div>
          </Fade>
        </div>
        <div
          className="section-web"
          style={{ backgroundColor: themeColors[SELECTED_THEME].primary }}
        >
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div className="w-1032 gap-16 child reverse-column">
              <div className="w-595" style={{ zIndex: 2 }}>
                <div
                  className="brand-tag ph-16"
                  style={{ width: 'fit-content' }}
                >
                  <div>FOR FANS</div>
                </div>
                <div className="title-web margin-top-24">
                  Be a part and get exclusive access.
                </div>
                <div className="subtitle-web margin-top-16">
                  FanFliQ is the perfect platform for fans seeking an exclusive
                  pass to their favorite artist's backstage. Every fan is
                  treated like a VIP and receives exclusive access to music,
                  involvement in the creation process, behind-the-scenes
                  content, and more before anyone else.
                </div>
                <div className="gap-16">
                  {BENEFITS.FANS.map(benefit => (
                    <div
                      style={{ display: 'flex' }}
                      className="gap-16 margin-top-16"
                    >
                      <img src={IcLock} alt="unlock" />
                      <div className="subtitle-14-web">{benefit}</div>
                    </div>
                  ))}
                </div>
                {/* <div className="web-btn margin-top-40 hover-link-ff">
                  <div>GET FAN APP</div>
                </div> */}
              </div>
              <div className="w-508">
                <img
                  className="w-508"
                  src="https://fanfliq-assets.s3.ap-south-1.amazonaws.com/Frame-1321314589banner_2-_1_.webp"
                  alt="banner_2"
                />
              </div>
            </div>
          </Fade>
        </div>
        <div
          className="section-web"
          style={{ backgroundColor: themeColors[SELECTED_THEME].primary }}
        >
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div className="w-1032 gap-16 child">
              <div className="w-508">
                <img
                  className="w-508"
                  src="https://fanfliq-assets.s3.ap-south-1.amazonaws.com/Frame-1321314589banner_3.webp"
                  alt="banner_3"
                />
              </div>
              <div className="w-595" style={{ zIndex: 2 }}>
                <div
                  className="brand-tag ph-16"
                  style={{ width: 'fit-content' }}
                >
                  <div>FOR ARTIST</div>
                </div>
                <div className="title-web margin-top-24">
                  All in 1 tech solution for your community.
                </div>
                <div className="subtitle-web margin-top-16">
                  FanFliQ provides an array of powerful tools designed for
                  artists who aim to strengthen connections with their fans,
                  broaden their audience, and creatively monetize their talents.
                  With FanFliQ, you hold the reins to your art, your fanbase,
                  and your future.
                </div>
                <div className="gap-16">
                  {BENEFITS.ARTIST.map(benefit => (
                    <div
                      style={{ display: 'flex' }}
                      className="gap-16px margin-top-16"
                    >
                      <img src={IcLock} alt="unlock" />
                      <div className="subtitle-14-web">{benefit}</div>
                    </div>
                  ))}
                </div>
                <div
                  className="subtitle-16-web margin-top-40"
                  style={{
                    color: themeColors[SELECTED_THEME].textPrimary,
                    fontWeight: 700,
                  }}
                >
                  Know more and Get Artist App!
                </div>
                <a
                  href="/for-artist"
                  style={{ textDecoration: 'none' }}
                  className="hover-link-ff"
                >
                  <div className="web-btn margin-top-16">
                    <div>LEARN MORE</div>
                  </div>
                </a>
              </div>
            </div>
          </Fade>
        </div>
        <div className="div-strip">
          <div>
            <div>MUSIC. FANS. ARTIST. UNITED. MUSIC. FANS. ARTIST. UNITED.</div>
          </div>
        </div>
        <div
          className="section-web"
          style={{ backgroundColor: themeColors[SELECTED_THEME].primary }}
        >
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div className="w-1032 gap-16 child">
              <div
                style={{
                  zIndex: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  className="brand-tag ph-16"
                  style={{ width: 'fit-content', alignSelf: 'center' }}
                >
                  <div>FOR ARTIST</div>
                </div>
                <div className="text-align-center title-web margin-top-24">
                  A tool designed to connect better with your fans.
                </div>
                <div className="margin-top-144 w-857">
                  <div style={{ display: 'flex' }} className="gap-16-small">
                    <div
                      className="subtitle-14-web p-16"
                      style={{
                        width: '33.3%',
                        flexGrow: 1,
                        backgroundColor: 'transparent',
                      }}
                    />
                    <div
                      className="subtitle-14-web p-16"
                      style={{
                        flexGrow: 1,
                        width: '33.3%',
                        backgroundColor: '#000000',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                        }}
                        className="gap-8rem"
                      >
                        {['spotify', 'apple', 'instagram', 'tiktok', 'x'].map(
                          (icon, index) => (
                            <img
                              key={index}
                              src={getSocialIcon(icon)}
                              alt={icon}
                              className="w-24"
                            />
                          ),
                        )}
                      </div>
                    </div>
                    <div
                      className="subtitle-14-web p-16"
                      style={{
                        flexGrow: 1,
                        width: '33.3%',
                        backgroundColor: '#000000',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={require('assets/ic_full_logo.svg').default}
                        alt={'logo'}
                        className="w-108"
                      />
                    </div>
                  </div>
                  {DIFFERENCES.map((diff, index) => (
                    <div
                      key={index}
                      style={{ display: 'flex' }}
                      className="gap-16-small"
                    >
                      <div
                        className="p-16"
                        style={{
                          width: '33.3%',
                          display: 'flex',
                          alignItems: 'center',
                          flexGrow: 1,
                          backgroundColor:
                            index % 2 === 0
                              ? themeColors[SELECTED_THEME].black8
                              : '#000000',
                        }}
                      >
                        <div
                          className="subtitle-14-web-small"
                          style={{ fontWeight: 600 }}
                        >
                          {diff.title}
                        </div>
                      </div>
                      <div
                        className="subtitle-14-web-small p-16"
                        style={{
                          flexGrow: 1,
                          display: 'flex',
                          alignItems: 'center',
                          width: '33.3%',
                          backgroundColor:
                            index % 2 === 0
                              ? themeColors[SELECTED_THEME].black8
                              : '#000000',
                        }}
                      >
                        <div className="subtitle-14-web-small">
                          {diff.other}
                        </div>
                      </div>
                      <div
                        className="subtitle-14-web-small p-16"
                        style={{
                          flexGrow: 1,
                          display: 'flex',
                          alignItems: 'center',
                          width: '33.3%',
                          backgroundColor:
                            index % 2 === 0
                              ? themeColors[SELECTED_THEME].black8
                              : '#000000',
                        }}
                      >
                        <div className="subtitle-14-web-small">
                          {diff.fanfliq}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <div
          className="section-web p-88"
          style={{ backgroundColor: themeColors[SELECTED_THEME].primary }}
        >
          <Fade big style={{ zIndex: 2 }} direction="bottom">
            <div className="w-1032 gap-16 child section-web-left">
              <div
                style={{
                  zIndex: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <div
                  className="brand-tag ph-16"
                  style={{ width: 'fit-content' }}
                >
                  <div>FAQ</div>
                </div>
                <div className="title-web margin-top-24">
                  Frequently asked questions.
                </div>
                <div className="subtitle-web margin-top-16">
                  Your Questions, Answered: Common Inquiries and Solutions
                </div>
                <div className="gap-16 margin-top-48-small">
                  <Accordion className="w-1032" defaultActiveKey="0">
                    {FAQS.map((faq, index) => (
                      <Accordion.Item className="w-1032" eventKey={`${index}`}>
                        <Accordion.Header>{faq.title}</Accordion.Header>
                        <Accordion.Body>{faq.content}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <Footer />
      <FanfliqModalCommon
        title={
          <div className="text-20-black">
            The iOS version for fans is coming soon!! Stay tuned!
          </div>
        }
        show={showComingSoon}
        handleClose={() => swetShowComingSoon(false)}
      />
    </div>
  );
}
