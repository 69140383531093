import styled from 'styled-components';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import IcFullLogo from 'assets/ic_full_logo.svg';
import { media } from 'utils/media';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import IcMenu from 'assets/ic_menu.svg';
import IcClose from 'assets/ic_close.svg';
import { Button, Dropdown, Modal, Spinner } from 'react-bootstrap';
import { auth } from 'utils/firebase';
import { getAccessToken } from 'api/http-common';
import { API_CALLS, callAPI } from 'api';
import { DataContext } from 'utils/DataContext';

export const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [loader, setLoader] = useState(false);
  const { myProfile } = useContext(DataContext);

  const handleLogout = async () => {
    setLoader(true);
    auth.signOut();
    await callAPI(API_CALLS.LOGOUT);
    setLoader(false);
    localStorage.clear();
    window.location.reload();
  };

  const renderMenuMobile = () => {
    return (
      <Modal
        className="modal-dialog-menu"
        style={{ margin: 0, width: '100%' }}
        fullscreen
        show={showMenu}
        onHide={() => setShowMenu(false)}
      >
        <Modal.Body
          style={{ background: 'white', margin: 0, padding: '1.6rem' }}
        >
          {getAccessToken() ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div className="menu-title">Profile</div>
                <img
                  src={IcClose}
                  alt="logo"
                  onClick={() => setShowMenu(false)}
                />
              </div>
              <img
                alt="profile"
                src={myProfile?.avatar}
                style={{
                  borderRadius: '100%',
                  height: '8rem',
                  width: '8rem',
                  marginTop: '3.2rem',
                }}
              />
              <div
                className="text-14"
                style={{
                  marginTop: '0.8rem',
                  fontSize: '1.4rem',
                  color: themeColors[SELECTED_THEME].primary,
                }}
              >
                {myProfile?.name}
              </div>
              {!loader ? (
                <Button
                  onClick={handleLogout}
                  className="primary-btn text-14"
                  style={{ width: '40%', marginTop: '3.2rem' }}
                >
                  Logout
                </Button>
              ) : (
                <div
                  className="primary-btn"
                  style={{ width: '40%', marginTop: '3.2rem' }}
                >
                  <Spinner />
                </div>
              )}
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  onClick={() =>
                    navigate(`/login?redirect=${window.location.href}`)
                  }
                  className="primary-btn text-14"
                  style={{ width: '40%' }}
                >
                  LOGIN
                </Button>
                <img
                  src={IcClose}
                  alt="logo"
                  onClick={() => setShowMenu(false)}
                />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  const navigate = useNavigate();

  return (
    <Parent>
      <FlexDiv>
        <a href="/">
          <img src={IcFullLogo} alt="logo" className="logo" />
        </a>{' '}
        {/* <div className="text-14 txt-btn">MY ACCESS</div> */}
        {/* <div className="text-14 txt-btn">ARTISTS</div>
        <div className="text-14 txt-btn">RELEASES</div> */}
      </FlexDiv>
      {getAccessToken() ? (
        <Dropdown placement="bottom-start" style={{ zIndex: 2 }}>
          <Dropdown.Toggle as="div" id="dropdown-custom-components">
            <Image alt="profile-pic" src={myProfile?.avatar} />
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              borderRadius: '8px',
              padding: '1rem',
              background: 'white',
              maxWidth: '40rem',
            }}
          >
            <div>
              <div
                className="text-14"
                style={{
                  marginTop: '0.8rem',
                  fontSize: '1.4rem',
                  color: themeColors[SELECTED_THEME].primary,
                }}
              >
                {myProfile?.name}
              </div>
              {!loader ? (
                <Button
                  onClick={handleLogout}
                  className="primary-btn text-14"
                  style={{ marginTop: '3.2rem', width: '20rem' }}
                >
                  Logout
                </Button>
              ) : (
                <div
                  className="primary-btn"
                  style={{ marginTop: '3.2rem', width: '20rem' }}
                >
                  <Spinner variant="light" />
                </div>
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div
          className="text-14 txt-btn hide-in-mobile"
          onClick={() => navigate(`/login?redirect=${window.location.href}`)}
        >
          LOGIN
        </div>
      )}
      <Menu src={IcMenu} alt="menu" onClick={() => setShowMenu(true)} />
      {renderMenuMobile()}
    </Parent>
  );
};

const Parent = styled.div`
  width: 100%;
  display: flex;
  gap: 3.2rem;
  background-color: #000;
  justify-content: space-between;
  ${media.mobile`padding: 0 2.4rem; height: 6.4rem`};
  ${media.small`padding: 0 2.4rem; height: 6.4rem`};
  ${media.medium`padding: 0 7.2rem; height: 7.2rem`};
  align-items: center;
  position: fixed;
  z-index: 10;
  bottom: 0;
`;

const Image = styled.img`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 6.4rem;
  cursor: pointer;
  ${media.mobile`display: none`};
  ${media.small`display: none`};
  ${media.medium`display: flex`};
`;

const Menu = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 6.4rem;
  ${media.mobile`display: flex`};
  ${media.small`display: flex`};
  ${media.medium`display: none`};
`;

const FlexDiv = styled.div`
  gap: 3.2rem;
  display: flex;
  align-items: center;
`;
