/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from 'utils/DataContext';
import styled from 'styled-components';
import { media } from 'utils/media';
import { ArtistDetailsCard } from 'components/ArtistDetailsCard';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { MusicList } from 'components/MusicList';
import Skeleton from 'react-loading-skeleton';
import { ArtistDetailsCardPhone } from 'components/ArtistDetailsCardPhone';
import { BtsVideoList } from 'components/BtsVideoList';
import { AboutUsCard } from 'components/AboutUsCard';
import { ShareLinks } from 'components/ShareLinks';
import { Fade } from 'react-awesome-reveal';
import { ScrollIndicator } from 'components/ScrollIndicator';
import Circle from 'components/fab';
import { DiscographySection } from 'components/DiscographySection';
import { getAccessToken } from 'api/http-common';
import { TopBackground } from './TopBackground';

export function Portfolio({
  price,
  subsData,
  handleCancel,
  openBuyFliq,
  artist,
}) {
  const { notify, setPlaylist, curAlbumId } = useContext(DataContext);

  const [content, setContent] = useState([{}, {}, {}, {}, {}]);

  const fetchContentList = async userId => {
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };
    try {
      const response = await axios.get(
        `https://api.fanfliq.com/v1/content?userId=${userId}&visibility=Exclusive&contentType=Music&sortBy=createdAt:desc&limit=5`,
        {
          headers: headers,
        },
      );
      setContent(response.data.results);
    } catch (err) {
      notify('Error in loading data', 'error');
    }
  };

  useEffect(() => {
    if (artist && artist.id) {
      fetchContentList(artist.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artist]);

  useEffect(() => {
    if (curAlbumId) setPlaylist(content.map(con => con.id));
  }, [curAlbumId]);

  const handleScroll = () => {
    if (document.getElementById('about').getBoundingClientRect().top <= 100)
      setCurSection(2);
    else if (
      document.getElementById('content').getBoundingClientRect().top <= 100
    )
      setCurSection(1);
    else setCurSection(0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [curSection, setCurSection] = useState(0);

  const onScrollClicked = index => {
    var myElement;
    switch (index) {
      case 0:
        myElement = document.getElementById('home');
        myElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 1:
        myElement = document.getElementById('content');
        myElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 2:
        myElement = document.getElementById('about');
        myElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div
        className="hide-in-mobile"
        style={{
          alignItems: 'center',
          position: 'fixed',
          left: '2.4rem',
          top: 0,
          bottom: 0,
          margin: 'auto',
          zIndex: 9,
        }}
      >
        <ScrollIndicator
          onClicked={onScrollClicked}
          sectionIndex={curSection}
        />
      </div>
      <Circle />
      <div className="hide-in-desktop">
        {artist?.avatar ? (
          <Cover
            alt={'profile'}
            wrapperClassName="full-width"
            placeholderSrc={artist?.thumbnail}
            src={artist?.avatar}
          />
        ) : (
          <CoverLoader />
        )}
      </div>
      <div className="parent-div">
        <div
          className="hide-in-mobile"
          style={{
            width: '100%',
            height: '100vh',
          }}
        >
          <TopBackground
            id="home"
            className="hide-in-mobile"
            layerHeight="100vh"
            styleProp={{
              position: 'absolute',
              height: '100vh',
              width: '100%',
              top: 0,
              left: 0,
              right: 0,
            }}
            content={{ ...artist, cover: artist?.avatar }}
            gradient="linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), transparent 50% / cover no-repeat"
          />
          <div
            style={{
              width: '100%',
              height: '100vh',
              position: 'absolute',
              top: 0,
            }}
            className="hide-in-mobile"
          >
            <ArtistDetailsCard
              profile={artist}
              price={price}
              subsData={subsData}
              handleCancel={handleCancel}
              openBuyFliq={openBuyFliq}
            />
          </div>
        </div>

        <SectionDiv>
          <Fade direction="bottom">
            <div className="hide-in-desktop">
              <ArtistDetailsCardPhone
                profile={artist}
                price={price}
                subsData={subsData}
                handleCancel={handleCancel}
                openBuyFliq={openBuyFliq}
              />
            </div>
          </Fade>
          <Fade direction="bottom">
            <div id="content">
              <div className="text-section-title">Exclusive Music.</div>
              <MusicList list={content} />
            </div>
          </Fade>
          <Fade direction="bottom">
            <div id="content">
              <div className="text-section-title">Discography.</div>
              <DiscographySection userId={artist.id} />
            </div>
          </Fade>
          <Fade direction="bottom">
            <div>
              <div className="text-section-title">BTS.</div>
              <div className="text-section-subtitle">LATEST</div>
              <BtsVideoList list={[]} />
            </div>
          </Fade>
          <Fade direction="bottom">
            <div id="about">
              <div className="text-section-title">About.</div>
              <div className="text-section-subtitle">{`Posted by ${artist.name}`}</div>
              <AboutUsCard profile={artist} />
            </div>
          </Fade>

          <Fade>
            <div>
              <div className="text-section-title">Social Links.</div>
              <ShareLinks socials={artist.socials} />
            </div>
          </Fade>
        </SectionDiv>
      </div>
    </div>
  );
}

const Cover = styled(LazyLoadImage)`
  ${media.mobile`width: 100%; height: auto`};
  ${media.small`width: 100%; height: auto`};
  ${media.medium`width: 100%; height: auto`};
  aspect-ratio: 16/9;
  object-fit: cover;
`;

const CoverLoader = styled(Skeleton)`
  ${media.mobile`width: 100%; height: auto`};
  ${media.small`width: 100%; height: auto`};
  ${media.medium`width: 100%; height: auto`};
`;

const SectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  background: ${themeColors[SELECTED_THEME].primary};
  ${media.mobile`gap: 4.8rem; margin: 0; padding: 1.6rem 1.6rem 11.2rem 1.6rem`};
  ${media.small` gap: 4.8rem; margin: 0; padding: 1.6rem 1.6rem 11.2rem 1.6rem`};
  ${media.medium`gap: 6.4rem; padding: 0 0 16rem 16rem;`};
`;
