import styled from 'styled-components';
import { media } from 'utils/media';
import icMusic from 'assets/ic_music.svg';
import icCamera from 'assets/ic_camera.svg';
import icPlay from 'assets/ic_play_circle.svg';
import icMerch from 'assets/ic_gift.svg';
import icTicket from 'assets/ic_ticket.svg';
import icHeart from 'assets/ic_heart.svg';
import IcVerified from 'assets/ic_verified.svg';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SELECTED_THEME, themeColors } from 'utils/theme';

export const ArtistDetailsCardPhone = ({
  profile,
  price,
  subsData,
  handleCancel,
  openBuyFliq,
}) => {
  const OFFERINGS = [
    {
      title: 'Pre-releases',
      icon: icMusic,
    },
    {
      title: 'BTS Footages',
      icon: icPlay,
    },
    {
      title: 'Video QnA Sessions',
      icon: icCamera,
    },
    {
      title: 'Merch',
      icon: icMerch,
    },
    {
      title: 'Tickets',
      icon: icTicket,
    },
    {
      title: 'TOP Fans perks',
      icon: icHeart,
    },
  ];
  return (
    <ParentDiv>
      <FlexDiv>
        {profile?.avatar ? (
          <Image
            alt={'profile'}
            wrapperClassName="circle-image"
            placeholderSrc={profile?.thumbnail}
            src={profile?.avatar}
          />
        ) : (
          <ImageSkeleton />
        )}
        <div
          style={{
            gap: '0.8rem',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Title>
            {profile.name ? (
              profile.name
            ) : (
              <Skeleton style={{ flex: 1, width: '15rem' }} />
            )}
          </Title>
          <img
            style={{ width: '1.6rem', height: '1.6rem' }}
            src={IcVerified}
            alt="verfied"
          />
        </div>
        {profile.numSongsTxt ? (
          <div
            style={{
              gap: '0.8rem',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <div className="text-12">{profile.numSongsTxt}</div>
            <div
              style={{
                width: '0.4rem',
                height: '0.4rem',
                background: themeColors[SELECTED_THEME].textSecondary,
                borderRadius: '4.8rem',
              }}
            ></div>
            <div className="text-12">{profile.numStreamsTxt}</div>
          </div>
        ) : (
          <Skeleton style={{ flex: 1, width: '10rem' }} />
        )}
        <div
          className="text-12"
          style={{
            color: themeColors[SELECTED_THEME].textPrimary,
            lineHeight: '3.2rem',
            fontWeight: 600,
            fontSize: '1.2rem',
            marginTop: '0.8rem',
          }}
        >
          GET EXCLUSIVE ACCESS TO MY
        </div>
        <Grid style={{ marginTop: '0.8rem' }}>
          {OFFERINGS.map((item, index) => (
            <OfferCard key={index}>
              <img
                src={item.icon}
                style={{ width: '1.6rem', height: '1.6rem' }}
                alt="icon"
              />
              <div
                className="text-12"
                style={{
                  fontWeight: 500,
                  lineHeight: '1.6rem',
                }}
              >
                {item.title}
              </div>
            </OfferCard>
          ))}
        </Grid>
        <div
          style={{
            marginTop: '2.4rem',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {price ? (
            !subsData ? (
              <div
                onClick={openBuyFliq}
                className="btn-brand-small text-14"
                style={{
                  color: 'white',
                  fontSize: '1.2rem',
                  fontWeight: 700,
                  lineHeight: '2.4rem',
                  height: '4.8rem',
                  width: 'fit-content',
                  padding: '1.2rem 2.4rem',
                }}
              >
                Subscribe to my FliQ
              </div>
            ) : !subsData.cancelAt ? (
              <div
                onClick={handleCancel}
                className="btn-black btn-black-hover"
              >{`Cancel FliQ`}</div>
            ) : (
              <div
                className="text-12"
                style={{ fontWeight: 500, textTransform: 'uppercase' }}
              >
                Scheduled to cancel on{' '}
                <span
                  style={{
                    color: themeColors[SELECTED_THEME].brand,
                    marginLeft: '0.4rem',
                  }}
                >{`   ${new Date(subsData.cancelAt).toDateString()}`}</span>
              </div>
            )
          ) : (
            <Skeleton
              style={{
                flex: 1,
                width: '6rem',
                height: '2rem',
                borderRadius: '12rem',
              }}
            />
          )}
          {price ? (
            subsData ? (
              <></>
            ) : (
              <Title
                disabled
                style={{
                  fontWeight: 900,
                  color: '#E4E4E7',
                  lineHeight: '4rem',
                  fontSize: '1.4rem',
                }}
              >{`${price}`}</Title>
            )
          ) : (
            <Skeleton
              style={{
                flex: 1,
                width: '6rem',
                height: '2rem',
                borderRadius: '12rem',
              }}
            />
          )}
        </div>
      </FlexDiv>
    </ParentDiv>
  );
};

const ParentDiv = styled.div`
  margin-top: -6rem;
`;

const OfferCard = styled.div`
  border-radius: 0.8rem;
  padding: 1.6rem;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: ${(window.screen.width - 64) / 3}px;
  background: ${themeColors[SELECTED_THEME].footer};
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  width: 100%;
`;

const Image = styled(LazyLoadImage)`
  ${media.mobile`width: 8rem; height: 8rem`};
  ${media.small`width: 8rem; height: 8rem`};
  ${media.medium`width: 8rem; height: 8rem`};
  border-radius: 100%;
`;

const ImageSkeleton = styled(Skeleton)`
  ${media.mobile`width: 8rem; height: 8rem`};
  ${media.small`width: 8rem; height: 8rem`};
  ${media.medium`width: 8rem; height: 8rem`};
  border-radius: 100%;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${media.mobile`gap: 0.8rem`};
  ${media.small`gap: 0.8rem`};
  ${media.medium`gap: 0.8rem`};
`;

const Title = styled.div`
  color: ${themeColors[SELECTED_THEME].textPrimary};
  font-family: Poppins;
  font-weight: 900;
  ${media.mobile`font-size: 1.8rem; line-height: 3.2rem`};
  ${media.small`font-size: 1.8rem; line-height: 3.2rem`};
  ${media.medium`font-size: 1.8rem; line-height: 3.2rem;`};
`;
