// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { useLocation } from 'react-router-dom';

export default function Purchase() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('Error in creating payment link');
  const location = useLocation();

  // const loadScript = src => {
  //   return new Promise(resolve => {
  //     const script = document.createElement('script');
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // };

  // React.useEffect(() => {
  //   loadScript('https://checkout.razorpay.com/v1/checkout.js');
  // });

  // const openPaymentWindow = options => {
  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const planId = queryParams.get('planId');
    const status = queryParams.get('status');
    const subscribedId = queryParams.get('subscribedId');

    if (token && planId) {
      axios
        .post(
          'https://api.fanfliq.com/v1/subscription/order/create',
          { planId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(response => {
          if (response.status === 200 && response.data.easebuzzaccess) {
            window.open(
              `https://pay.easebuzz.in/pay/${response.data.easebuzzaccess}`,
              '_self',
            );
          }
        })
        .catch(err => {
          console.log(err);
          setShow(true);
        });
    } else if (status && status === 'success') {
      if (subscribedId) {
        window.ReactNativeWebView.postMessage('SUCCESS');
      }
    } else if (status && status === 'failed') {
      setMessage('Error in completing payment');
      setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Div>
      <Spinner />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
      </Modal>
    </Div>
  );
}

const Div = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
