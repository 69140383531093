import { getAccessToken } from 'api/http-common';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const ProtectedRoute = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const route = queryParams.get('redirect')
    ? `/login?redirect=${queryParams.get('redirect')}`
    : `/login?redirect=${window.location.href}`;

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return getAccessToken() ? <Outlet /> : <Navigate to={route} />;
};
