import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import IcPlay from 'assets/ic_play_show.svg';
import IcPause from 'assets/ic_pause.svg';
import { BASE_URL, getAccessToken } from 'api/http-common';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { useContext, useState } from 'react';
import { DataContext } from 'utils/DataContext';
import { media } from 'utils/media';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export const MusicListItem = ({ item, index, setHoverId, hoverId }) => {
  const { id } = useParams();
  const { isPlaying, setIsPlaying, curPlayingId, setPlayingId, setCurAlbumId } =
    useContext(DataContext);

  const shareUrl = id => `${BASE_URL.PROD}/content/sharable/${id}`;
  const [progress, setPrpgress] = useState(-2);

  const handlePlayPause = itemId => {
    if (curPlayingId === itemId) {
      setIsPlaying(!isPlaying);
    } else {
      if (window.location.href.includes('album/')) {
        setCurAlbumId(id);
      } else {
        setCurAlbumId(itemId);
      }

      setPlayingId(item.id);
      setIsPlaying(true);
    }
  };

  const downloadShareFile = async id => {
    setPrpgress(-1);

    const response = await axios({
      url: shareUrl(id),
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      method: 'GET',
      responseType: 'blob', // important
      onDownloadProgress: progressEvent => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        setPrpgress(percentCompleted);
      },
    });

    setPrpgress(-2);
    if (response.data) {
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'share.mp4'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  };

  return (
    <ItemDiv
      key={index}
      onMouseEnter={() => setHoverId(item.id)}
      onMouseLeave={() => setHoverId()}
      onClick={() => {
        handlePlayPause(item.id);
      }}
    >
      <div className="hor-flex" style={{ flexGrow: 1, width: '100%' }}>
        {item.cover ? (
          <div style={{ position: 'relative' }}>
            {hoverId === item.id ? (
              <img
                style={{
                  position: 'absolute',
                  inset: 0,
                  margin: 'auto',
                  width: '2rem',
                  height: '2rem',
                }}
                src={curPlayingId === item.id && isPlaying ? IcPause : IcPlay}
                alt="play"
              />
            ) : (
              <></>
            )}
            <Image placeholderSrc={item?.thumbnail} src={item?.cover} />
          </div>
        ) : (
          <ImageSkeleton />
        )}
        <div style={{ flexGrow: 1, marginLeft: '0.8rem' }}>
          <Title playing={curPlayingId === item.id}>{item.title}</Title>
          {item.analyticData ? (
            <SubTitle>{item.analyticData.numStreamsTxt}</SubTitle>
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div>
        {/* {item.url ? (
          progress === -2 ? (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Share Snippet</Tooltip>}
            >
              <img
                src={IcDownload}
                className="white-hover"
                style={{ height: '2.4rem', width: '2.4rem' }}
                alt="more"
                onClick={e => {
                  e.stopPropagation();
                  downloadShareFile(item.id);
                }}
              />
            </OverlayTrigger>
          ) : progress === -1 ? (
            <Spinner
              style={{ height: '2rem', width: '2rem' }}
              variant="success"
            />
          ) : (
            <CircularProgressbar
              value={progress}
              styles={{ root: { width: '2.4rem', height: '2.4rem' } }}
            />
          )
        ) : (
          <></>
        )} */}
      </div>
    </ItemDiv>
  );
};

const SubTitle = styled.div`
  color: ${themeColors[SELECTED_THEME].black60};
  font-family: Poppins;
  font-weight: 400;
  ${media.mobile`font-size: 1rem; line-height: 2.4rem;`};
  ${media.small`font-size: 1rem; line-height: 3.2rem`};
`;

const Title = styled.div`
  color: ${props =>
    props.playing
      ? themeColors[SELECTED_THEME].brand
      : themeColors[SELECTED_THEME].textPrimary};
  font-family: Poppins;
  font-weight: 600;
  flex-grow: 1;
  ${media.mobile`font-size: 1.2rem; line-height: 2.4rem;`};
  ${media.small`font-size: 1.2rem; line-height: 2.4rem`};
`;

const Image = styled(LazyLoadImage)`
  ${media.mobile`width: 4.8rem; height: 4.8rem; border-radius: 0.4rem;`};
  ${media.small`width: 4.8rem; height: 4.8em; border-radius: 0.4rem;`};
  ${media.medium`width: 4.8rem; height: 4.8rem; border-radius: 0.4rem;`};
  object-fit: cover;
`;

const ImageSkeleton = styled(Skeleton)`
  border-radius: 0.4rem;
  ${media.mobile`width: 4.8rem; height: 4.8rem`};
  ${media.small`width: 4.8rem; height: 4.8em`};
  ${media.medium`width: 4.8rem; height: 4.8rem`};
  object-fit: cover;
`;

const ItemDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  background: transparent;
  border-radius: 0.8rem;
  ${media.mobile`width: 100%; justify-content: space-between; padding: 0`};
  ${media.small`width: 100%; justify-content: space-between; padding: 0`};
  ${media.medium`width: 100%; justify-content: space-between`};
  :hover {
    background: #141414;
  }
`;
