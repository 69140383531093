import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Purchase from './pages/Purchase';
import { GlobalStyle } from './utils/global-styles';

import './index.css';
import { toast, ToastContainer } from 'react-toastify';
import { DataContext } from './utils/DataContext';
import 'react-loading-skeleton/dist/skeleton.css';
import ScrollToTop from './utils/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-circular-progressbar/dist/styles.css';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { Login } from 'pages/Login';
import { AccountSetup } from 'pages/AccountSetup';
import { useEffect, useState } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Home } from 'pages/Home';
import { Redeem } from 'pages/RedeemPage';
import { OpenOnWeb } from 'pages/OpenOnWeb';
import { PhoneInput } from 'pages/PhoneInput';
import { API_CALLS, callAPI } from 'api';
import { getAccessToken } from 'api/http-common';
import { HomePageWebsite } from 'pages/HomePageWebsite';
import { ForArtist } from 'pages/ForArtist';
import AnimatedCursor from 'react-animated-cursor';
import { sizes } from 'utils/media';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { Album } from 'pages/Album';
import { SharePage } from 'pages/SharePage';
import { EmailInput } from 'pages/EmailInput';
import { Privacy } from 'pages/Privacy';
import { Terms } from 'pages/Terms';
import { Contact } from 'pages/Contactus';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  const { width } = useWindowDimensions();
  const [myProfile, setMyProfile] = useState();

  const notify = (message, type = 'info') =>
    toast(message, {
      position:
        width <= 700 ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.TOP_RIGHT,
      type,
    });

  const [isPlaying, setIsPlaying] = useState(false);
  const [curPlayingId, setPlayingId] = useState();
  const [playlist, setPlaylist] = useState([]);
  const [curAlbumId, setCurAlbumId] = useState();

  const getUser = async () => {
    try {
      const profile = await callAPI(
        API_CALLS.GET_PROFILE,
        localStorage.getItem('id'),
      );
      setMyProfile(profile);
    } catch (e) {}
  };

  useEffect(() => {
    if (getAccessToken()) {
      getUser();
      if (sessionStorage.getItem('curPlayingId'))
        setPlayingId(sessionStorage.getItem('curPlayingId'));
      if (sessionStorage.getItem('curAlbumId'))
        setCurAlbumId(sessionStorage.getItem('curAlbumId'));
      if (sessionStorage.getItem('playlist'))
        setPlaylist(sessionStorage.getItem('playlist'));
      sessionStorage.clear();
    }
  }, []);

  return (
    <div>
      {width > sizes.small ? (
        <AnimatedCursor
          innerSize={0}
          outerSize={36}
          innerScale={0}
          outerScale={1}
          outerAlpha={0}
          showSystemCursor
          innerStyle={{
            border: '0px solid var(--cursor-color)',
            zIndex: -1,
          }}
          outerStyle={{
            zIndex: 999,
            transition: 'all 0.3s ease-out',
            border: `3px solid ${themeColors[SELECTED_THEME].brand}`,
            boxShadow: '0 0 22px rgba(220, 20, 60, 0.6)',
          }}
          clickables={[
            {
              target: '.hover-link-ff',
              outerStyle: {
                zIndex: 999,
                transform: 'scale(2) translateX(-25%) translateY(-25%)',
                border: 'none',
                backgroundColor: 'rgba(220, 20, 60,.1)',
                boxShadow: '0 0 20px rgba(220, 20, 60, 0.8)',
              },
            },
          ]}
        />
      ) : (
        <></>
      )}
      <Router>
        <ScrollToTop>
          <GlobalStyle />
          <SkeletonTheme
            baseColor="rgba(255, 255, 255, 0.08)"
            highlightColor="rgba(255, 255, 255, 0.4)"
          >
            <DataContext.Provider
              value={{
                notify,
                isPlaying,
                setIsPlaying,
                curPlayingId,
                setPlayingId,
                myProfile,
                playlist,
                setPlaylist,
                curAlbumId,
                setCurAlbumId,
              }}
            >
              <Routes>
                <Route path="/" element={<HomePageWebsite />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/" element={<HomePageWebsite />} />
                <Route path="/redeem" element={<Redeem />} />
                <Route path="/for-artist" element={<ForArtist />} />
                <Route path="/myfliq/:id" element={<SharePage />} />
                <Route exact path="/artist/:id" element={<Home />} />
                <Route exact path="/:username/album/:id" element={<Album />} />

                <Route exact path="/setup" element={<ProtectedRoute />}>
                  <Route exact path="/setup" element={<AccountSetup />} />
                </Route>
                <Route path="/purchase" element={<Purchase />} />
                <Route path="/login" element={<Login />} />
                <Route path="/openweb" element={<OpenOnWeb />} />
                <Route path="/phone" element={<PhoneInput />} />
                <Route path="/email/verify" element={<EmailInput />} />
              </Routes>
            </DataContext.Provider>
          </SkeletonTheme>
        </ScrollToTop>
        <ToastContainer style={{ '--toastify-toast-width': 380 }} />
      </Router>
    </div>
  );
}

export default App;
