import styled from 'styled-components';
import { media } from 'utils/media';
import { SELECTED_THEME, themeColors } from 'utils/theme';

export const ShareLinks = ({ socials = [] }) => {
  const getSocialIcon = (type = '') => {
    return require(`assets/${type}.svg`);
  };

  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  return (
    <>
      <ParentDiv>
        {socials.map((social, index) => (
          <img
            key={index}
            onClick={() => openInNewTab(social.url)}
            src={getSocialIcon(social.type)}
            alt={social.type}
            className="filter-hover"
            style={{ height: '2.4rem', width: '2.4rem' }}
          />
        ))}
      </ParentDiv>
    </>
  );
};

const ParentDiv = styled.div`
  display: flex;
  border-radius: 0.8rem;
  background: ${themeColors[SELECTED_THEME].black8};
  ${media.mobile`gap: 1.6rem; width: 100%; padding: 2.4rem 1.6rem`};
  ${media.small`gap: 1.6rem; width: 100%; padding: 2.4rem 1.6rem`};
  ${media.medium`gap: 1.6rem; margin-top: 0.8rem; width: 79rem; padding: 3.2rem 2.4rem`};
`;
