import axios, { AxiosProgressEvent } from 'axios';

import { axiosInstance } from './api';
import { ROUTES } from './http-common';

export const getUser = async (param: string) => {
  const response = await axiosInstance.get(
    ROUTES.USERS.replace('{filters}', param),
  );
  return response.data;
};

export const getUserById = async (userId: string) => {
  const response = await axiosInstance.get(
    ROUTES.PROFILE.replace('{userId}', userId),
  );
  return response.data;
};

export const getUserByUsername = async (username: string) => {
  const response = await axios.get(
    ROUTES.PROFILE_BY_USERNAME.replace('{username}', username),
  );
  return response.data;
};

export const updateUserById = async (userId: string, params?: object) => {
  const response = await axiosInstance.patch(
    ROUTES.PROFILE.replace('{userId}', userId),
    params,
  );
  return response.data;
};

export const deleteUser = async (userId?: string, params?: object) => {
  const response = await axiosInstance.post(ROUTES.DELETE);
  return response.data;
};

export const getAnalytics = async (param: string) => {
  const response = await axiosInstance.get(
    ROUTES.ANALYTICS.replace('{filters}', param),
  );
  return response.data;
};

export const getAvatarUploadUrl = async (param: string) => {
  const response = await axiosInstance.get(`${ROUTES.AVATAR_UPLOAD}/${param}`);
  return response.data;
};

export const getPlanData = async (param: string) => {
  const response = await axiosInstance.get(
    ROUTES.PLANS_API.replace('{filters}', param),
  );
  return response.data;
};

export const createPlanData = async (param: any) => {
  const response = await axiosInstance.post(ROUTES.CREATE_PLANS_API, param);
  return response.data;
};

export const updatePlanById = async (planId: string, params?: object) => {
  const response = await axiosInstance.patch(
    ROUTES.UPDATE_PLAN.replace('{planId}', planId),
    params,
  );
  return response.data;
};

export const getSubsDetails = async (param: any) => {
  const response = await axiosInstance.get(
    ROUTES.GET_SUBS_DETAILS.replace('{filters}', param),
  );
  return response.data;
};

export const getNotifications = async (param: any) => {
  const response = await axiosInstance.get(
    ROUTES.GET_NOTIFICATIONS.replace('{filters}', param),
  );
  return response.data;
};

export const getPlayerToken = async (param: any) => {
  const response = await axiosInstance.get(ROUTES.GET_PLAYER_KEY);
  return response.data;
};

export const cancelSubs = async (param: any) => {
  const response = await axiosInstance.post(ROUTES.CANCEL_SUBS, param);
  return response.data;
};

export const uploadFileToS3 = async (
  s3Url: string,
  data: any,
  type: string,
) => {
  await axios.put(s3Url, data, {
    headers: {
      'Content-Type': type,
      'Content-Length': data.size.toString(),
    },
  });
};

export const uploadFileToS3NonAsync = async (
  s3Url: string,
  data: any,
  type: string,
  onUploadProgress: (_p: AxiosProgressEvent) => void = (
    _p: AxiosProgressEvent,
  ) => {},
) => {
  await axios.put(s3Url, data, {
    onUploadProgress,
    headers: {
      'Content-Type': type,
      'Content-Length': data.size.toString(),
    },
  });
};
