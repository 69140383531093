import styled from 'styled-components';
import { media } from 'utils/media';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import ReactPlayer from 'react-player';
import IcPlay from 'assets/ic_play_show.svg';
import IcBtsVideo from 'assets/ic_bts.svg';

export const BtsVideoList = ({
  list = [],
  showBtn = false,
  handleShowAll,
  openBuyFliq,
}) => {
  const renderEmptyState = (
    <EmptyState>
      <img src={IcBtsVideo} className="resp-icon-32" alt="bts" />
      <div className="text-16-bold" style={{ textAlign: 'center' }}>
        BTS footages are coming soon. <br /> Stay tuned.
      </div>
    </EmptyState>
  );

  return (
    <Parent>
      {list.length
        ? list.map((item, index) => (
            <ItemDiv
              key={index}
              onClick={() => {
                if (!item.url) openBuyFliq();
              }}
            >
              {item.cover ? (
                item.url ? (
                  <ReactPlayer url={item.url} />
                ) : (
                  <div style={{ position: 'relative' }}>
                    <Image placeholderSrc={item?.thumbnail} src={item?.cover} />

                    <PlayBg>
                      <img
                        src={IcPlay}
                        alt="play"
                        style={{ width: '3.2rem', height: '3.2rem' }}
                      />
                    </PlayBg>
                  </div>
                )
              ) : (
                <ImageSkeleton />
              )}
              <Title>{item.title}</Title>
              <SubTitle>{item.numStreamsTxt}</SubTitle>
            </ItemDiv>
          ))
        : renderEmptyState}
      {showBtn ? <BtnDiv onClick={handleShowAll}>See more</BtnDiv> : <></>}
    </Parent>
  );
};

const BtnDiv = styled.div`
  border-radius: 22.4rem;
  border: 0.1rem solid ${themeColors[SELECTED_THEME].textSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themeColors[SELECTED_THEME].textSecondary};
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  ${media.mobile`font-size: 1.2rem; padding: 0rem 1.6rem;height: 4.8rem; line-height: 3.2rem; margin-top: 2.4rem`};
  ${media.small`font-size: 1.2rem; padding: 0rem 1.6rem;height: 4.8rem; line-height: 3.2rem; margin-top: 2.4rem`};
  ${media.medium`font-size: 1.2rem; padding: 0rem 1.6rem;height: 4.8rem; line-height: 3.2rem; margin-top: 2.4rem`};
  ${media.large`font-size: 1.2rem; padding: 0rem 1.6rem;height: 4.8rem; line-height: 3.2rem;width: fit-content; margin-top: 3.2rem`};
  cursor: pointer;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const SubTitle = styled.div`
  color: ${themeColors[SELECTED_THEME].textSecondary};
  font-family: Poppins;
  ${media.mobile`font-size: 1rem; font-weight: 400; line-height: 2.4rem; margin: 0 1.6rem`};
  ${media.small`font-size: 1rem; font-weight: 400; line-height: 2.4rem; margin: 0 1.6rem`};
  ${media.medium`font-size: 1.4rem; font-weight: 600; line-height: 3.2rem; margin: 0 2.4rem`};
`;

const Title = styled.div`
  color: ${themeColors[SELECTED_THEME].textPrimary};
  font-family: Poppins;
  font-weight: 900;
  ${media.mobile`font-size: 1.2rem; font-weight: 600; line-height: 2.4rem; margin: 0 1.6rem`};
  ${media.small`font-size: 1.2rem; font-weight: 600; line-height: 2.4rem; margin: 0 1.6rem`};
  ${media.medium`font-size: 1.6rem; font-weight: 900; line-height: 3.2rem; margin: 0 2.4rem`};
`;

const Image = styled(LazyLoadImage)`
  border-radius: 0.8rem 0.8rem 0 0;
  ${media.mobile`width: 100%; aspect-ratio: 16 / 9; height: auto`};
  ${media.small`width: 100%; aspect-ratio: 16 / 9; height: auto`};
  ${media.medium`width: 79rem; height: 45.6rem`};
  object-fit: cover;
`;

const ImageSkeleton = styled(Skeleton)`
  border-radius: 0.8rem 0.8rem 0 0;
  ${media.mobile`width: 100%; aspect-ratio: 16 / 9; height: auto`};
  ${media.small`width: 100%; aspect-ratio: 16 / 9; height: auto`};
  ${media.medium`width: 79rem; height: 45.6rem`};
  object-fit: cover;
`;

const ItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${themeColors[SELECTED_THEME].black8};
  ${media.mobile`gap: 2.4rem; width: 100%; padding-bottom: 1.6rem`};
  ${media.small`gap: 2.4rem; width: 100% padding-bottom: 1.6rem`};
  ${media.medium`gap: 2.4rem; width: 79rem; margin-top: 0.8rem; padding-bottom: 3.2rem`};
`;

const Parent = styled.div`
  ${media.mobile`width: 100%`};
  ${media.small`width: 100%`};
  ${media.medium`width: 79rem; margin-top: 0.8rem`};
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background: ${themeColors[SELECTED_THEME].black8};
  ${media.mobile`gap: 0.8rem; width: 100%; padding: 4rem 0`};
  ${media.small`gap: 0.8rem; width: 100% padding: 4rem 0`};
  ${media.medium`gap: 0.8rem; width: 79rem; margin-top: 0.8rem; padding: 5.6rem 0`};
`;

const PlayBg = styled.div`
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${themeColors[SELECTED_THEME].brand};
  :hover {
    transform: scale(1.06);
  }
`;
