import axios from 'axios';
import { getBlogById, getBlogs } from './blogs';
import {
  deleteContentById,
  getContentById,
  getContentUploadUrl,
  getContents,
  postContent,
  shareContent,
} from './contents';
import { loginApple, loginGoogle, logout } from './login';
import { report } from './report';
import { trackStream } from './stream';
import {
  cancelSubs,
  createPlanData,
  deleteUser,
  getAnalytics,
  getAvatarUploadUrl,
  getNotifications,
  getPlanData,
  getPlayerToken,
  getSubsDetails,
  getUser,
  getUserById,
  getUserByUsername,
  updatePlanById,
  updateUserById,
} from './users';

export const API_CALLS = {
  LOGIN_WITH_GOOGLE: 'api_google',
  LOGIN_WITH_APPLE: 'api_apple',
  LOGOUT: 'api_logout',

  GET_USERS: 'api_users',
  GET_PROFILE_BY_USERNAME: 'api_profile_username',
  GET_PROFILE: 'api_profile',
  UPDATE_PROFILE: 'api_profile_update',
  DELETE_USER: 'api_delete_user',

  GET_BLOGS: 'api_blogs',
  GET_BLOG: 'api_blog',

  GET_CONTENTS: 'api_contents',
  GET_CONTENT: 'api_content',
  POST_CONTENT: 'api_create_content',
  SHARE_CONTENT: 'api_share_content',
  DELETE_CONTENT: 'api_delete_content',

  GET_ANALYTICS: 'api_analytics',

  GET_AVATAR_UPLOAD_URL: 'api_avatar_upload',
  GET_CONTENT_UPLOAD_URL: 'api_content_upload',

  GET_PLANS: 'api_get_plans',
  CREATE_PLANS: 'api_create_plans',
  UPDATE_PLANS: 'api_update_plans',
  GET_SUBS_DETAILS: 'api_get_subs_details',
  CANCEL_SUBS: 'api_cancel_subs',

  REPORT: 'api_report',
  TRACK_STREAM: 'api_track_stream',

  GET_NOTIFICATIONS: 'api_get_notifications',
  GET_PLAYER_TOKEN: 'api_player_token',

  GET_ALBUMS: 'api_get_albums',
};

export const callAPI = async (
  type: string,
  param: any,
  downloadProgress?: any,
) => {
  try {
    switch (type) {
      case API_CALLS.LOGIN_WITH_GOOGLE:
        return await loginGoogle(param);
      case API_CALLS.LOGIN_WITH_APPLE:
        return await loginApple(param);
      case API_CALLS.GET_USERS:
        return await getUser(param);
      case API_CALLS.GET_PROFILE:
        return await getUserById(param);
      case API_CALLS.GET_PROFILE_BY_USERNAME:
        return await getUserByUsername(param);
      case API_CALLS.UPDATE_PROFILE:
        return await updateUserById(param.userId, param.params);
      case API_CALLS.GET_BLOGS:
        return await getBlogs(param);
      case API_CALLS.GET_BLOG:
        return await getBlogById(param);
      case API_CALLS.GET_CONTENTS:
        return await getContents(param);
      case API_CALLS.GET_CONTENT:
        return await getContentById(param);
      case API_CALLS.DELETE_CONTENT:
        return await deleteContentById(param);
      case API_CALLS.LOGOUT:
        return await logout();
      case API_CALLS.GET_ANALYTICS:
        return await getAnalytics(param);
      case API_CALLS.GET_AVATAR_UPLOAD_URL:
        return await getAvatarUploadUrl(param);
      case API_CALLS.GET_PLANS:
        return await getPlanData(param);
      case API_CALLS.CREATE_PLANS:
        return await createPlanData(param);
      case API_CALLS.UPDATE_PLANS:
        return await updatePlanById(param.planId, param.params);
      case API_CALLS.GET_CONTENT_UPLOAD_URL:
        return await getContentUploadUrl(param);
      case API_CALLS.POST_CONTENT:
        return await postContent(param);
      case API_CALLS.SHARE_CONTENT:
        return await shareContent(param, downloadProgress);
      case API_CALLS.GET_SUBS_DETAILS:
        return await getSubsDetails(param);
      case API_CALLS.CANCEL_SUBS:
        return await cancelSubs(param);
      case API_CALLS.REPORT:
        return await report(param);
      case API_CALLS.TRACK_STREAM:
        return await trackStream(param);
      case API_CALLS.GET_NOTIFICATIONS:
        return await getNotifications(param);
      case API_CALLS.GET_PLAYER_TOKEN:
        return await getPlayerToken(param);
      case API_CALLS.DELETE_USER:
        return await deleteUser(param);
    }
  } catch (error: any) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    throw error;
  }
};
