import { HeaderWebsite } from 'components/HeaderWebsite';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { Footer } from 'components/Footer';
import Accordion from 'react-bootstrap/Accordion';

const FAQS = [
  {
    title: 'Contact Us:',
    content: (
      <>
        FliqMedia Tech Private Limited
        <br />
        For any queries and compaints, feel free to reach out us at -
        <br />
        Email: admin@fanfliq.app
      </>
    ),
  },
];

export function Contact() {
  return (
    <div>
      <HeaderWebsite />
      <div
        className="padding-top-12"
        style={{
          backgroundColor: themeColors[SELECTED_THEME].primary,
          height: '80vh',
        }}
      >
        <div className="section-web p-88" style={{}}>
          <div className="w-1032 gap-16 child section-web-left">
            <div
              style={{
                zIndex: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div className="brand-tag ph-16" style={{ width: 'fit-content' }}>
                <div>Contact</div>
              </div>
              <div className="subtitle-web margin-top-24">
                Product and Account Support
              </div>
              <div className="gap-16 margin-top-48-small">
                <Accordion
                  className="w-1032"
                  defaultActiveKey={'0'}
                  alwaysOpen={true}
                >
                  {FAQS.map(faq => (
                    <Accordion.Item className="w-1032" eventKey={`0`}>
                      <Accordion.Header className="no-icon">
                        {faq.title}
                      </Accordion.Header>
                      <Accordion.Body>{faq.content}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
