/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

export const TopBackground = ({ content }) => {
  const [srcState, setSrcState] = useState();

  useEffect(() => {
    if (!srcState && content) {
      setSrcState(content?.thumbnail);

      const imageLoader = new Image();
      imageLoader.src = content?.cover;

      imageLoader.onload = () => {
        setSrcState(content?.cover);
      };
    }
  }, [content]);

  const styles = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    inset: 0,
  };

  return (
    <>
      <div
        style={{
          ...styles,
          background: `url(${srcState}) lightgray 50% / cover no-repeat`,
          filter: 'blur(46px)',
        }}
      />
      <div
        style={{
          ...styles,
          background: 'rgba(0, 0, 0, 0.71)',
          filter: 'blur(46px)',
        }}
      />
      <div
        style={{
          ...styles,
          background: `url(https://fanfliq-assets.s3.ap-south-1.amazonaws.com/blur_bg.webp) lightgray 50% / cover no-repeat`,
          mixBlendMode: 'overlay',
          opacity: 0.7, 
          filter: 'blur(0.1px)',
        }}
      />
    </>
  );
};
