import styled from 'styled-components';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import IcFullLogo from 'assets/ic_full_logo.svg';
import IcInstagram from 'assets/ic_instagram.svg';
import IcLinkedin from 'assets/ic_linkedin.svg';
import { media } from 'utils/media';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { DataContext } from 'utils/DataContext';

export const Footer = () => {
  const navigate = useNavigate();
  const { curPlayingId } = useContext(DataContext);
  const toHome = () => {
    navigate('/');
  };

  return (
    <Parent paddingBottom={curPlayingId ? '19.2rem' : '9.6rem'}>
      <Options>
        <FlexDiv>
          <img
            src={IcFullLogo}
            alt="logo"
            className="txt-btn"
            onClick={toHome}
          />
          <div style={{ display: 'flex', gap: '0.8rem' }}>
            <a
              href="https://www.instagram.com/fanfliq/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="social"
                src={IcInstagram}
                style={{ width: '2.4rem', height: '2.4rem' }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/get-fanfliq/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                alt="social"
                src={IcLinkedin}
                style={{ width: '2.4rem', height: '2.4rem' }}
              />
            </a>
          </div>
        </FlexDiv>
        <FlexDivRight>
          <a
            href="/terms"
            className="text-14 txt-btn"
            style={{
              textTransform: 'none',
              textDecoration: 'none',
              lineHeight: '3.2rem',
            }}
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
          <a
            href="/privacy"
            className="text-14 txt-btn"
            style={{
              textTransform: 'none',
              textDecoration: 'none',
              lineHeight: '3.2rem',
            }}
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="/contact"
            className="text-14 txt-btn"
            style={{
              textTransform: 'none',
              textDecoration: 'none',
              lineHeight: '3.2rem',
            }}
            rel="noreferrer"
          >
            Contact Us
          </a>
        </FlexDivRight>
      </Options>
      <div className="text-12">Copyright © 2024 FliqMedia Tech PVT LTD</div>
    </Parent>
  );
};

const Parent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${themeColors[SELECTED_THEME].footer};
  ${media.mobile`padding: 4rem 2.4rem; height: 41.8rem`};
  ${media.small`padding: 4rem 2.4rem; height: 41.8rem`};
  ${media.medium`padding: ${props => `4rem 7.2rem ${props.paddingBottom} 7.2rem`}; height: auto`};
  align-items: center;
  justify-content: space-between;
`;

const Options = styled.div`
  display: flex;
  gap: 4rem;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  ${media.mobile`flex-direction: column`};
  ${media.small`flex-direction: column`};
  ${media.medium`flex-direction: row`};
`;

const FlexDiv = styled.div`
  gap: 2.4rem;
  display: flex;
  flex-direction: column;
`;

const FlexDivRight = styled.div`
  gap: 0.4rem;
  display: flex;
  flex-direction: column;
`;
