import { HeaderWebsite } from 'components/HeaderWebsite';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { Footer } from 'components/Footer';
import Accordion from 'react-bootstrap/Accordion';

const FAQS = [
  {
    title: 'Introduction',
    content: (
      <>
        These Terms of Use for all Users govern your use of FanFliq and your
        agreement with us.
      </>
    ),
  },
  {
    title: 'Interpretation: In the Terms of Service',
    content: (
      <>
        a. we refer to our website/app as "FanFliq", including when accessed via
        the URL www.FanFliq.app <br />
        b. references to "we", "our", "us" are references to FliqMedia Tech
        Private Limited, the operator of FanFliq <br />
        c. "Content" means any material uploaded to FanFliq by any User (whether
        a Creator or a Fan), including any photos, videos, audio (for example
        music and other sounds), livestream material, data, text (such as
        comments and hashtags), metadata, images, interactive features, emojis,
        GIFs, memes, and any other material whatsoever <br />
        d. "Creator" means a User who has set up their FanFliq account as a
        Creator account to post Content on FanFliq to be viewed by other Users{' '}
        <br />
        e. "Fan" means a User who follows a Creator and is able to view the
        Creator's Content <br />
        f. "Fan/Creator Transaction" means any transaction between a Fan and a
        Creator on FanFliq by which access is granted to the Creator's Content
        including in any of the following ways: (i) a Subscription, (ii)
        payments made by a Fan to view a Creator's Content and (iii) use by the
        Fan of the fan interaction function on a Creator's account <br />
        g ."Fan Payment" means any and all payments made by a Fan to a Creator
        (i) in connection with a Fan/Creator Transaction, or (ii) by way of a
        tip for a Creator <br />
        h. "Referring User" means a User who participates in the FanFliq
        Referral Program <br />
        i. "Standard Contract between Fan and Creator" means the terms which
        govern each Fan/Creator Transaction, which can be found here <br />
        j. "Subscription/Fliq" means a Fan's subscription to a Creator's account
        (whether paid or unpaid, and whether for one month or as part of a
        bundle comprising a subscription for more than one month) <br />
        k. "Terms of Service" (also called "your agreement with us") means the
        legally binding agreement between you and us which consists of: (i)
        these Terms of Use for all Users, (ii) Terms of Use for Fans, (iii)
        Terms of Use for Creators, (iv) Privacy Policy, (v) Community Guidelines{' '}
        <br />
        l. "User" means any user of FanFliq, whether a Creator or a Fan or both
        (also referred to as "you" or "your") <br />
      </>
    ),
  },
  {
    title: 'Who we are and how to contact us',
    content: (
      <>
        FanFliq is operated by FliqMedia Tech Private Limited. We are a private
        limited company registered in India under companies act and we have our
        registered office address at A-20/5, Chetram Gali, Maujpur, Delhi-110053{' '}
        <br />
        To contact us with any questions about FanFliq, please email our support
        team at admin@fanfliq.app.
      </>
    ),
  },
  {
    title: 'How We Might Amend the Terms of Service',
    content: (
      <>
        The Terms of Service may be altered without prior notification under the
        following circumstances: <br />
        a. to accommodate alterations in laws and regulatory prerequisites
        affecting FanFliq and its services, features, and programs, we may need
        to revise our terms and conditions without the opportunity to provide
        you with adequate prior notice.; and <br />
        b. to swiftly respond to an unexpected and imminent threat concerning
        the protection of FanFliq, fans, or creators against fraud, malware,
        spam, data breaches, or other cyber security risks. complying with our
        legal obligations.
        <br />
        <br />
        We reserve the right to make additional changes to any aspect of the
        Terms of Service. We will provide you with reasonable notice of such
        changes via email or through FanFliq. You have the option to terminate
        your agreement with us before the changes take effect by contacting us.
        Upon the implementation of updated Terms of Service, your continued use
        of FanFliq will indicate your acceptance and adherence to them.
      </>
    ),
  },
  {
    title:
      'We reserve the right to modify, suspend, or withdraw FanFliq at our discretion',
    content: (
      <>
        Changes may be made periodically for various reasons, such as reflecting
        updates to our services, addressing user needs, adapting business
        practices, enhancing performance, improving functionality, or addressing
        security concerns. We endeavor to provide reasonable notice of any
        significant changes. However, we cannot guarantee uninterrupted
        availability or accessibility of FanFliq or its content. We may suspend,
        withdraw, or restrict access to all or part of FanFliq for business or
        operational reasons. Efforts will be made to give you reasonable notice
        of any suspension or withdrawal that affects you.
      </>
    ),
  },
  {
    title: 'Signing up with FanFliq',
    content: (
      <>
        Before accessing FanFliq, you're required to register and establish a
        User account. You'll need to supply a valid email address, select a
        username, and create a password. Alternatively, you can authenticate
        using a valid Apple or Google account. Your password must be unique,
        meaning it should differ from those used on other websites, and it must
        meet FanFliq's technical requirements for password composition. To
        register as a User: <br /> a. you must be at least 13 years old, and you
        will be required to confirm this; <br />
        b. if the laws of the country or State/province where you live provide
        that you can only be legally bound by a contract with us at an age which
        is higher than 18 years old, then you must be old enough to be legally
        bound by a contract with us under the laws of the country or
        State/province where you live; and <br />
        c. you must be authorized by the laws of the country or state/province
        where you are situated to join FanFliq, access any available content,
        and utilize its provided functionalities. <br />
        d. you must provide such other information or verification records as we
        require.
        <br />
        If you do not meet the above requirements, you must not access or use
        FanFliq.
      </>
    ),
  },
  {
    title: 'Your commitments to us',
    content: (
      <>
        When you register with and use FanFliq, you make the following
        commitments to us:
        <br />
        a. If you had a prior account with FanFliq, you affirm that it wasn't
        terminated or suspended by us due to violations of our terms or policies
        <br />
        b. You agree to ensure that all information provided to us is truthful,
        accurate, and complete. Any changes to your submitted information will
        be promptly updated. <br />
        c. By using FanFliq, you consent to receiving communications from us
        electronically, including emails and messages posted to your FanFliq
        account. You also consent to the processing of your personal data as
        outlined in our Privacy Policy.
        <br />
        d. You are responsible for maintaining the confidentiality and security
        of your account/login details, including user credentials, passwords,
        and any other security-related information. Promptly notify
        hello@fanfliq.app if you suspect unauthorized access to or use of your
        account or if any security breach occurs. Additionally, ensure to log
        out of your account after each session, especially when accessing it
        from public or shared computers, to prevent unauthorized access to,
        viewing, or recording of your password or personal information by
        others. e. You acknowledge that you are accountable for all activities
        occurring on your account, even if contrary to the Terms of Service,
        someone else utilizes it. f. You agree to adhere fully to these Terms of
        Use for all Users, our Acceptable Use Policy, and all other relevant
        sections of the Terms of Service governing your usage of FanFliq.
      </>
    ),
  },
  {
    title: 'Rights we have, including to suspend or terminate your account',
    content: (
      <>
        When you register with and use FanFliq, you make the following
        commitments to us:
        <br />
        a. While we retain the capability to moderate or review your content, we
        are not under obligation to do so to ensure compliance with the Terms of
        Service, including our Acceptable Use Policy, and/or any relevant laws.
        <br />
        b. Our policy dictates that we may suspend access to any content you
        post on FanFliq if we become aware that it may not comply with the Terms
        of Service, including our Acceptable Use Policy, and/or any applicable
        law. This suspension allows us to investigate the suspected
        non-compliance or unlawfulness of the content. If access to your content
        is suspended, you have the right to request a review of our decision by
        contacting us at hello@fanfliq.app. Following our investigation, we
        reserve the right to take appropriate action, which may include
        reinstating access to the content or permanently removing or disabling
        access without prior notice or consent from you. You agree to provide us
        with all reasonable assistance, at your own expense, to aid in our
        investigation, including providing requested information. We are not
        liable for any losses you may incur due to the suspension of access to
        your content or any actions taken in good faith to investigate suspected
        non-compliance or unlawfulness under this policy. <br />
        c. If we suspend access to or delete any of your Content, we will notify
        you via email or electronic message to your FanFliq account, but we are
        not obligated to give you prior notice of such removal or suspension.{' '}
        <br />
        d. We reserve the sole discretion to terminate your agreement with us
        and your access to FanFliq for any reason by providing you with a 30-day
        notice via email or electronic message to your FanFliq account.
        Additionally, we retain the right to suspend access to your user account
        or terminate your agreement with us and your access to FanFliq
        immediately and without prior notice.
        <br />
        i. if we think that you have or may have seriously or repeatedly
        breached any part of the Terms of Service (including in particular our
        Acceptable Use Policy), or if you attempt or threaten to breach any part
        of the Terms of Service in a way which has or could have serious
        consequences for us or another User; or <br />
        ii. if you take any action that in our opinion has caused or is
        reasonably likely to cause us to suffer a loss or that otherwise harms
        the reputation of FanFliq.
        <br />
        If we suspend access to your User account or terminate your agreement
        with us and your access to FanFliq we will let you know. During any
        period when access to your User account is suspended, any Fan Payments
        which would otherwise have fallen due during the period of suspension
        will be suspended, and we may withhold all or any part of the Creator
        Earnings due to you but not yet paid out in accordance with section 13
        of the Terms of Use for Creators. e. Upon termination of your account,
        we reserve the right to handle your content as deemed appropriate,
        including but not limited to deletion, in accordance with our Privacy
        Policy. You will no longer have entitlement to access your content.
        Please note that there is no technical facility on FanFliq to enable
        access to your content following the termination of your account. <br />
        f. We can investigate any suspected or alleged misuse, abuse, or
        unlawful use of FanFliq and cooperate with law enforcement agencies in
        such investigation. <br />
        g. We can disclose any information or records in our possession or
        control about your use of FanFliq to law enforcement agencies in
        connection with any law enforcement investigation of any suspected or
        alleged illegal activity, to protect our rights or legal interests, or
        in response to legal process. <br />
        h. We can change the third-party payment providers used to process
        payments on FanFliq and if we do so, we will notify you and store
        applicable details on your FanFliq account. <br />
        i. Other than Content (which is owned by or licensed to Creators), all
        rights in and to FanFliq and its entire contents, features, databases,
        source code and functionality, are owned by us and/or our licensors.
        Such material is protected by copyright, and may be protected by
        trademark, trade secret, and other intellectual property laws. <br />
        j. We are the sole and exclusive owners of any and all anonymised data
        relating to your use of FanFliq and such anonymised data can be used by
        us for any purpose, including for commercial, development and research
        purposes. <br />
      </>
    ),
  },
  {
    title: 'What we are not responsible for',
    content: (
      <>
        We will use reasonable care and skill in providing FanFliq to you, but
        there are certain things which we are not responsible for, as follows
        <br />
        a. We do not endorse or approve content on FanFliq, and the opinions
        expressed by creators or fans do not necessarily reflect our own.
        <br />
        b. You are not granted any rights regarding content. Any such rights can
        only be granted by creators. <br />
        c. Your content may be viewed by individuals who recognize your
        identity. We will not be held responsible if you are identified from
        your content. While we may occasionally offer geofencing or geolocation
        technology on FanFliq, you understand and agree that we do not guarantee
        the accuracy or effectiveness of such technology, and you waive any
        claims against us arising from your use or reliance upon it. <br />
        d. All content is created, selected, and provided by users, not by us.
        We do not review or moderate content, nor do we select or alter the
        content stored or transmitted via FanFliq. We are not obligated to
        monitor content or detect breaches of the Terms of Service, including
        the Acceptable Use Policy.
        <br />
        e. You are not obligated to follow any suggestions, comments, reviews,
        or instructions from other users of FanFliq. If you choose to do so, you
        do so at your own risk. <br />
        f. We make no guarantees regarding the earnings of creators or referring
        users from their use of FanFliq, including the FanFliq Referral Program.{' '}
        <br />
        g. The materials provided on FanFliq are for general information only.
        We do not guarantee the accuracy or outcomes of such materials. <br />
        h. We do not guarantee compatibility of FanFliq with all devices and
        operating systems. You are responsible for configuring your IT, devices,
        and programs for access and should use virus protection software. <br />
        i. We are not responsible for internet availability or any errors in
        your connections, devices, equipment, or software related to your use of
        FanFliq. <br />
        j. While we strive for security and freedom from bugs and viruses, we
        cannot guarantee it and have no control over creator-supplied content.{' '}
        <br />
        k. We are not liable for lost, stolen, or compromised user accounts,
        passwords, email accounts, or resulting unauthorized activities or
        payments. <br />
        l. Once your content is posted on FanFliq, we cannot control or be held
        responsible for its use by other users or third parties. You can delete
        your account at any time, but acknowledge that deletion does not prevent
        the circulation of your content recorded by other users or third parties
        in breach of the Terms of Service prior to deletion. <br />
      </>
    ),
  },
  {
    title: 'Intellectual property rights – ownership and licenses:',
    content: (
      <>
        a. By agreeing to these terms, you affirm that you possess all
        intellectual property rights, including but not limited to copyright and
        trademarks, in the content ("Content") you submit to FanFliq, or that
        you have acquired all requisite rights necessary to grant licenses
        regarding your Content to us and to other users. This encompasses rights
        essential for engaging in the activities delineated in sub-section 10(b)
        below, within any territory where FanFliq is accessible, notably in the
        Inida, United States of America, Canada and the European Union and other
        part of world.
        <br />
        b. The license granted to us pursuant to sub-section 10(b) is perpetual,
        non-exclusive, worldwide, royalty-free, sub-licensable, assignable, and
        transferable by us. This signifies that the license shall remain
        effective even after termination of your agreement with us and cessation
        of your usage of FanFliq, that no compensation shall be due to you for
        the license, and that we reserve the right to sublicense your Content to
        third parties, or assign or transfer the license to others. This license
        empowers us, for instance, to incorporate stickers, text, and watermarks
        into your Content, to make your Content accessible to other users of
        FanFliq, and to utilize your Content for other standard operations of
        FanFliq. We commit not to sell your Content to other platforms, though
        we may sell or transfer any license granted to us in the Terms of
        Service in the event of a sale of our company or its assets to a third
        party. <br />
        c. Your content may be viewed by individuals who recognize your
        identity. We will not be held responsible if you are identified from
        your content. While we may occasionally offer geofencing or geolocation
        technology on FanFliq, you understand and agree that we do not guarantee
        the accuracy or effectiveness of such technology, and you waive any
        claims against us arising from your use or reliance upon it. <br />
        d. While we do not claim ownership of your Content, you grant us a
        limited right to issue notifications of infringement, including
        copyright or trademark infringement, on your behalf to any third-party
        website or service hosting or dealing in infringing copies of your
        Content without your consent. Although we are not obligated to do so, we
        reserve the right to submit or withdraw any such notification to any
        third-party website or service where we deem it appropriate. However, we
        do not undertake, nor are obligated to, monitoring infringements of your
        Content. You agree to provide us with all consents and other pertinent
        information reasonably required for us to issue notifications of
        infringement on your behalf, upon our request. Please refer to our
        Complaints Policy for guidance on submitting complaints regarding
        infringement of intellectual property rights.
        <br />
        e. You waive any moral rights you may hold under applicable law to
        object to derogatory treatment of any Content posted by you on FanFliq.
        This waiver does not in any way affect your ownership of any
        intellectual property rights in your Content or your rights to prevent
        unauthorized copying of your Content. The intention behind this waiver
        is to enable us, when dealing with your Content as permitted by the
        license granted to us in section 10(b) above, to incorporate watermarks,
        stickers, or text into your Content. <br />
      </>
    ),
  },
  {
    title: 'Anti-Piracy Clause',
    content: (
      <>
        1. Piracy Prohibition:
        <br />
        a. The User acknowledges that the use, reproduction, distribution, or
        modification of copyrighted materials without proper authorization
        constitutes piracy and is strictly prohibited.
        <br />
        b. Unauthorized reproduction of software, media, or any digital content.
        Distribution of copyrighted material without proper licensing or
        permission. Modification or reverse engineering of software, media, or
        any digital content without explicit consent. <br />
        i. Unauthorized reproduction of software, media, or any digital content.{' '}
        <br />
        ii. Distribution of copyrighted material without proper licensing or
        permission. <br />
        iii. Modification or reverse engineering of software, media, or any
        digital content without explicit consent. <br />
        2. Compliance with Copyright Laws:
        <br />
        a. The User agrees to comply with all applicable copyright laws,
        regulations, and international treaties.
        <br />
        b. The User acknowledges that violating copyright laws may result in
        legal consequences, including but not limited to civil penalties,
        criminal charges, and financial liabilities.
        <br />
        3. Consequences of Piracy: a. In the event of any violation of this
        Anti-Piracy Clause, the Provider reserves the right to take appropriate
        action, including but not limited to: <br />
        i. Suspension or termination of services. <br />
        ii. Legal action to recover damages and seek injunctive relief. <br />
        iii. Pursuing criminal charges where applicable.
        <br />
        <br />
        The User agrees to indemnify and hold harmless the Provider, its
        affiliates, officers, directors, employees, and agents from and against
        any claims, damages, liabilities, costs, and expenses arising out of or
        related to any breach of this Anti-Piracy Clause by the User.
      </>
    ),
  },
  {
    title: 'Linking to and from FanFliq',
    content: (
      <>
        a. Links to FanFliq:
        <br />
        i. You are permitted to link to the FanFliq homepage, provided that such
        linking is conducted in a fair and legal manner that does not harm our
        reputation or exploit it. However, you must refrain from establishing a
        link in a manner that implies any form of association, approval, or
        endorsement on our part.
        <br />
        ii. If you are a Creator, you are required to adhere to our Terms of
        Service as well as the terms of service of any other website where you
        place a link to or otherwise promote your Creator account. When
        promoting your Creator account, it is prohibited to impersonate FanFliq
        or create the impression that your Creator account is being endorsed or
        promoted by us if this is not the case. Additionally, you are not
        permitted to promote your FanFliq account using Google Ads or any
        similar advertising platform or search engine advertising service.
        <br />
        b. Links from FanFliq: If FanFliq contains links to other sites and
        resources provided by third parties, these links are provided for your
        convenience only. Such links should not be interpreted as approval by us
        of those linked websites or information you may obtain from them. We
        have no control over the contents of those sites or resources and accept
        no responsibility for them or for any loss or damage that may arise from
        your use of them. If you decide to access any of the third-party
        websites linked to FanFliq, you do so entirely at your own risk and
        subject to the terms and conditions of use for such websites.
        <br />
        c. Domain Names: In some instances, FanFliq may allow Creators to
        register or use domain names that contain the FanFliq trademark or a
        confusingly similar term. However, you will not register such a domain
        name, unless:
        <br />
        i. The domain name is registered by the Creator.
        <br />
        ii. The domain name redirects to the Creator’s FanFliq profile. Domain
        names containing the FanFliq trademark or a confusingly similar term
        must not direct to any other website, including link aggregators.
        <br />
        iii. The Creator obtains prior written permission from FanFliq and signs
        a licensing agreement.
        <br />
      </>
    ),
  },
  {
    title: 'How do I delete my account?',
    content: (
      <>
        a. If you are a Fan, your account deletion request will be processed
        within a reasonable timeframe upon submission.
        <br />
        b. If you are a Creator, initiating the "delete account" process will
        keep your account active until the conclusion of your Fans' paid
        Subscription period. Following this, your final payment will be issued,
        and your account will be deleted.
        <br />
        c. If you are both a Fan and a Creator, your account will undergo
        deletion in two stages, starting with your Fan account followed by your
        Creator account.
        <br />
        d. Once your account is deleted, you will no longer incur any further
        charges or have access to your former FanFliq account or its Content.
        Any subscriptions will be terminated and cannot be reinstated. A
        confirmation email will be sent upon successful deletion of your
        account. Subsequently, we reserve the right to manage your Content in
        accordance with our Privacy Policy, including deletion. Following
        account termination, you will no longer be able to access your Content,
        as there is no technical mechanism on FanFliq to facilitate access
        post-account deletion.
      </>
    ),
  },
  {
    title: 'Other Legal Terms',
    content: (
      <>
        Warranty Disclaimer
        <br />
        OTHER THAN AS EXPRESSLY STATED IN THIS AGREEMENT OR AS REQUIRED BY LAW,
        THE SERVICE IS PROVIDED “AS IS” AND FANFLIQ DOES NOT MAKE ANY SPECIFIC
        COMMITMENTS OR WARRANTIES ABOUT THE SERVICE. FOR EXAMPLE, WE DON’T MAKE
        ANY WARRANTIES ABOUT: (A) THE CONTENT PROVIDED THROUGH THE SERVICE; (B)
        THE SPECIFIC FEATURES OF THE SERVICE, OR ITS ACCURACY, RELIABILITY,
        AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS; OR (C) THAT ANY CONTENT YOU
        SUBMIT WILL BE ACCESSIBLE ON THE SERVICE.
        <br />
        Limitation of Liability
        <br />
        EXCEPT AS REQUIRED BY APPLICABLE LAW, FANFLIQ, ITS AFFILIATES, OFFICERS,
        DIRECTORS, EMPLOYEES AND AGENTS WILL NOT BE RESPONSIBLE FOR ANY LOSS OF
        PROFITS, REVENUES, BUSINESS OPPORTUNITIES, GOODWILL, OR ANTICIPATED
        SAVINGS; LOSS OR CORRUPTION OF DATA; INDIRECT OR CONSEQUENTIAL LOSS;
        PUNITIVE DAMAGES CAUSED BY:
        <br />
        a. ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICE;
        <br />
        b. PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR USE OF THE
        SERVICE;
        <br />
        c. ANY UNAUTHORIZED USE OF THE SERVICE;
        <br />
        d. ANY INTERRUPTION OR CESSATION OF THE SERVICE;
        <br />
        e. ANY VIRUSES OR MALICIOUS CODE TRANSMITTED TO OR THROUGH THE SERVICE
        BY ANY THIRD PARTY;
        <br />
        f.  ANY CONTENT WHETHER SUBMITTED BY A USER OR FANFLIQ, INCLUDING YOUR
        USE OF CONTENT; AND/OR
        <br />
        g. THE REMOVAL OR UNAVAILABILITY OF ANY CONTENT.
        <br />
        THIS PROVISION APPLIES TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM
        ASSERTED IS BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL
        THEORY.
        <br />
        TO THE EXTENT PERMITTED BY APPLICABLE LAW, FANFLIQ AND ITS AFFILIATES’
        TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE SERVICE
        IS LIMITED TO THE GREATER OF: (A) THE AMOUNT OF REVENUE THAT FANFLIQ HAS
        PAID TO YOU FROM YOUR USE OF THE SERVICE IN THE 12 MONTHS BEFORE THE
        DATE OF YOUR NOTICE, IN WRITING TO FANFLIQ, OF THE CLAIM AND (B) USD
        $500, WHICHEVER IS HIGHER.
        <br />
        Indemnity
        <br />
        To the extent permitted by applicable law, you agree to defend,
        indemnify and hold harmless FanFliq, its Affiliates, officers,
        directors, employees and agents, from and against any and all claims,
        damages, obligations, losses, liabilities, costs or debt, and expenses
        (including but not limited to attorney's fees) arising from: (i) your
        use of he Service; (ii) your violation of any term of these Terms of
        Service; (iii) your violation of any third party right, including
        without limitation any copyright, property, or privacy right; or (iv)
        any claim that your Content caused damage to a third party. This defense
        and indemnification obligation will survive this Agreement and your use
        of the Service.
        <br />
        Third-Party Links
        <br />
        The Service may contain links to third-party websites and online
        services that are not owned or controlled by FanFliq. FanFliq has no
        control over, and assumes no responsibility for, such websites and
        online services. Be aware when you leave the Service; we suggest you
        read the terms and privacy policy of each third-party website and online
        service that you visit.
      </>
    ),
  },
  {
    title: 'General: You agree that:',
    content: (
      <>
        a. If any provision of your agreement with us is found to be
        unenforceable, the remaining provisions will continue to be valid and
        enforceable
        <br />
        b. Our failure to enforce any provision of your agreement with us does
        not constitute a waiver of that provision.
        <br />
        c. We retain all rights not explicitly granted to you.
        <br />
        d. No implicit licenses or other rights are conferred upon you
        concerning any aspect of FanFliq, except as expressly stated in the
        Terms of Service.
        <br />
        e. You are not permitted to transfer your rights or obligations under
        your agreement with us without our prior written consent.
        <br />
        f. We reserve the right to assign or transfer our rights and obligations
        under your agreement with us to others. This may occur due to changes in
        ownership, such as through a merger, acquisition, or asset sale, or as
        required by law. Additionally, we may opt to delegate the performance of
        any obligations under your agreement to a third party, while remaining
        responsible to you for their fulfillment.
        <br />
        g. The Terms of Service constitute the entire agreement between you and
        us regarding your use of FanFliq, superseding any prior oral or written
        agreements.
      </>
    ),
  },
  {
    title: 'Terms relating to disputes-',
    content: (
      <>
        The law which applies to your agreement with us and where disputes and
        claims concerning your use of FanFliq (including those arising from or
        relating to your agreement with us) can be brought:
        <br />
        Dispute resolution and arbitration
        <br />
        You and FanFliq agree that any dispute, claim, or controversy between
        you and FanFliq arising in connection with or relating in any way to
        these Agreements or to your relationship with FanFliq as a user of the
        Service (whether based in contract, tort, statute, fraud,
        misrepresentation, or any other legal theory, and whether the claims
        arise during or after the termination of the Agreements) will be
        determined by mandatory binding individual arbitration.. The arbitrator
        must follow this agreement and can award the same damages and relief as
        a court (including attorney fees), except that the arbitrator may not
        award declaratory or injunctive relief benefiting anyone but the parties
        to the arbitration. This arbitration provision will survive termination
        of the Agreements.
        <br />
        The seat of arbitration shall be Chandigarh, India. The language of the
        arbitration shall be English. The number of arbitrators shall be Three,
        appointed in accordance with the rules of the Arbitration and
        Conciliation Act 1996
      </>
    ),
  },
  {
    title: 'ANTI-HACKING PROVISION',
    content: (
      <>
        You expressly agree not to use FanFliq in any manner or for any purpose
        that is prohibited by these terms and conditions. In addition, you
        expressly agree not to:
        <br />
        (1) use the FanFliq for any purpose that is prohibited by any law or
        regulation, or to facilitate the violation of any law or regulation;
        <br />
        (2) use or attempt to use any "deep-link," "scraper," "robot," "bot,"
        "spider," "data mining," "computer code" or any other automated device,
        program, tool, algorithm, proc-ess or methodology or manual process
        having similar processes or functionality, to access, ac-quire, copy, or
        monitor any portion of the Web Site or any data or content found on or
        accessed through the Web Site without prior express written consent;
        <br />
        (3) obtain or attempt to obtain through any means any materials or
        information on the FanFliq that have not been intentionally made
        publicly available either by their public display on the FanFliq or
        through their accessibility by a visible link on the FanFliq;
        <br />
        (4) in any way bypass or circumvent any other measure employed to limit
        or pre-vent access to the FanFliq or its content;
        <br />
        (5) violate the security of the Web Site or attempt to gain unauthorized
        access to the FanFliq, data, materials, information, computer systems or
        networks connected to any server associated with this Web Site, through
        hacking, password mining or any other means;
        <br />
        (6) interfere or attempt to interfere with the proper working of the
        FanFliq or any activities conducted on or through the Web Site,
        including accessing any data, content or other information prior to the
        time that it is intended to be available to the public on the FanFliq;
      </>
    ),
  },
  {
    title: 'Subscriptions and purchases by Fans:',
    content: (
      <>
        This section describes the terms which apply to Fan/Creator
        Transactions:
        <br />
        a. All transactions between Fans and Creators ("Fan/Creator
        Transactions") are governed by the terms of the Standard Contract
        between Fan and Creator. While we facilitate Fan/Creator Transactions by
        providing the FanFliq platform and storing content, we are not a party
        to the Standard Contract between Fan and Creator or any other contract
        between them, and bear no responsibility for any Fan/Creator
        Transactions.
        <br />
        b. Creators bear sole responsibility for determining the pricing
        applicable to Fan/Creator Transactions within the parameters set forth
        on FanFliq, as well as for the content to which access may be granted.
        All prices are displayed in INR only.
        <br />
        c. To initiate a Fan/Creator Transaction with a specific Creator, you
        must first add a payment card to your account and subsequently click the
        'Subscribe' button on the relevant Creator's profile
        <br />
        d. By providing us with your payment card details, you authorize us and
        our subsidiary companies to transmit these details to a third-party
        payment provider for processing your Fan Payment. All Fan Payments will
        be charged in INR. Your payment card provider may impose currency
        conversion fees, and we and our subsidiary companies are not liable for
        such charges or fees.
        <br />
        e. In the event of a failed payment card transaction, if multiple
        payment cards are registered, the alternative payment card will be used
        to collect the full Fan Payment.
        <br />
        f. The payment provider will process periodic payments for
        Subscription-based Fan Payments and immediate payments for other Fan
        Payments (including tips to Creators) using the payment card details
        provided by you.
        <br />
        g. Except for free-trial Subscriptions, all Subscriptions to a Creator's
        profile will automatically renew at the end of the subscription period,
        unless your payment card is declined, the subscription price has
        increased, or the "Auto-Renew" switch is disabled on the relevant
        Creator's profile.
        <br />
        h. Upon cancellation of a Subscription, you will retain access to the
        relevant Creator's content until the end of the current subscription
        period. Following this, no further payments will be processed for
        subscriptions to that Creator's profile, and access to their content
        will cease.
        <br />
        i. Wallet Credits can be preloaded on FanFliq for future Fan Payments.
        These credits cannot be divided for purchases exceeding the remaining
        credit balance, in which case the full purchase amount will be charged
        to your payment card. Wallet Credits are subject to a maximum limit
        determined by us and are non-refundable
      </>
    ),
  },
  {
    title: 'Suspension',
    content: (
      <>
        Suspension of your User account may result in the suspension of any
        pending Fan Payments during the account suspension period, as per our
        rights under the Terms of Service
      </>
    ),
  },
  {
    title: 'Contact Us:',
    content: (
      <>
        FliqMedia Tech Private Limited
        <br />
        <br />
        Email: admin@fanfliq.app
      </>
    ),
  },
];

export function Terms() {
  return (
    <div>
      <HeaderWebsite />
      <div
        className="padding-top-12"
        style={{ backgroundColor: themeColors[SELECTED_THEME].primary }}
      >
        <div className="section-web p-88" style={{}}>
          <div className="w-1032 gap-16 child section-web-left">
            <div
              style={{
                zIndex: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div className="brand-tag ph-16" style={{ width: 'fit-content' }}>
                <div>Terms & Conditions</div>
              </div>
              <div className="subtitle-web margin-top-24">
                BY USING OUR WEBSITE OR APPLICATION YOU AGREE TO THESE TERMS –
                PLEASE READ THEM CAREFULLY
              </div>
              <div className="gap-16 margin-top-48-small">
                <Accordion
                  className="w-1032"
                  defaultActiveKey={'0'}
                  alwaysOpen={true}
                >
                  {FAQS.map(faq => (
                    <Accordion.Item className="w-1032" eventKey={`0`}>
                      <Accordion.Header className="no-icon">
                        {faq.title}
                      </Accordion.Header>
                      <Accordion.Body>{faq.content}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
