import { API_CALLS, callAPI } from 'api';
import { useContext, useEffect, useState } from 'react';
import { DataContext } from 'utils/DataContext';
import IcVerified from 'assets/ic_verified_txt.svg';
import IcAppstore from 'assets/ic_appstore.svg';
import IcPlaystore from 'assets/ic_playstore.svg';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const ShareCard = ({ username }) => {
  const [artist, setArtist] = useState({});
  const { notify } = useContext(DataContext);
  const [showBtn, setShowBtn] = useState(false);
  const getSocialIcon = (type = '') => {
    return require(`assets/${type}.svg`);
  };

  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  const fetchArtist = async () => {
    try {
      const details = await callAPI(
        API_CALLS.GET_PROFILE_BY_USERNAME,
        username,
      );
      setArtist(details);
    } catch (e) {
      setArtist({});
      const errResponse =
        (e && e?.response && e?.response.data && e?.response.data.message) ||
        (e && e?.message) ||
        'There was some issue while loading content.';
      notify(errResponse, 'error');
    }
  };

  useEffect(() => {
    if (username) {
      fetchArtist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const renderButtons = () => (
    <div
      style={{
        padding: '3.2rem 1.6rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      className="half-height"
    >
      <div
        style={{ display: 'flex', marginTop: '7.8rem' }}
        className="gap-16 hover-link-ff"
      >
        <a
          href={`https://join.fanfliq.app/hDPC/obrcx6a0?deep_link_value=${artist.id}`}
        >
          <img className="store-btn" src={IcPlaystore} alt="playstore" />
        </a>
        <a
          href={`https://join.fanfliq.app/hDPC/obrcx6a0?deep_link_value=${artist.id}`}
        >
          <img className="store-btn" src={IcAppstore} alt="appstore" />
        </a>
      </div>
      <div
        className="subtitle-14-web-share"
        style={{ textAlign: 'center', marginTop: '8rem', padding: '0' }}
      >
        Download the app to join the community and get access to exclusive music
        and FliQs.
      </div>
    </div>
  );

  const renderCommunity = () => (
    <div
      style={{
        padding: '2.4rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      className="half-height"
    >
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        className="gap-20"
      >
        <div style={{ display: 'flex', gap: '1.6rem' }}>
          <div className="subtitle-14-web-share">Music</div>
          <div className="subtitle-14-web-share">FliQ</div>
          <div className="subtitle-14-web-share">Ranking</div>
        </div>
        <div
          className="web-btn hover-link-ff"
          style={{ width: '100%', textAlign: 'center' }}
          onClick={() =>
            window.open(
              `https://join.fanfliq.app/hDPC/obrcx6a0?deep_link_value=${artist.id}&af_force_deeplink=true`,
            )
          }
        >
          <div className="subtitle-14-web-share" style={{ fontWeight: 600 }}>
            + Meet me on FanFliQ 🤫
          </div>
        </div>
        <div
          className="subtitle-14-web-share"
          style={{ textAlign: 'center', padding: '0 1rem' }}
        >
          Join the community to get access to exclusive music and FliQs.
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1.6rem',
            alignSelf: 'center',
            marginTop: '6.8rem',
          }}
        >
          {artist?.socials?.map((social, index) => (
            <img
              key={index}
              onClick={() => openInNewTab(social.url)}
              src={getSocialIcon(social.type)}
              alt={social.type}
              className="filter-hover"
              style={{ height: '2.4rem', width: '2.4rem' }}
            />
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="center-in-parent share-link-card w-360 gap-8"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div className="cover-thumbnail" style={{ position: 'relative' }}>
        {artist?.avatar ? (
          <LazyLoadImage
            alt={'profile'}
            className="cover-thumbnail"
            wrapperClassName="cover-thumbnail"
            placeholderSrc={artist?.thumbnail}
            src={artist?.avatar}
          />
        ) : (
          <Skeleton className="cover-thumbnail" />
        )}
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0 2.4rem 1.6rem 2.4rem',
              gap: '0.8rem',
            }}
          >
            <div className="subtitle-20-web-share">{artist?.name}</div>
            <img src={IcVerified} alt="verfied" />
          </div>
        </div>
      </div>
      {showBtn ? renderButtons() : renderCommunity()}
    </div>
  );
};
