/*
 * Media queries utility
 */

import { css } from 'styled-components/macro';

/*
 * Taken from https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32914
 */

// Update your breakpoints if you want
export const sizes = {
  mobile: 200,
  small: 700,
  medium: 1024,
  large: 1200,
  xlarge: 1440,
  xxlarge: 1920,
};

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (first, ...interpolations) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(first, ...interpolations)}
    }
  `;

  return acc;
}, {});

// /*
//  * @types/styled-component is not working properly as explained in the github issue referenced above.
//  * We must overcome this with custom typings, however, this might not work in time as the styled-components update.
//  * Be carefull and keep an eye on the issue and the possible improvements
//  */
// type MediaFunction = <P extends object>(
//   first:
//     | TemplateStringsArray
//     | CSSObject
//     | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
//   ...interpolations: Array<Interpolation<ThemedStyledProps<P, DefaultTheme>>>
// ) => FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>;

// /* Example
// const SomeDiv = styled.div`
//   display: flex;
//   ....
//   ${media.medium`
//     display: block
//   `}
// `;
// */
