import { ShareCard } from 'components/share-card';
import { useParams } from 'react-router-dom';

export const SharePage = () => {
  let { id } = useParams();

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ShareCard username={id} />
    </div>
  );
};
